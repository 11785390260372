import { useCallback } from "react";

import { useAppSelector, useAppDispatch } from "core/app/store";
import { setTeamId } from "core/app/store/tasks-for-shift-slice";

const useTeamId = (): [
  number | null,
  (teamId: number | null, disbandedStatus?: boolean, shiftTeamId?: null | number) => void,
  boolean,
  number | null
] => {
  const { teamId, disbanded, shiftId } = useAppSelector((state) => state.tasksForShift);
  const dispatch = useAppDispatch();
  const set = useCallback(
    (id: number | null, disbandedStatus = false, shiftTeamId: null | number = null) => {
      dispatch(setTeamId({ id, disbandedStatus, shiftTeamId }));
    },
    [dispatch]
  );

  return [teamId, set, disbanded, shiftId];
};

export { useTeamId };
