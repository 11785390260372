import { ReactElement } from "react";

interface LegendBulletProps {
  color: string;
}
function LegendBullet(props: LegendBulletProps): ReactElement {
  const { color } = props;
  return (
    <div className="mr-2" style={{ backgroundColor: color, borderRadius: "2px", width: "14px", lineHeight: "6px" }}>
      &nbsp;
    </div>
  );
}

export default LegendBullet;
