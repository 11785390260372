import { ReactElement, useEffect } from "react";
import { Select, Form } from "antd";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import qs from "qs";

import { useGetOossaTechNodesQuery } from "core/app/api";
import { useOossaSystem } from "core/shared/oossa/api/useOossaSystems";
import { OossaSystemTypeEnum, TQueryProps } from "core/app/api/interfaces";

import { useIsFieldRequired } from "../../useIsFieldRequired";
import { FIELD_NAMES } from "../../constants";

export function Node(): ReactElement {
  const objectId = Form.useWatch(FIELD_NAMES.OBJECT);
  const systemId = Form.useWatch(FIELD_NAMES.SYSTEM);
  const equipmentId = Form.useWatch(FIELD_NAMES.EQUIPMENT);

  const required = useIsFieldRequired(FIELD_NAMES.TECH_NODE);
  const form = Form.useFormInstance();
  const oossaSystem = useOossaSystem(systemId);
  const disabled = oossaSystem?.code_enum === OossaSystemTypeEnum.SMZIS;

  const queryParams: TQueryProps = {
    object_id: objectId,
    system_id: systemId,
    limit: 1000,
  };
  if (equipmentId) {
    queryParams.equipment_id = equipmentId;
  }

  const { data, isFetching } = useGetOossaTechNodesQuery(qs.stringify(queryParams));
  const options = (data || []).map((item) => ({
    label: item.name,
    value: item.id,
  }));

  useEffect(() => {
    if (disabled && equipmentId && data?.length === 1) {
      form.setFieldValue(FIELD_NAMES.TECH_NODE, data[0].id);
    }
  }, [disabled, data, equipmentId, form]);

  const handleSelectChange = () => {
    form.setFieldValue(FIELD_NAMES.EQUIPMENT, undefined);
  };

  return (
    <Form.Item
      label="ТЕХ. УЗЕЛ"
      name={FIELD_NAMES.TECH_NODE}
      colon={false}
      required={required}
      rules={[
        {
          required,
          message: "",
        },
      ]}
    >
      <Select
        loading={isFetching}
        size="large"
        disabled={disabled}
        allowClear
        optionFilterProp="children"
        showSearch
        filterOption={(input, option) =>
          (option?.label?.toString() ?? "").toLowerCase().includes(input.trim().toLowerCase())
        }
        options={options}
        onChange={handleSelectChange}
      />
    </Form.Item>
  );
}
