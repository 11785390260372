import { intersection } from "lodash";

import { useGetWhoamiQuery } from "core/app/api";
import { RoleEnum } from "core/shared/constants";
import { useUnit } from "core/shared/unit/useUnit";

export function useUserRoles() {
  const { data } = useGetWhoamiQuery("");

  return data?.roles || [];
}

export function useUserHasCustomerAnyRole() {
  const roles = useUserRoles();
  return !!intersection(roles, [RoleEnum.CUSTOMER_REPRESENTATIVE, RoleEnum.CUSTOMER_REPRESENTATIVE_SIGNATORY]).length;
}

export function useHasAccessDownloadUploadTasks() {
  const unit = useUnit();
  const roles = useUserRoles();

  const hasAccessSLPI =
    !!intersection(roles, [RoleEnum.SLPI_WORKSHOP_LEAD, RoleEnum.TEAM_LEAD, RoleEnum.RELIABILITY_ENGINEER]).length &&
    unit?.isSLPI();

  const hasAccessPSPA =
    !!intersection(roles, [
      RoleEnum.PSPA_WORKSHOP_LEAD,
      RoleEnum.SHIFT_LEAD,
      RoleEnum.CUSTOMER_REPRESENTATIVE,
      RoleEnum.CUSTOMER_REPRESENTATIVE_SIGNATORY,
      RoleEnum.UNIT_LEAD,
      RoleEnum.RELIABILITY_ENGINEER,
    ]).length && unit?.isPSPA();

  const hasAccessOOSSA =
    !!intersection(roles, [
      RoleEnum.SHIFT_LEAD,
      RoleEnum.CUSTOMER_REPRESENTATIVE,
      RoleEnum.CUSTOMER_REPRESENTATIVE_SIGNATORY,
      RoleEnum.UNIT_LEAD,
      RoleEnum.RELIABILITY_ENGINEER,
    ]).length && unit?.isOOSSA();

  const hasAccessLSO = unit?.isLSO();
  const hasAccessLKU = unit?.isLKU();
  const hasAccessVOLS = unit?.isVOLS();

  return hasAccessSLPI || hasAccessOOSSA || hasAccessPSPA || hasAccessLSO || hasAccessLKU || hasAccessVOLS;
}
