import { skipToken } from "@reduxjs/toolkit/query/react";
import qs from "qs";

import { useGetRepairAreasQuery } from "core/app/api";
import { useUnitId } from "core/shared/unit/useUnitId";

export function useUnitRepairAreas() {
  const [unitId] = useUnitId();
  const queryString = qs.stringify({
    unit_id: unitId,
  });
  return useGetRepairAreasQuery(unitId ? queryString : skipToken);
}
