import { Row, Col } from "antd";
import { ReactElement } from "react";
import { useOutsideClick } from "outsideclick-react";

import Text from "core/lib/Text/Text";

import { CheckStatusEnum } from "..";

interface MenuProps {
  setVisible: (arg: boolean) => void;
  onChange?: (value: number) => void;
}

/** Содержимое всплывающего меню для карточки ТО с разноцветными квадратами состояния выполнения работы. */
function MaintenanceExecutionCheckMenu({ setVisible, onChange }: MenuProps): ReactElement {
  const handleCheckboxClick = (value: number) => {
    if (typeof onChange === "function") {
      onChange(value);
    }
    setVisible(false);
  };
  const handleOutsideClick = () => {
    setVisible(false);
  };

  const ref = useOutsideClick(handleOutsideClick);

  return (
    <Row ref={ref} gutter={16} className="maintenance-attached-equipment-modal">
      <Col span={6}>
        <div
          className="marker-wrapper"
          onClick={() => {
            handleCheckboxClick(CheckStatusEnum.Unchecked);
          }}
        >
          <div className="marker unchecked" />
          <div>
            <Text size="label2">Работа не выполн.</Text>
          </div>
          <div>
            <Text size="label2">неполная компл.</Text>
          </div>
        </div>
      </Col>
      <Col span={6}>
        <div
          className="marker-wrapper"
          onClick={() => {
            handleCheckboxClick(CheckStatusEnum.Ok);
          }}
        >
          <div className="marker ok" />
          <Text size="label2">Исправно</Text>
        </div>
      </Col>
      <Col span={6}>
        <div
          className="marker-wrapper"
          onClick={() => {
            handleCheckboxClick(CheckStatusEnum.Repaired);
          }}
        >
          <div className="marker repaired" />
          <div>
            <Text size="label2">Отремонтировано</Text>
          </div>
          <div>
            <Text size="label2">в процессе ТО</Text>
          </div>
        </div>
      </Col>
      <Col span={6}>
        <div
          className="marker-wrapper"
          onClick={() => {
            handleCheckboxClick(CheckStatusEnum.Broken);
          }}
        >
          <div className="marker broken" />
          <div>
            <Text size="label2">В оборудовании</Text>
          </div>
          <div>
            <Text size="label2">есть поломка</Text>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default MaintenanceExecutionCheckMenu;
