import { Space } from "antd";

import Media from "core/widgets/TaskModals/shared/Media";

import { useLsoMaintenanceContext } from "../hooks";

import ClosingFields from "./ClosingFields";

function Footer() {
  const { task } = useLsoMaintenanceContext();

  if (!task) {
    return null;
  }

  return (
    <Space className="w-100" direction="vertical">
      <ClosingFields />
      <Media task={task} />
    </Space>
  );
}

export default Footer;
