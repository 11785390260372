import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
}

function System({ task }: FieldProps): ReactElement {
  return <Field label="Наименование системы" value={task.system?.name} />;
}

export { System };
