import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IColumn, IFilter } from "core/shared/interfaces";
import dayjs, { apiDateFormat } from "core/shared/tools/dayjs";

export interface IMaintenanceAttached {
  selectedEquipmentType: number;
  date: string;
  columns: IColumn[];
  filters: IFilter[];
  filterIsBroken: boolean;
  filterHasComment: boolean;
  filterIsRepaired: boolean;
  taskToReset: number | null;
  currentTaskUpdated: boolean;
  showRecovery: boolean;
}

const initialState: IMaintenanceAttached = {
  selectedEquipmentType: -1,
  date: "",
  filterIsBroken: false,
  filterHasComment: false,
  filterIsRepaired: false,
  taskToReset: null,
  currentTaskUpdated: false,
  showRecovery: false,
  filters: [
    {
      label: "Машина",
      key: "transport_id",
      value: "",
    },
    {
      label: "Название машины содержит",
      key: "transport_name",
      value: "",
    },
    {
      label: "Описание техники",
      key: "transport_description",
      value: "",
    },
    {
      label: "Система",
      key: "equipment_type_id",
      value: "",
    },
    {
      label: "Участок",
      key: "transport__location",
      value: "",
    },
    {
      label: "ФИО",
      key: "fio",
      value: "",
    },
    {
      label: "Статус обслуживания",
      key: "maintenance_result",
      value: "",
    },
  ],
  columns: [
    {
      id: "transport_id",
      name: "Машина",
      enabled: true,
      canBeFiltered: true,
      canBeHidden: false,
      inputType: "select",
    },
    {
      id: "transport_description",
      name: "Описание техники",
      enabled: true,
      canBeFiltered: true,
      canBeHidden: true,
      inputType: "input",
    },
    {
      id: "system",
      name: "Система",
      enabled: true,
      canBeFiltered: false,
      canBeHidden: true,
      inputType: "select",
    },
    {
      id: "location",
      name: "Участок",
      enabled: true,
      canBeFiltered: true,
      canBeHidden: true,
      inputType: "input",
    },
    {
      id: "group",
      name: "Группа",
      enabled: false,
      canBeFiltered: false,
      canBeHidden: true,
      inputType: "input",
    },
    {
      id: "comment",
      name: "Комментарии",
      enabled: false,
      canBeFiltered: false,
      canBeHidden: true,
      tooltip: "Общий комментарий в карточке/акте ТО",
    },
    {
      id: "fio",
      name: "ФИО",
      enabled: false,
      canBeFiltered: true,
      canBeHidden: true,
      tooltip: "Сотрудник, который выполнил ТО",
      inputType: "input",
    },
  ],
};

export const MaintenanceAttachedSlice = createSlice({
  name: "maintenance-attached",
  initialState,
  reducers: {
    setSelectedEquipmentType(state, action) {
      state.selectedEquipmentType = action.payload;
    },
    setState(_, action) {
      return action.payload;
    },
    setDate(state, action) {
      state.date = dayjs(action.payload).format(apiDateFormat);
    },
    toggleColumn(state, action) {
      const index = state.columns.findIndex((column) => column.id === action.payload.id);
      const column = state.columns[index];
      state.columns[index] = {
        ...column,
        enabled: !column.enabled,
      };
    },
    setFilters(state, action: PayloadAction<IFilter[]>) {
      state.filters = action.payload;
    },
    resetFilters(state) {
      state.filters = initialState.filters;
    },
    toggleFilterIsBroken(state) {
      state.filterIsBroken = !state.filterIsBroken;
    },
    toggleFilterIsRepaired(state) {
      state.filterIsRepaired = !state.filterIsRepaired;
    },
    toggleFilterHasComment(state) {
      state.filterHasComment = !state.filterHasComment;
    },
    setTaskToReset(state, action) {
      state.taskToReset = action.payload;
    },
    setCurrentTaskUpdated(state, action) {
      state.currentTaskUpdated = action.payload;
    },
    toggleShowRecovery(state) {
      state.showRecovery = !state.showRecovery;
    },
    setShowRecovery(state, action: { payload: boolean }) {
      state.showRecovery = action.payload;
    },
    reset() {
      return initialState;
    },
  },
});

export const {
  setSelectedEquipmentType,
  setState,
  toggleColumn,
  setFilters,
  toggleFilterIsBroken,
  toggleFilterHasComment,
  setTaskToReset,
  setCurrentTaskUpdated,
  toggleFilterIsRepaired,
  setDate,
  resetFilters,
  toggleShowRecovery,
  reset,
  setShowRecovery,
} = MaintenanceAttachedSlice.actions;
