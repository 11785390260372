import { StateNamesEnum } from "core/app/api/interfaces";

export function getTaskAvailableStates(currentState: string) {
  const availableStates = [];
  if (currentState === StateNamesEnum.Closed) {
    availableStates.push({
      title1: "Вернуть в работу",
      state: StateNamesEnum.InWork,
    });
  }
  if (currentState !== StateNamesEnum.Closed && currentState !== StateNamesEnum.InWork) {
    availableStates.push({
      title1: "Взять в работу",
      state: StateNamesEnum.InWork,
    });
  }
  if (currentState === StateNamesEnum.InWork) {
    availableStates.push({
      title1: "Приостановить",
      state: StateNamesEnum.OnPause,
    });
  }
  if (currentState !== StateNamesEnum.Closed && currentState !== StateNamesEnum.New) {
    availableStates.push({
      title1: "Закрыть задачу",
      state: StateNamesEnum.Closed,
    });
  }

  return availableStates;
}
