import { Button, Row, Col, Space, message, Modal, Form } from "antd";
import { ReactElement, useEffect, useState } from "react";
import { get } from "lodash";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { useAppDispatch } from "core/app/store";
import { setTaskToReset, setCurrentTaskUpdated } from "core/app/store/maintenance-attached-slice";
import { useGetTaskAttachmentsQuery, useGetTaskStatesQuery } from "core/app/api";
import { useUpdateTask } from "core/shared/task/useUpdateTask";
import { ITask, StateNamesEnum } from "core/app/api/interfaces";
import { IDictionaryItem } from "core/shared/interfaces";
import { setTask } from "core/app/store/task-slice";
import { useClosingDate } from "core/shared/task/useClosingDate";
import Text from "core/lib/Text/Text";
import { hasEmptyAttachmentsCategory } from "core/shared/task";
import { useDisabledByLocation } from "core/shared/task/useDisabledByLocation";
import { useNetworkState } from "core/shared/main/api/useNetworkState";
import { useDisabledByShiftStatus } from "core/shared/task/useDisabledByShift";

import { EmptyAttachmentWarning } from "../../Media/EmptyAttachmentWarning";

interface StateButtonsProps {
  task: ITask;
  hideClearButton?: boolean;
  onValidationFailed?: (errorInfo: any) => void;
  takeMteFromTask?: boolean;
}

function StateButtons(props: StateButtonsProps): ReactElement {
  const { task, hideClearButton, onValidationFailed, takeMteFromTask } = props;
  const dispatch = useAppDispatch();
  const { data: { results: states } = {} } = useGetTaskStatesQuery("");
  const [updateTaskRequest, updateTaskResult] = useUpdateTask(task?.discriminator);
  const [newStateName, setNewStateName] = useState<StateNamesEnum>();
  const disabledByShiftStatus = useDisabledByShiftStatus();
  const disabledByLocation = useDisabledByLocation();
  const { isOnline } = useNetworkState();
  const disabled = disabledByShiftStatus || disabledByLocation || !isOnline;
  const closingDate = useClosingDate(task);
  const { data: attachments } = useGetTaskAttachmentsQuery(task.id);

  const form = Form.useFormInstance();

  const handleChangeStatusClick = async (stateName: StateNamesEnum) => {
    setNewStateName(stateName);
    if (!states) {
      return;
    }
    const newState = states.find((state: IDictionaryItem) => state.name === stateName);
    if (newState) {
      if (stateName === StateNamesEnum.Closed && form) {
        try {
          await form.validateFields();
        } catch (e) {
          onValidationFailed?.(e);
          return;
        }
      }

      const values = form.getFieldsValue();

      const payload = {
        ...values,
        state_id: newState.id,
      };

      if (stateName === StateNamesEnum.Closed) {
        payload.closing_date = closingDate;
      }

      if (stateName === StateNamesEnum.InWork) {
        payload.closing_date = null;
      }

      /**
       * Временный костыль для форм в которых maintenance_execution изменяется не через Form api а через объект task.
       * Актуально для ООССА.
       */
      if (takeMteFromTask) {
        payload.maintenance_execution = task.maintenance_execution;
      }
      updateTaskRequest(payload);
    }
  };

  const handleCloseClick = () => {
    const handleOk = () => {
      handleChangeStatusClick(StateNamesEnum.Closed);
    };
    if (hasEmptyAttachmentsCategory(attachments)) {
      Modal.confirm({
        title: <Text boldness="black">Внимание</Text>,
        icon: <ExclamationCircleOutlined />,
        content: <EmptyAttachmentWarning attachments={attachments} />,
        okText: "Подтвердить",
        cancelText: "Отменить",
        onOk: handleOk,
      });
    } else {
      handleOk();
    }
  };

  const handleResetClick = () => {
    if (task) {
      dispatch(setTaskToReset(task.id));
    }
  };

  useEffect(() => {
    if (updateTaskResult.isSuccess) {
      dispatch(setCurrentTaskUpdated(true));
      dispatch(setTask(updateTaskResult.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updateTaskResult.isSuccess]);

  useEffect(() => {
    if (updateTaskResult.isError) {
      const errorMessage: string =
        get(updateTaskResult, "error.data.used_materials[0]") || get(updateTaskResult, "error.data.comment[0]", "");
      if (errorMessage) {
        message.error(errorMessage);
      }
    }
  }, [updateTaskResult]);

  return (
    <Row justify="end" className="mb-2">
      <Col>
        {task && task.state && (
          <Space>
            {task.state.name !== StateNamesEnum.Closed && !hideClearButton && (
              <Button disabled={disabled} onClick={handleResetClick}>
                ОЧИСТИТЬ ВВЕДЕННЫЕ ДАННЫЕ
              </Button>
            )}
            {task.state.name === StateNamesEnum.Closed && (
              <Button
                disabled={disabled}
                onClick={() => {
                  handleChangeStatusClick(StateNamesEnum.InWork);
                }}
                type="primary"
                loading={updateTaskResult.isLoading && newStateName === StateNamesEnum.InWork}
              >
                ВЕРНУТЬ В РАБОТУ
              </Button>
            )}
            {task.state.name !== StateNamesEnum.Closed && task.state.name !== StateNamesEnum.InWork && (
              <Button
                onClick={() => {
                  handleChangeStatusClick(StateNamesEnum.InWork);
                }}
                type="primary"
                disabled={disabled}
                loading={updateTaskResult.isLoading && newStateName === StateNamesEnum.InWork}
              >
                ВЗЯТЬ В РАБОТУ
              </Button>
            )}
            {task.state.name === StateNamesEnum.InWork && (
              <Button
                onClick={() => {
                  handleChangeStatusClick(StateNamesEnum.OnPause);
                }}
                type="primary"
                disabled={disabled}
                loading={updateTaskResult.isLoading && newStateName === StateNamesEnum.OnPause}
              >
                ПРИОСТАНОВИТЬ
              </Button>
            )}
            {task.state.name !== StateNamesEnum.Closed && task.state.name !== StateNamesEnum.New && (
              <Button
                onClick={() => {
                  handleCloseClick();
                }}
                type="primary"
                disabled={disabled}
                loading={updateTaskResult.isLoading && newStateName === StateNamesEnum.Closed}
              >
                ЗАКРЫТЬ ЗАДАЧУ
              </Button>
            )}
          </Space>
        )}
      </Col>
    </Row>
  );
}

export default StateButtons;
