import qs from "qs";

import { useGetCustomerRepresentativesQuery } from "core/app/api";

import { useUnitId } from "../unit/useUnitId";

const useCustomerRepresentatives = (queryExtraProps?: Record<string, any>) => {
  const [unit] = useUnitId();
  const queryString = qs.stringify({
    unit_id: unit,
    ...queryExtraProps,
  });
  return useGetCustomerRepresentativesQuery(queryString);
};

export { useCustomerRepresentatives };
