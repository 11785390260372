import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
}
/** @todo отличается только лейблом, надо рефакторить */
function VolsDepartment({ task }: FieldProps): ReactElement {
  return <Field label="Подразделение" value={task.customer_department?.name} />;
}

export { VolsDepartment };
