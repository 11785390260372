import qs from "qs";

import { useGetLocationsQuery } from "core/app/api";

import { useUnitId } from "../unit/useUnitId";

export interface ILocationFilterProps {
  is_mounted?: boolean;
  customer_department_id?: number | null;
}

const useLocations = (filter?: ILocationFilterProps) => {
  const [unit] = useUnitId();
  const employeesQS = qs.stringify({
    unit,
    limit: 500,
    ...filter,
  });
  return useGetLocationsQuery(employeesQS);
};

export function useLocation(id: number) {
  const { data } = useLocations();
  const locations = data?.results;
  return locations?.find((item) => item.id === id);
}

export { useLocations };
