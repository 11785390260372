import { ReactElement, useContext } from "react";

import { useGetDashboardShiftStatisticTasksByTeamsPspaQuery } from "core/app/api";
import { StatisticTeamSelector } from "core/shared/dashboard/ui/TeamSelector";
import { ShiftContext } from "core/shared/dashboard/context/shiftContext";

export function StatisticTeamSelectorPspa(): ReactElement {
  const { queryString } = useContext(ShiftContext);
  const { data, isSuccess, isFetching } = useGetDashboardShiftStatisticTasksByTeamsPspaQuery(queryString, {
    skip: !queryString,
  });

  if (!isFetching && isSuccess && data) {
    return <StatisticTeamSelector teams={data} />;
  }
  return <div />;
}
