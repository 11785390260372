import { ReactNode } from "react";
import { Space } from "antd";

import AccidentsStatistic from "./Accidents";
import ShiftsStatistic from "./Shifts";
import MaintenanceStatistic from "./Maintenance";

/** Статистика по участку. */
export default function UnitStatistic(): ReactNode {
  return (
    <Space direction="vertical" className="w-100" size={16}>
      <AccidentsStatistic />
      <ShiftsStatistic />
      <MaintenanceStatistic />
    </Space>
  );
}
