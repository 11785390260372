import React, { ReactElement } from "react";
import { Row, Col } from "antd";

import Text from "core/lib/Text/Text";
import { useGetEquipmentTypeQuery } from "core/app/api";

interface IsMountedProps {
  equipmentTypeId: string | number;
}

function IsMounted({ equipmentTypeId }: IsMountedProps): ReactElement {
  const { data } = useGetEquipmentTypeQuery(equipmentTypeId);

  return (
    <Row>
      <Col>
        <Text size="label2">Признак:&nbsp;</Text>
      </Col>
      <Col>
        <Text size="label2" boldness="black">
          {data?.is_mounted && "Навесное"}
          {!data?.is_mounted && "Стационарное"}
        </Text>
      </Col>
    </Row>
  );
}

export default IsMounted;
