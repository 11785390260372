import { ReactElement } from "react";
import { Select, Form } from "antd";

import { IDictionaryItem } from "core/shared/interfaces";
import { useLocations } from "core/shared/useLocations";

interface LocationFieldProps {
  required?: true;
}

function LocationField({ required }: LocationFieldProps): ReactElement {
  const form = Form.useFormInstance();
  const { data } = useLocations();

  const handleSelectChange = () => {
    form.setFieldsValue({ transportId: null, equipmentId: null });
  };

  let options = [
    {
      label: "",
      value: 0,
    },
  ];
  if (data?.results) {
    options = data.results.map((item: IDictionaryItem) => ({
      label: item.name,
      value: item.id,
    }));
  }

  return (
    <Form.Item
      label="МЕСТОПОЛОЖЕНИЕ"
      name="locationId"
      colon={false}
      rules={required && [{ required: true, message: "" }]}
    >
      <Select
        onChange={handleSelectChange}
        allowClear
        optionFilterProp="children"
        showSearch
        size="large"
        filterOption={(input, option) => (option?.label?.toString() ?? "").toLowerCase().includes(input.toLowerCase())}
        options={options}
      />
    </Form.Item>
  );
}

export default LocationField;
