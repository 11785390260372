import { useEffect, useState } from "react";

import { useGetWhoamiQuery } from "core/app/api";
import { useAppSelector } from "core/app/store";
import { useUpdateUiPermissionsMutation } from "core/app/api/api";

import { getPermissionDecls } from "../permissions/utils/permissionGroupUtils";

function useUpdateUserPermissions() {
  const { token } = useAppSelector((store) => store.token);
  const { data } = useGetWhoamiQuery("", { skip: !token });
  const [updatePermissionsRequest] = useUpdateUiPermissionsMutation();
  const [userInfo, setUserInfo] = useState<{
    prevUserName?: string;
    currentUserName?: string;
  }>({});

  useEffect(() => {
    if (data) {
      setUserInfo((prevValue) => ({
        prevUserName: prevValue.currentUserName,
        currentUserName: data.username,
      }));
    }
  }, [data]);

  useEffect(() => {
    if (token && userInfo.currentUserName !== userInfo.prevUserName) {
      updatePermissionsRequest(
        getPermissionDecls().map(({ key, description }) => ({
          key,
          name: description,
        }))
      );
    }
  }, [token, userInfo.currentUserName, userInfo.prevUserName, updatePermissionsRequest]);
}

export { useUpdateUserPermissions };
