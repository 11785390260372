import { capitalize } from "lodash";

import { ITask, IEtoTask } from "core/app/api/interfaces";
import { useUnitShifts } from "core/shared/unit/useUnitShifts";

import { Field } from "../Field";

type TShiftProps = {
  task: ITask | IEtoTask;
};

export function Shift({ task }: TShiftProps) {
  const { data } = useUnitShifts();

  const taskShift = data?.find((shift) => shift.number === task.shift_number);
  const shiftName = taskShift?.name || String(task.shift_number);

  return <Field label="Смена" value={capitalize(shiftName)} />;
}
