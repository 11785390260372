import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
}
/** @todo отличается только лейблом, надо рефакторить */
function VolsEquipmentType({ task }: FieldProps): ReactElement {
  return <Field label="Тип" value={task.equipment_type?.name} />;
}

export { VolsEquipmentType };
