import { Form, Input } from "antd";
import { TextAreaProps } from "antd/lib/input";
import clsx from "clsx";

import Text from "core/lib/Text/Text";

import "./styles.scss";

export type TTextFieldProps = {
  label?: string;
} & TextAreaProps;

function TextField({ label, name, className, placeholder, ...otherProps }: TTextFieldProps) {
  return (
    <Form.Item
      name={name}
      label={
        label && (
          <Text transform="uppercase" size="label4">
            {label}
          </Text>
        )
      }
    >
      <Input.TextArea
        className={clsx("text-field", className)}
        placeholder={placeholder || "Комментарий"}
        {...otherProps}
      />
    </Form.Item>
  );
}

export default TextField;
