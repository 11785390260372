import { Row, Col, Divider, Button, Form, InputNumber } from "antd";
import { ReactElement, useEffect } from "react";

import { useAppDispatch } from "core/app/store";
import { useUpdateTask } from "core/shared/task/useUpdateTask";
import { setCurrentTaskUpdated } from "core/app/store/maintenance-attached-slice";
import Media from "core/widgets/TaskModals/shared/Media";
import { useDisabled } from "core/shared/task/useDisabled";
import { useTask } from "core/shared/task/useTask";
import { ITask } from "core/app/api/interfaces";
import TaskTitle from "core/widgets/TaskModals/shared/TaskTitle";
import StateButtons from "core/widgets/TaskModals/shared/StateButtons/FormControlled";
import { SignatureButtons } from "core/widgets/TaskModals/shared/SignatureButtons";
import { ElapsedTime } from "core/widgets/TaskModals/shared/ElapsedTime";
import { EmployeesSelect as EmployeesSelectFormControlled } from "core/widgets/TaskModals/shared/EmployeesSelect/FormControlled";
import { Comment } from "core/widgets/TaskModals/shared/Comment";
import { ClosingDate } from "core/widgets/TaskModals/shared/ClosingDate";
import UsedMaterialsTextArea from "core/widgets/TaskModals/shared/UsedMaterialsTextArea";

import GeneralInfo from "./GeneralInfo";
import { Body } from "./Body";

import "../styles.scss";

/** Карточка технического обслуживания (ТО) для навесного оборудования ПСПА. */
function Content(): ReactElement {
  const { data: task } = useTask();
  const dispatch = useAppDispatch();
  const [updateTaskRequest, updateTaskResult] = useUpdateTask(task?.discriminator);
  const disabled = useDisabled();
  const [form] = Form.useForm();

  const handleSave = async (values: Partial<ITask>) => {
    updateTaskRequest(values);
  };

  useEffect(() => {
    if (updateTaskResult.isSuccess) {
      dispatch(setCurrentTaskUpdated(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updateTaskResult.isSuccess]);
  if (task) {
    return (
      <>
        <TaskTitle icon task={task} />
        <>
          <Form form={form} initialValues={task} scrollToFirstError onFinish={handleSave} layout="vertical">
            <Form.Item name="id" label="id" hidden>
              <InputNumber />
            </Form.Item>
            <GeneralInfo task={task} />
            <StateButtons task={task} />
            <Divider />
            <Body />
            <Divider />
            <Row gutter={16}>
              <Col span={13}>
                <EmployeesSelectFormControlled disabled={disabled} teamId={task.team_id} />
                <ElapsedTime disabled={disabled} />
                <Row>
                  <Col>
                    <ClosingDate value={task.planned_date} />
                  </Col>
                </Row>
              </Col>
              <Col span={11}>
                <Comment disabled={disabled} />
                <UsedMaterialsTextArea disabled={disabled} />
              </Col>
            </Row>
          </Form>
          <Media task={task} />
          <SignatureButtons task={task} />
          <Divider />
          <Row>
            <Col span={24}>
              <Button
                type="primary"
                size="large"
                className="w-100"
                onClick={form.submit}
                loading={updateTaskResult.isLoading}
                disabled={disabled}
              >
                Сохранить
              </Button>
            </Col>
          </Row>
        </>
      </>
    );
  }
  return <div />;
}

export default Content;
