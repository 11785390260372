import { ReactElement } from "react";

import { IMaintenanceExecutionWork } from "core/app/api/interfaces";
import Text from "core/lib/Text/Text";

interface OossaMaintenanceTaskExecutionGroupWorkProps {
  workItem: IMaintenanceExecutionWork;
}

export default function OossaMaintenanceTaskExecutionGroupWork({
  workItem,
}: OossaMaintenanceTaskExecutionGroupWorkProps): ReactElement {
  const groupId = workItem?.group_number;

  const groupsNames = [];
  groupsNames[3] = null; // т.к. заголовок такой группы пустой, то она должна быть первой по номеру группы
  groupsNames[4] = "CISCO ASA5505";
  groupsNames[5] = "CISCO Firepower 1010";
  groupsNames[6] = "CISCO SG350";
  groupsNames[7] = "Eaton 9E 1000I";
  groupsNames[8] = "Eaton 9SX 1000";
  groupsNames[9] = "SIMATIC IOT2050";
  groupsNames[10] = "SIMATIC S7-1200";
  groupsNames[11] = "БС ВЕГА";

  return groupId && groupsNames[groupId] === null ? (
    <span /> // не выводить заголовок такой группы, но тогда она должна быть первой
  ) : (
    <div className="mb-1">
      <Text size="label1" boldness="semibold">
        {groupId ? groupsNames[groupId] || groupId : "Группа не указана"}
      </Text>
    </div>
  );
}
