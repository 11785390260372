import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
}

function SlpiMaintenanceName({ task }: FieldProps): ReactElement {
  return <Field label="Номер ТО" value={task.slpi_maintenance_name} />;
}

export { SlpiMaintenanceName };
