import { useEffect, useState } from "react";
import { Modal, Typography } from "antd";

import { useAppSelector } from "core/app/store";
import { useNetworkState } from "core/shared/main/api/useNetworkState";
import { signaturesToSendSelector } from "core/app/store/task-signature-slice";
import { useSendSignature } from "core/shared/offline-mode/useSendSignature";

const { Text } = Typography;

function SignatureWorkerWrapper() {
  const [shouldSend, setShouldSend] = useState(true);
  const signaturesToSend = useAppSelector(signaturesToSendSelector);
  const { isNetwork, isOnline } = useNetworkState();
  const sendSignature = useSendSignature();

  useEffect(() => {
    if (isNetwork && isOnline && signaturesToSend.length && shouldSend) {
      sendSignature(signaturesToSend[0]);
    }
  }, [isNetwork, sendSignature, signaturesToSend, isOnline, shouldSend]);

  return (
    <Modal
      open={Boolean(isNetwork && isOnline && signaturesToSend.length && shouldSend)}
      title="Отправка подписей"
      centered
      footer={null}
      closable
      onCancel={() => setShouldSend(false)}
    >
      <Text>Выполняется отправка подписей. Пожалуйста, подождите...</Text>
    </Modal>
  );
}

export default SignatureWorkerWrapper;
