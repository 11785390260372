import qs from "qs";

import { useGetSlpiSystemComplexQuery } from "core/app/api";

const useObjectSystemComplexes = (queryParams: Record<string, string | number | null>) =>
  useGetSlpiSystemComplexQuery(
    qs.stringify({
      ...queryParams,
    })
  );

export { useObjectSystemComplexes };
