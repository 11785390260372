/** @jsxImportSource @emotion/react */
import { CSSObject } from "@emotion/react";

import icCollapse from "core/images/ic-collapse.svg";

interface Props {
  open: boolean;
  reverse?: boolean;
}

const styles: CSSObject = {
  transition: "transform 0.3s",
  cursor: "pointer",
  verticalAlign: "middle",
};

function IcCollapse({ open, reverse = false }: Props) {
  let transform = open ? "rotate(0deg)" : "rotate(-90deg)";
  if (reverse) {
    transform = open ? "rotate(0deg)" : "rotate(180deg)";
  }
  return <img src={icCollapse} alt="collapse" css={[styles, { transform }]} />;
}

export { IcCollapse };
