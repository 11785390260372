import { Select, Form, FormInstance } from "antd";
import { ReactElement } from "react";
import qs from "qs";

import { useGetItsmEquipmentMalfunctionReasonQuery } from "core/app/api";
import { StateNamesEnum } from "core/app/api/interfaces";
import { useTask } from "core/shared/task/useTask";

interface MalfunctionSelectProps {
  disabled: boolean;
  form: FormInstance;
}

function MalfunctionSelect({ disabled, form }: MalfunctionSelectProps): ReactElement {
  const malfunctionId = Form.useWatch("malfunction_id");
  const { data: task } = useTask();

  const queryParams = {
    equipment_type_malfunction_id: malfunctionId,
  };

  const { data: itsmEquipmentMalfunctionReasons, isFetching } = useGetItsmEquipmentMalfunctionReasonQuery(
    qs.stringify(queryParams)
  );

  let options: {
    label: string;
    value: number;
  }[] = [];

  if (itsmEquipmentMalfunctionReasons) {
    options = itsmEquipmentMalfunctionReasons.map((itsmEquipmentMalfunctionReason) => ({
      label: itsmEquipmentMalfunctionReason.name,
      value: itsmEquipmentMalfunctionReason.id,
    }));
  }

  return (
    <Form.Item
      label="ПРИЧИНА ИНЦИДЕНТА"
      name="malfunction_reason_id"
      rules={[
        {
          validator: (_, value) => {
            const newState = form.getFieldValue("newState");
            if (newState === StateNamesEnum.Closed && !value && !!task?.itsm_equipment) {
              return Promise.reject(new Error("Пожалуйста, введите ПРИЧИНА ИНЦИДЕНТА"));
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <Select
        disabled={disabled || !task?.itsm_equipment}
        style={{ width: "100%" }}
        dropdownStyle={{ zIndex: 2000 }}
        dropdownMatchSelectWidth={650}
        options={options}
        loading={isFetching}
        allowClear
        showSearch
        filterOption={(input, option) => (option?.label?.toString() ?? "").toLowerCase().includes(input.toLowerCase())}
      />
    </Form.Item>
  );
}

export default MalfunctionSelect;
