import { useCallback } from "react";

import { useAppSelector, useAppDispatch } from "core/app/store";
import { setPrintTaskReportModalState, PrintTaskReportModalStatesEnum } from "core/app/store/temp-slice";

const usePrintTaskReportModalOpen = (): [
  PrintTaskReportModalStatesEnum,
  (val: PrintTaskReportModalStatesEnum) => void
] => {
  const dispatch = useAppDispatch();
  const { printTaskReportModalState } = useAppSelector((state) => state.temp);

  const set = useCallback(
    (val: PrintTaskReportModalStatesEnum) => {
      dispatch(setPrintTaskReportModalState(val));
    },
    [dispatch]
  );

  return [printTaskReportModalState, set];
};

export { usePrintTaskReportModalOpen };
