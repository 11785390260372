import { Row, Col } from "antd";
import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";
import { Field } from "core/widgets/TaskModals/shared/Fields/Field";
import { isLkuTask, isLsoTask, isOossaTask, isPspaTask, isSlpiTask, isVolsTask } from "core/shared/task";

import SlpiGeneralInfo from "./SlpiGeneralInfo";
import OossaGeneralInfo from "./OossaGeneralInfo";
import DefaultGeneralInfo from "./DefaultGeneralInfo";
import LsoGeneralInfo from "./LsoGeneralInfo";
import { LkuGeneralInfo } from "./LkuGeneralInfo";
import { VolsGeneralInfo } from "./VolsGeneralInfo";

interface GeneralInfoProps {
  task: ITask;
}

/** Заглавная часть (раздел) в карточке доп. работ. */
function GeneralInfo({ task }: GeneralInfoProps): ReactElement {
  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <Field label="Описание" value={task.request_description} />
        </Col>
      </Row>
      {isSlpiTask(task) && <SlpiGeneralInfo task={task} />}
      {isOossaTask(task) && <OossaGeneralInfo task={task} />}
      {isPspaTask(task) && <DefaultGeneralInfo task={task} />}
      {isLsoTask(task) && <LsoGeneralInfo task={task} />}
      {isLkuTask(task) && <LkuGeneralInfo task={task} />}
      {isVolsTask(task) && <VolsGeneralInfo task={task} />}
    </>
  );
}

export default GeneralInfo;
