import { useState, useMemo, useEffect } from "react";

import { EtoTaskStepNameEnum } from "core/app/api/interfaces";

import { IAssistanceCarMaintenanceContextValues, FormPagesEnum } from "../AssistanceCarMaintenanceContext";

import { useCurrentStep } from "./useCurrentStep";

export function useAssistanceCarMaintenanceContextValues(): IAssistanceCarMaintenanceContextValues {
  const [page, setPage] = useState(FormPagesEnum.TASK);
  const currentStep = useCurrentStep();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [signatureSide, setSignatureSide] = useState<IAssistanceCarMaintenanceContextValues["signatureSide"]>(null);
  const [selectedStepTab, setSelectedStepTab] =
    useState<IAssistanceCarMaintenanceContextValues["selectedStepTab"]>(null);
  const [errorsSteps, setErrorsSteps] = useState<IAssistanceCarMaintenanceContextValues["errorsSteps"]>({
    PERFORMING_ETO: 0,
    PRE_SHIFT_CHECK: 0,
    POST_SHIFT_CHECK: 0,
  });

  useEffect(() => {
    if (selectedStepTab === null && currentStep) {
      setSelectedStepTab(currentStep.step_name);
    }
  }, [selectedStepTab, currentStep]);

  return useMemo(
    () => ({
      page,
      setPage,
      showErrorMessage,
      setShowErrorMessage,
      errorsSteps,
      setErrorsSteps,
      signatureSide,
      setSignatureSide,
      selectedStepTab,
      setSelectedStepTab,
    }),
    [page, showErrorMessage, errorsSteps, signatureSide, selectedStepTab]
  );
}
