import { Tag } from "antd";

import { TAG_COLORS } from "core/shared/oossa";
import { OossaSystemTypeEnum } from "core/app/api/interfaces";

interface IObjectNameProps {
  object: {
    object_name: string;
    system_name: string;
    code_enum: OossaSystemTypeEnum;
  };
}

function ObjectName({ object }: IObjectNameProps) {
  return (
    <>
      {object.object_name} <br />
      <Tag color={TAG_COLORS[object.code_enum]}>{object.system_name}</Tag>
    </>
  );
}

export { ObjectName };
