import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
}

function RequestStatus({ task }: FieldProps): ReactElement {
  return <Field label="Статус в Creatio" value={task.request_status} />;
}

export { RequestStatus };
