import { useEffect, useMemo, useState } from "react";

import { useAppSelector } from "core/app/store";
import { networkSelector } from "core/app/store/network-slice";

export function useNetworkState() {
  const { isOnline } = useAppSelector(networkSelector);
  const [networkStatus, setNetworkStatus] = useState({
    prevState: window.navigator.onLine,
    state: window.navigator.onLine,
  });
  const updateNetworkStatus = () => {
    setNetworkStatus((prevState) => ({
      prevState: prevState.state,
      state: window.navigator.onLine,
    }));
  };
  useEffect(() => {
    window.addEventListener("offline", updateNetworkStatus);
    window.addEventListener("online", updateNetworkStatus);
    return () => {
      window.removeEventListener("offline", updateNetworkStatus);
      window.removeEventListener("online", updateNetworkStatus);
    };
  });
  return useMemo(
    () => ({
      isOnline,
      isNetwork: networkStatus.state,
    }),
    [networkStatus.state, isOnline]
  );
}
