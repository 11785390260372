import qs from "qs";

import { useGetCustomersQuery } from "core/app/api";
import { useUnitId } from "core/shared/unit/useUnitId";

export function useCustomers(filters?: Record<string, any>) {
  const [unitId] = useUnitId();
  return useGetCustomersQuery(
    qs.stringify({
      unit_id: unitId,
      ...filters,
    })
  );
}
