import { ReactElement } from "react";
import { Col, Row } from "antd";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import {
  IMaintenanceExecutionCheckListItem,
  IMaintenanceExecutionValuesItem,
  IMaintenanceExecutionWork,
  OossaSystemTypeEnum,
  ITask,
} from "core/app/api/interfaces";
import { Field } from "core/widgets/TaskModals/shared/Fields/Field";
import { useGetOossaTechNodeQuery } from "core/app/api/api";
import { useOossaSystem } from "core/shared/oossa/api/useOossaSystems";

import OossaMaintenanceTaskExecutionWork from "./OossaMteWork";

interface OossaMaintenanceTaskExecutionItemProps {
  task: ITask;
  index: number;
}

function getGroupNumber(item: IMaintenanceExecutionWork): number {
  const checkItem = item as IMaintenanceExecutionCheckListItem;
  if (checkItem.check) return checkItem.check.group_number;
  const valueItem = item as IMaintenanceExecutionValuesItem;
  if (valueItem.field) return valueItem.field.group_number;
  return 0;
}

/** Список работ карточки задания на ТО (cодержимое под закладкой, или без закладок). */
export default function OossaMaintenanceTaskExecutionItem({
  task,
  index,
}: OossaMaintenanceTaskExecutionItemProps): ReactElement {
  const oossaSystem = useOossaSystem(task.system_id || 0);
  const maintenanceExecution = task.maintenance_execution[index];

  // АСКУЭ - может быть несколько единиц оборудования, отбираем по типу оборудования, нужному на текущей закладке карточки ТО
  const { data: oossaTechNode } = useGetOossaTechNodeQuery(task.oossa_tech_node_id ?? skipToken);
  const oossaEquipments = oossaTechNode?.oossaequipments.filter(
    // eslint-disable-next-line camelcase
    ({ equipment_type }) => equipment_type.id === maintenanceExecution?.equipment_type?.id
  );

  let w: IMaintenanceExecutionWork[] = [];
  let works: IMaintenanceExecutionWork[] = [];
  if (maintenanceExecution) {
    w = [...maintenanceExecution.checklist, ...maintenanceExecution.values];
    works = w.map((element) => ({ ...element, group_number: getGroupNumber(element) } as IMaintenanceExecutionWork));
    works.sort((a, b) => {
      // самая первая группа будет без заголовка
      const a1 = `${a.group_number?.toString().padStart(3, "0")}_${a.order_number?.toString().padStart(4, "0")}`;
      const b1 = `${b.group_number?.toString().padStart(3, "0")}_${b.order_number?.toString().padStart(4, "0")}`;

      if (a1 < b1) return -1;
      if (a1 > b1) return 1;
      return 0;
    });
  }

  return (
    <>
      {oossaSystem?.code_enum !== OossaSystemTypeEnum.SMZIS && (
        // Только если АСКУЭ, АСКУЭ ЭЭ
        <Row gutter={12} style={{ marginBottom: "16px" }}>
          <Col span={12}>
            <Field label="Серийный номер" value={oossaEquipments?.map((oe) => oe.serial_number).join(", ")} />
            <Field label="Марка/модель" value={oossaEquipments?.map((oe) => oe.model).join(", ")} />
          </Col>
          <Col>
            <Field label="Дата производства" value={oossaEquipments?.map((oe) => oe.manufactured_date).join(", ")} />
            <Field label="ДУ, мм" value={oossaEquipments?.map((oe) => oe.nominal_diameter).join(", ")} />
            <Field label="Дата поверки" value={oossaEquipments?.map((oe) => oe.verification_date).join(", ")} />
          </Col>
        </Row>
      )}

      {/* Для всех - АСКУЭ, АСКУЭ ЭЭ, СМЗиС */}
      <Row gutter={16}>
        <div className="mb-3">
          {works.map((workItem, i) => {
            const showGroupName = i === 0 || works[i - 1].group_number !== workItem.group_number;
            return (
              <OossaMaintenanceTaskExecutionWork
                key={workItem.id}
                maintenanceExecutionId={maintenanceExecution.id}
                workItem={workItem}
                showGroupName={showGroupName}
              />
            );
          })}
        </div>
      </Row>
    </>
  );
}
