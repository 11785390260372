import { Form, Input } from "antd";

const { TextArea } = Input;

interface Props {
  disabled?: boolean;
  required?: boolean;
}
export function Comment({ disabled, required }: Props) {
  return (
    <Form.Item
      label="КОММЕНТАРИЙ"
      name="comment"
      required={required}
      rules={[
        {
          message: "",
          required,
        },
      ]}
    >
      <TextArea rows={5} disabled={disabled} />
    </Form.Item>
  );
}
