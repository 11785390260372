import { createSlice } from "@reduxjs/toolkit";

interface IDashboard {
  shift: 1 | 2 | 3;
}

const initialState: IDashboard = {
  shift: 2,
};

export const DashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setShift(state, action) {
      state.shift = action.payload;
    },
  },
});

export const { setShift } = DashboardSlice.actions;
