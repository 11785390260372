import { ReactElement, useState, useCallback } from "react";
import { Row, Col, Button, Modal } from "antd";

import Text from "core/lib/Text/Text";
import { ITask, TaskTypesIdsEnum } from "core/app/api/interfaces";

interface FieldProps {
  task: ITask;
}

function Number({ task }: FieldProps): ReactElement {
  const [openTaskInfo, setOpenTaskInfo] = useState(false);
  const handleTaskClick = useCallback(() => {
    setOpenTaskInfo(true);
  }, []);

  const handleCloseTaskInfo = useCallback(() => {
    setOpenTaskInfo(false);
  }, []);

  return (
    <Row gutter={4}>
      <Col>
        <Text size="label2">Номер заявки:</Text>
      </Col>
      <Col>
        <Text className="blue" size="label2" boldness="black">
          {task.type === TaskTypesIdsEnum.MAINTENANCE ? (
            task.number
          ) : (
            <Button
              type="link"
              style={{ padding: 0, height: "auto", fontWeight: 400, fontSize: "12px", lineHeight: "12px" }}
              onClick={handleTaskClick}
            >
              {task.number}
            </Button>
          )}
        </Text>
      </Col>
      <Modal
        title={`Описание задачи ${task.number}`}
        open={openTaskInfo}
        onOk={handleCloseTaskInfo}
        onCancel={handleCloseTaskInfo}
        cancelButtonProps={{ style: { display: "none" } }}
        okText="Закрыть"
      >
        <Text style={{ fontSize: "14px", lineHeight: "15px" }}>{task.request_description}</Text>
      </Modal>
    </Row>
  );
}

export { Number };
