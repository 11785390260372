import React, { ReactElement } from "react";
import { Row, Col } from "antd";

import Text from "core/lib/Text/Text";
import { useGetUnitQuery } from "core/app/api";

interface UnitProps {
  unitId: string | number;
}

function Unit({ unitId }: UnitProps): ReactElement {
  const { data: unit } = useGetUnitQuery(unitId);

  return (
    <Row>
      <Col>
        <Text size="label2">Объект (рудник):&nbsp;</Text>
      </Col>
      <Col>
        <Text size="label2" boldness="black">
          {unit?.name}
        </Text>
      </Col>
    </Row>
  );
}

export default Unit;
