import { ReactElement } from "react";

import { isGroup } from "core/shared/dashboard";

import { ICellProps } from "..";
import { Paragraph } from "../Paragraph";

function ClosingNote({ record }: ICellProps): ReactElement {
  if (isGroup(record)) {
    return <div />;
  }
  return <Paragraph>{record.closing_note}</Paragraph>;
}

export default ClosingNote;
