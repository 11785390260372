import { ReactElement } from "react";
import { Alert, Tabs } from "antd";

import { OossaSystemTypeEnum, ITask } from "core/app/api/interfaces";
import Tab from "core/widgets/TaskModals/shared/Tab";
import { useOossaSystem } from "core/shared/oossa/api/useOossaSystems";

import OossaMaintenanceTaskExecutionItem from "./OossaMteItem";

interface MaintenanceExecutionProps {
  task: ITask;
}

/** Раздел выполнения работ в карточке технического обслуживания (ТО).
 * Используется для ООССА. */
export default function OossaMaintenanceTaskExecution({ task }: MaintenanceExecutionProps): ReactElement {
  const oossaSystem = useOossaSystem(task.system_id || 0);

  if (!task.maintenance_execution?.length) return <Alert message="Ошибка данных" showIcon type="error" />;

  if (oossaSystem?.code_enum !== OossaSystemTypeEnum.SMZIS) {
    // Если АСКУЭ, АСКУЭ ЭЭ
    const tabs = task.maintenance_execution?.map((item, index) => ({
      key: item.equipment_type.id.toString(),
      label: <Tab maintenanceExecution={item} />,
      children: <OossaMaintenanceTaskExecutionItem task={task} index={index} />,
    }));
    return <Tabs items={tabs} />;
  }
  // Если СМЗиС
  return <OossaMaintenanceTaskExecutionItem task={task} index={0} />;
}
