import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
}

function Address({ task }: FieldProps): ReactElement {
  return <Field label="Адрес" value={task.address?.name} />;
}

export { Address };
