import { createTheme } from "@mui/material";

/** Тема приложения по-умолчанию (дизайн UI-элементов) */
const theme = createTheme({
  spacing: 8, // обычное расстояние между ui-элементами
  typography: {
    fontFamily: ["ProximaNova-Regular", "Roboto", "sans-serif"].join(","),
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          boxShadow: "none",
        },
        text: {
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
        contained: {
          minHeight: 40,
          fontWeight: 500,
          fontSize: 13,
          lineHeight: "20px",
          borderRadius: 8,
        },
        containedPrimary: {
          backgroundColor: "var(--blue-2)",
          "&:hover": {
            backgroundColor: "var(--blue-2)",
          },
        },
        containedSecondary: {
          backgroundColor: "var(--blue-5)",
          "&:hover": {
            backgroundColor: "var(--blue-5)",
          },
        },
        containedSuccess: {
          backgroundColor: "var(--blue-8)",
          "&:hover": {
            backgroundColor: "var(--blue-8)",
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "64px",
          padding: "0 30px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "var(--white)",
          borderRadius: 4,
          "input, textarea": {
            color: "var(--blue-6)",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "24px",
            padding: "8px 14px",
          },
          textarea: {
            "&.Mui-disabled": {
              WebkitTextFillColor: "inherit",
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        colorSecondary: {
          backgroundColor: "var(--gray-4)",
          color: "var(--white)",
          "&:hover": {
            backgroundColor: "var(--gray-4)",
          },
        },
        sizeSmall: {
          width: 30,
          height: 30,
        },
        sizeLarge: {
          width: 60,
          height: 60,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderBottom: `1px solid var(--common-border)`,
          height: "56px",
        },
        colorPrimary: {
          background: "#e2ebf0",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorLeft: {
          width: "100%",
          background: "var(--gradient-menu-background)",
          maxWidth: 400,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          background: "var(--gradient-card-background)",
          boxShadow: "var(--shadow-card)",
          borderRadius: 8,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "var(--blue-7)",
          borderColor: "transparent",
        },
        light: {
          backgroundColor: "var(--gray-0)",
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        grouped: {
          borderRadius: "8px",
          "&:not(:last-of-type)": {
            borderRadius: "8px",
            border: 0,
            marginLeft: 0,
          },
          "&:not(:first-of-type)": {
            borderRadius: "8px",
            marginLeft: 0,
            border: 0,
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          p: {
            color: "var(--white)",
          },
          padding: "12px 24px",
          "&:hover": {
            backgroundColor: "var(--white)",
            p: {
              color: "var(--blue-6)",
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          backgroundColor: "var(--white)",
          padding: "8px 14px",
          color: "var(--blue-6)",
          fontWeight: 400,
          fontSize: 16,
          lineHeight: "24px",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "var(--blue-2)",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: "var(--blue-6)",
          opacity: 1,
          backgroundColor: "transparent",
          "&.Mui-selected": {
            color: "var(--blue-2)",
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          marginRight: "8px",
          border: 0,
          padding: "6px 12px",
          textTransform: "none",
          borderRadius: "8px",
          backgroundColor: "var(--gray-2)",
          "&:hover": {
            backgroundColor: "var(--gray-2)",
          },
          "&.Mui-selected": {
            p: {
              color: "var(--white) !important",
            },
            backgroundColor: "var(--blue-4)",
            "&:hover": {
              backgroundColor: "var(--blue-4)",
            },
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "transparent",
            border: "1px solid var(--gray-4)",
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&.transparent": {
            backgroundColor: "transparent",
            boxShadow: "none",
            margin: 0,
            ".Mui-expanded": {
              borderBottom: 0,
            },
            ".MuiAccordionSummary-root, .MuiAccordionDetails-root": {
              padding: 0,
            },

            "&:before": {
              opacity: 0,
            },
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 12,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: 40,
          "&.Mui-expanded": {
            minHeight: 40,
            borderBottom: "1px solid var(--gray-0)",
          },
        },
        content: {
          margin: 0,
          padding: "12px 0",
          "&.Mui-expanded": {
            margin: 0,
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          ".MuiBackdrop-root": {
            "&.notifications-popover": {
              background: "var(--gradien-backdrop)",
              backdropFilter: "blur(4px)",
            },
          },
        },
        paper: {
          "&.notifications-popover-paper": {
            boxShadow: "none",
            borderRadius: 8,
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        paragraph: {
          color: "#4A545D",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "15px",
          margin: 0,
          whiteSpace: "pre-wrap",
        },
        h1: {
          fontSize: "24px",
          lineHeight: "29px",
          color: "#4A4A4A",
          fontWeight: 600,
        },
        h2: {
          fontSize: "18px",
          lineHeight: "22px",
          color: "#4A4A4A",
          fontWeight: 600,
        },
        h3: {
          fontSize: "12px",
          lineHeight: "14px",
          color: "#4A545D",
          fontWeight: 600,
        },
        caption: {
          fontWeight: 300,
          fontSize: "13px",
          lineHeight: "16px",
          color: "var(--gray-4)",
        },
        subtitle1: {
          fontWeight: 300,
          fontSize: "13px",
          lineHeight: "16px",
          color: "var(--gray-4)",
        },
        subtitle2: {
          color: "var(--gray-4)",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "24px",
          margin: 0,
        },
      },
    },
  },
});

export default theme;
