import { Col, Row, Switch, Tooltip } from "antd";
import { ReactElement } from "react";

import Text from "core/lib/Text/Text";
import { useAppDispatch } from "core/app/store";
import { setMaintenanceExecutionValueById } from "core/app/store/task-slice";
import { IMaintenanceExecutionValuesItem } from "core/app/api/interfaces";
import { useDisabled } from "core/shared/task/useDisabled";

interface OossaMaintenanceExecutionValueProps {
  maintenanceExecutionId: number;
  valuesItem: IMaintenanceExecutionValuesItem;
}

/** Текущее значение выполнения работы (например, измеренное значение) по заданию на ТО. */
export default function OossaMaintenanceExecutionValue(props: OossaMaintenanceExecutionValueProps): ReactElement {
  const { maintenanceExecutionId, valuesItem } = props;
  const dispatch = useAppDispatch();
  const disabled = useDisabled();

  const handleValueSwitchChange = (checked: boolean) => {
    dispatch(
      setMaintenanceExecutionValueById({
        maintenanceExecutionId,
        valuesItemId: valuesItem.id,
        value: checked ? "1" : "0",
      })
    );
  };

  return (
    <div>
      {/* <MaintenanceExecutionValue key={valueItem.id} valueItem={valueItem} /> */}
      <Row key={valuesItem.id} className="mb-1">
        <Col span={22}>
          <Tooltip title={valuesItem?.field?.description}>
            <Text size="label2" boldness="semibold" className="list-item">
              {valuesItem?.field?.title}
            </Text>
          </Tooltip>
        </Col>
        <Col span={2}>
          <Row justify="end">
            <Col>
              <Switch
                checkedChildren="Да"
                unCheckedChildren="Нет"
                checked={valuesItem.value === "1"}
                onChange={handleValueSwitchChange}
                disabled={disabled}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
