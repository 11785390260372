import { taskTypes } from "core/app/store/task-slice";
import { IGroup } from "core/shared/dashboard";
import {
  IDashboardShiftStatisticTasksByTeamsPspaTask,
  IDashboardShiftStatisticTasksByTeams,
  IDashboardShiftStatisticTasksByTeamsOossaTask,
  IDashboardShiftStatisticTasksByTeamsSlpiTask,
} from "core/app/api/interfaces";
import { IDashboardShiftStatisticTasksByTeamsLsoTask } from "core/app/api/LSO/interfaces";

function getDashboardShiftStatisticTasksByTeam<
  T extends
    | IDashboardShiftStatisticTasksByTeamsLsoTask
    | IDashboardShiftStatisticTasksByTeamsPspaTask
    | IDashboardShiftStatisticTasksByTeamsSlpiTask
    | IDashboardShiftStatisticTasksByTeamsOossaTask
>(data: IDashboardShiftStatisticTasksByTeams<T>[] | undefined, teamId: string) {
  const teamData = data?.find((item) => `${item.team_id}` === `${teamId}`);

  const dataSource: IGroup<T>[] = [];
  if (teamData?.tasks) {
    Object.getOwnPropertyNames(teamData.tasks).forEach((item: string) => {
      dataSource.push({
        name: taskTypes.find((taskType) => taskType.id === item)?.titleSuperShort || "",
        id: item,
        children: teamData.tasks[item],
        type: "group",
      });
    });
  }

  return dataSource;
}

export { getDashboardShiftStatisticTasksByTeam };
