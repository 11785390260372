/** @jsxImportSource @emotion/react */
import { ReactElement } from "react";
import { Form, Select } from "antd";
import { capitalize } from "lodash";
import { CSSObject } from "@emotion/react";

import { useUnitShifts } from "core/shared/unit/useUnitShifts";

import { useRequiredCustomerApproval } from "../../hooks";

interface ShiftSelectProps {
  style?: CSSObject;
}

function ShiftSelect({ style }: ShiftSelectProps): ReactElement {
  const { data, isLoading } = useUnitShifts();
  const requiredCustomerApproval = useRequiredCustomerApproval();
  const form = Form.useFormInstance();

  const handleChange = () => {
    form.setFieldValue("teamId", null);
  };

  if (data) {
    return (
      <Form.Item label="СМЕНА" colon={false} name="shiftNumber" style={{ marginBottom: 0 }}>
        <Select
          css={style}
          loading={isLoading}
          size="large"
          disabled={requiredCustomerApproval}
          onChange={handleChange}
        >
          {data?.map((shift) => (
            <Select.Option key={shift.number} value={shift.number}>
              {capitalize(shift.name)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  }
  return <div />;
}

export default ShiftSelect;
