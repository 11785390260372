import { IUnit, UnitServiceCodesEnum } from "./types";

export function isSLPIKF(this: IUnit) {
  return this.service.code === UnitServiceCodesEnum.SLPI_KF;
}

export function isSLPI(this: IUnit) {
  return this.service.code === UnitServiceCodesEnum.SLPI_KF || this.service.code === UnitServiceCodesEnum.SLPI_ZF;
}

export function isPSPA(this: IUnit) {
  return this.service.code === UnitServiceCodesEnum.PSPA_KF || this.service.code === UnitServiceCodesEnum.PSPA_ZF;
}

export function isOOSSA(this: IUnit) {
  return this.service.code === UnitServiceCodesEnum.OOSSA;
}

export function isPSPAZF(this: IUnit) {
  return this.service.code === UnitServiceCodesEnum.PSPA_ZF;
}

export function isPSPAKF(this: IUnit) {
  return this.service.code === UnitServiceCodesEnum.PSPA_KF;
}

export function isLSO(this: IUnit) {
  return this.service.code === UnitServiceCodesEnum.LSO;
}

export function isLKU(this: IUnit) {
  return this.service.code === UnitServiceCodesEnum.LKU;
}

export function isVOLS(this: IUnit) {
  return this.service.code === UnitServiceCodesEnum.VOLS;
}

export function isPSS(this: IUnit) {
  return this.isLSO() || this.isLKU() || this.isVOLS();
}
