import { ReactElement } from "react";
import { Form, InputNumber } from "antd";
import { Rule } from "antd/lib/form";

import { useWorkTypes } from "core/shared/main/useWorkTypes";
import { TimeInput } from "core/shared/components/TimeInput";
import { EffortTypeEnum } from "core/models/enums";
import dayjs, { getDayJsFromDurationString } from "core/shared/tools/dayjs";

export const MOTO_WORD = "Хозяйки";

interface Props {
  required?: true;
  effortType: EffortTypeEnum;
}

const effortTypeToFormItemMap = {
  [EffortTypeEnum.HOURS]: "effort_duration",
  [EffortTypeEnum.MAN_HOURS]: "effort",
};

function EffortField({ required, effortType }: Props): ReactElement {
  const workTypeId = Form.useWatch("workTypeId");
  const { data: workTypes } = useWorkTypes();

  const workType = workTypes?.find((item) => item.id === workTypeId);
  const isMoto = Boolean(workType?.name.includes(MOTO_WORD));

  const rules: Rule[] = [
    {
      validator: (_, value: string | number) => {
        if (effortType === EffortTypeEnum.HOURS && typeof value === "string") {
          const dayJsValue = getDayJsFromDurationString(value);
          const totalSeconds = dayJsValue.diff(dayjs().startOf("day"), "second");
          if (!totalSeconds || Number.isNaN(totalSeconds)) {
            return Promise.reject(new Error());
          }
        }
        if (!value || (typeof value === "string" && value.includes("_"))) {
          return Promise.reject();
        }
        return Promise.resolve();
      },
    },
  ];

  if (required) {
    rules.push({
      required,
      message: "",
    });
  }

  return (
    <Form.Item
      label="ПЛАНОВЫЕ ТРУДОЗАТРАТЫ"
      name={effortTypeToFormItemMap[effortType]}
      colon={false}
      required={required}
      rules={rules}
    >
      {effortType === EffortTypeEnum.MAN_HOURS ? (
        <InputNumber size="large" addonAfter={isMoto ? "мтч." : "ч.ч."} min={0} />
      ) : (
        <TimeInput size="large" />
      )}
    </Form.Item>
  );
}

export default EffortField;
