const getIsNumberValid = (value: string | number) => {
  if (typeof value === "string") {
    const parsedValue = Number.parseInt(value, 2);
    return !Number.isNaN(parsedValue);
  }
  if (typeof value === "number") {
    return !Number.isNaN(value);
  }
  return false;
};

export default getIsNumberValid;
