import { Space, Checkbox } from "antd";

import { Text } from "core/lib";

import { usePlanningToolbarContext } from "./PlanningToolbarContext";

function PlanningToolbar() {
  const { shouldShowFact, shouldShowPlan, setShowFact, setShowPlan } = usePlanningToolbarContext();

  return (
    <Space className="planning-toolbar" size="small" direction="vertical">
      <Text size="label2" transform="uppercase" color="gray2" boldness="semibold">
        ПЛАНИРОВАНИЕ
      </Text>
      <Space>
        <Checkbox
          checked={shouldShowFact}
          onChange={(e) => {
            setShowFact(e.target.checked);
          }}
        >
          Факт
        </Checkbox>
        <Checkbox
          checked={shouldShowPlan}
          onChange={(e) => {
            setShowPlan(e.target.checked);
          }}
        >
          Остаток
        </Checkbox>
      </Space>
    </Space>
  );
}

export default PlanningToolbar;
