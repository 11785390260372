import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IFindOrCreate {
  transportId: number;
  date: string;
  monthPlanId: number;
}

const initialState: {
  findOrCreate: IFindOrCreate | null;
} = {
  findOrCreate: null,
};

export const FindOrCreateSlice = createSlice({
  name: "find-or-create",
  initialState,
  reducers: {
    setFindOrCreate(state, action: PayloadAction<IFindOrCreate | null>) {
      state.findOrCreate = action.payload;
    },
  },
});

export const { setFindOrCreate } = FindOrCreateSlice.actions;
