import { Col, Row } from "antd";

import { IEtoTask } from "core/app/api/interfaces";
import { FactoryNumber, Unit, Shift, HourMeterReadings } from "core/widgets/TaskModals/shared/Fields";
import dayjs, { displayDateFormat } from "core/shared/tools/dayjs";
import { Field } from "core/widgets/TaskModals/shared/Fields/Field";

import StateButtons from "./StateButtons";

type THeaderProps = {
  task: IEtoTask;
};

function Header({ task }: THeaderProps) {
  const plannedВate = `${dayjs(task.planned_date).format(displayDateFormat)} г.`;
  return (
    <Row justify="space-between" className="mb-4">
      <Col span={17}>
        <Row>
          <Col span={12}>
            {/* <RequestCreationDatetime task={task} label="Дата" format="DD.MM.YYYY" /> */}
            <Field label="Дата" value={plannedВate} />
            <Shift task={task} />
            <Unit task={task} />
          </Col>
          <Col span={12}>
            <FactoryNumber task={task} />
            <HourMeterReadings task={task} />
          </Col>
        </Row>
      </Col>
      <Col span={7}>
        <StateButtons task={task} />
      </Col>
    </Row>
  );
}

export default Header;
