import { useContext } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { useGetDashboardShiftStatisticTasksByTeamsOossaQuery } from "core/app/api";
import { ShiftContext } from "core/shared/dashboard/context/shiftContext";
import { getDashboardShiftStatisticTasksByTeam } from "core/shared/dashboard/api/getDashboardShiftStatisticTasksByTeam";

function useDashboardShiftStatisticTasksByTeamsOossa() {
  const { queryString, teamId } = useContext(ShiftContext);
  const { data } = useGetDashboardShiftStatisticTasksByTeamsOossaQuery(queryString ?? skipToken);
  return getDashboardShiftStatisticTasksByTeam(data, teamId);
}

export { useDashboardShiftStatisticTasksByTeamsOossa };
