import getIsNumberValid from "./getIsNumberValid";

import { getUuid } from "./index";

export const getUniqueArray = (size: number) => {
  if (getIsNumberValid(size)) {
    return Array(size)
      .fill("")
      .map(() => getUuid());
  }
  return [];
};
