import dayjs from "dayjs";

import { displayDateFormat, displayTimeFormat } from "core/shared/tools/dayjs";

import { ICellRendererProps } from "../../interfaces";

function SignatureDateCellRenderer({ record }: ICellRendererProps) {
  const date = record.signedAt;

  if (!date) {
    return <div>-</div>;
  }

  const formattedDate = dayjs(record.signedAt).format(displayDateFormat);
  const formattedTime = dayjs(record.signedAt).format(displayTimeFormat);

  return (
    <div>
      {formattedDate} в {formattedTime}
    </div>
  );
}

export default SignatureDateCellRenderer;
