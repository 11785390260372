import { createSlice } from "@reduxjs/toolkit";

import leadIcon from "core/images/lead.svg";
import electricianIcon from "core/images/electrician.svg";

export enum PositionEnum {
  Lead = "бригадир",
  Electrician = "электромонтажник",
}

export interface IEmployee {
  id: number;
  unit_id: number;
  personnel_number: string;
  last_name: string;
  first_name: string;
  middle_name: string;
  full_name: string;
  position: string;
  is_foreman: boolean;
  working_mode: string;
}

interface IEmployeeState {
  employees: IEmployee[];
}

export const initialState: IEmployeeState = {
  employees: [],
};

export const EmployeeSlice = createSlice({
  name: "employees",
  initialState,
  reducers: {
    addEmployee(state, action) {
      state.employees.push(action.payload);
    },
  },
});

export const getEmployeeIcon = (isForeman: boolean): string => {
  switch (isForeman) {
    case true:
      return leadIcon;
    default:
      return electricianIcon;
  }
};

export const { addEmployee } = EmployeeSlice.actions;
