import { useAppSelector, useAppDispatch } from "core/app/store";
import { setEquipmentId } from "core/app/store/main-slice";

const useEquipmentId = (): [number | null, (newEquipmentId: number | null) => void] => {
  const selectedEquipmentId = useAppSelector((state) => state.main.equipmentId);
  const dispatch = useAppDispatch();

  const set = (newCustomerId: number | null) => {
    dispatch(setEquipmentId(newCustomerId));
  };
  return [selectedEquipmentId, set];
};

export { useEquipmentId };
