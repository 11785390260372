import { useMemo, useState } from "react";

import { useLsoTask } from "core/shared/task/useTask";

import { ILsoMaintenanceContextValues } from "../LsoMaintenanceContext";

export function useLsoMaintenanceContextValues(): ILsoMaintenanceContextValues {
  const { data: task, isLoading, isError } = useLsoTask();
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  return useMemo(
    () => ({
      showErrorMessage,
      setShowErrorMessage,
      task,
      isTaskRequestError: isError,
      isTaskRequestLoading: isLoading,
    }),
    [showErrorMessage, task, isLoading, isError]
  );
}
