import { Alert } from "antd";

import "./styles.scss";

interface Props {
  message?: string;
  description?: string;
}

function ErrorMessage({
  message = "Невозможно создать заявку",
  description = "Выделенные поля обязательны для заполнения",
}: Props) {
  return <Alert className="error-message" message={message} description={description} type="error" showIcon />;
}

export default ErrorMessage;
