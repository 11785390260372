import { useLocation } from "react-router-dom";
import _ from "lodash";

import { AppRoutes } from "core/shared/constants.routes";
import { StateNamesEnum, TaskDiscriminatorEnum, TaskSnapMomentEnum, TaskTypesIdsEnum } from "core/app/api/interfaces";
import dayjs from "core/shared/tools/dayjs";
import { RoleEnum } from "core/shared/constants";
import { useHasUserOneOfSelectedRoles } from "core/shared/main/useHasUserOneOfSelectedRoles";

import { useTask } from "../useTask";
import { useDisabledByLocation } from "../useDisabledByLocation";
import { useDisabledByShiftStatus } from "../useDisabledByShift";
import { useTaskId } from "../useTaskId";

interface Props {
  skipStatusCheck?: boolean;
}

/**
 * Блокировка задачи
 * @returns
 */
export function useDisabled(props?: Props) {
  const { data: task } = useTask();
  const { pathname } = useLocation();
  const [, , moment] = useTaskId();
  const shiftDate = _.isNil(task?.planned_date) ? task?.planned_date : dayjs(task?.planned_date);
  const isDisabledByLocation = useDisabledByLocation();
  const isDisabledByShiftStatus = useDisabledByShiftStatus(shiftDate);
  const isDisabledByStatus = !task?.state || task.state.name !== StateNamesEnum.InWork;
  const isDisabledByRole = !useHasUserOneOfSelectedRoles([
    RoleEnum.SHIFT_LEAD,
    RoleEnum.UNIT_LEAD,
    RoleEnum.PSPA_WORKSHOP_LEAD,
    RoleEnum.SLPI_WORKSHOP_LEAD,
  ]);
  const isSGPS = task?.discriminator === TaskDiscriminatorEnum.PSPA_KF_TASK;
  const isPSPA = task?.discriminator === TaskDiscriminatorEnum.PSPA_TASK;
  const isPSPAorSGPS = isPSPA || isSGPS;
  const isAvrOrZno = task?.type === TaskTypesIdsEnum.RECOVERY || task?.type === TaskTypesIdsEnum.SERVICE_REQUEST;
  const isAvrZnoAndPspaSgps = (isPSPA || isSGPS) && isAvrOrZno;

  /* Специфичная проверка для PSPA/SGPS и AVR/ZNO задач на стр ФНЗ для ролей SHIFT_LEAD, UNIT_LEAD, PSPA_WORKSHOP_LEAD, SLPI_WORKSHOP_LEAD */
  if (isPSPAorSGPS && isAvrOrZno && !isDisabledByRole && pathname === AppRoutes.tasks) {
    if (!isDisabledByStatus || props?.skipStatusCheck) {
      return false;
    }
  }

  let isDisabled = isDisabledByShiftStatus;

  if (isAvrZnoAndPspaSgps && pathname === AppRoutes.tasks) {
    isDisabled = isDisabled || isDisabledByRole;
  }

  isDisabled = isDisabled || isDisabledByLocation;

  if (!props?.skipStatusCheck) {
    isDisabled = isDisabled || isDisabledByStatus || moment === TaskSnapMomentEnum.TEAM_DISBAND;
  }

  return isDisabled;
}
