import { useMemo } from "react";

import { Text } from "core/lib/Text/Text";
import { groupWithOrder } from "core/shared/tools/groupWithOrder";

import { ICheckListItemWithIndex } from "..";

import { Place } from "./Place";

interface Props {
  checklists: ICheckListItemWithIndex[];
}

export function Power({ checklists }: Props) {
  const checklistsByPlace = useMemo(() => groupWithOrder(checklists, "check.place.name"), [checklists]);

  return (
    <div className="ml-4">
      {Object.entries(checklistsByPlace).map(([place, placeChecklists]) => (
        <div key={place}>
          <div className="mb-1">
            <Text boldness="semibold" size="label2" transform="uppercase" color="gray2">
              {place.split("_")[0]}
            </Text>
          </div>
          <Place checklists={placeChecklists} />
        </div>
      ))}
    </div>
  );
}
