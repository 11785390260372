import { Form, Select } from "antd";

import { IEmployee } from "core/app/api/interfaces";
import { useDate } from "core/shared/main/store/useDate";
import { useCurrentShift } from "core/shared/shift/useCurrentShift";
import dayjs, { apiDateFormat } from "core/shared/tools/dayjs";
import { useEmployees } from "core/shared/useEmployees";

type TElectricianSelectProps = {
  value: number | null;
  onChange: (value: number | null) => void;
};

function ElectricianSelect({ value, onChange }: TElectricianSelectProps) {
  const shift = useCurrentShift();
  const [date] = useDate();
  const { data: employees, isLoading } = useEmployees(
    {
      working_date: dayjs(date).format(apiDateFormat),
      working_shift: shift?.number,
    },
    !shift || !date
  );

  const options = (employees?.results || []).map((employee: IEmployee) => ({
    label: employee.full_name,
    value: employee.id,
  }));

  return (
    <Form.Item
      name="electrician"
      label="ФАМИЛИЯ ИМЯ ОТЧЕТСТВО"
      rules={[{ required: true, message: "Пожалуйста, укажите выпускающего электромеханика!" }]}
    >
      <Select
        value={value}
        style={{ width: "100%" }}
        showSearch
        allowClear
        loading={isLoading}
        optionFilterProp="children"
        dropdownStyle={{ zIndex: 2000 }}
        filterOption={(input, option) => (option?.label?.toString() ?? "").toLowerCase().includes(input.toLowerCase())}
        options={options}
        onChange={onChange}
      />
    </Form.Item>
  );
}

export default ElectricianSelect;
