import { Row, Col, Button, Spin, Space, Form, InputNumber } from "antd";
import { ReactElement, useEffect } from "react";

import { ITask, TaskSnapMomentEnum } from "core/app/api/interfaces";
import { useAppDispatch } from "core/app/store";
import { useUpdateTask } from "core/shared/task/useUpdateTask";
import { setTask } from "core/app/store/task-slice";
import { setCurrentTaskUpdated } from "core/app/store/maintenance-attached-slice";
import { useLocalTask, useTask } from "core/shared/task/useTask";
import { useTaskId } from "core/shared/task/useTaskId";

import "core/widgets/TaskModals/Stationary/styles.scss";

import OossaMaintenanceTaskGeneralInfo from "./OossaMaintenanceTaskGeneralInfo";
import OossaMaintenanceTaskExecution from "./OossaMte";
import OossaMaintenanceTaskFooter from "./OossaMaintenanceTaskFooter";

/** Карточка технического обслуживания (ТО) для ООССА. */
export default function OossaMaintenanceTaskContent(): ReactElement {
  const dispatch = useAppDispatch();
  const { data: remoteTask, refetch } = useTask();
  const [, , moment] = useTaskId();
  const checkMoment = moment === TaskSnapMomentEnum.TEAM_DISBAND;
  const [localTask] = useLocalTask();
  const [updateTaskRequest, updateTaskResult] = useUpdateTask(localTask?.discriminator);

  const handleSave = (values: Partial<ITask>) => {
    updateTaskRequest({ maintenance_execution: localTask?.maintenance_execution, ...values });
  };

  useEffect(() => {
    if (updateTaskResult.isSuccess) {
      refetch();
      dispatch(setCurrentTaskUpdated(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updateTaskResult.isSuccess]);

  useEffect(() => {
    if (remoteTask) {
      dispatch(setTask({ ...remoteTask }));
    }
  }, [dispatch, remoteTask]);

  if (localTask && remoteTask) {
    return (
      <Form initialValues={remoteTask} scrollToFirstError onFinish={handleSave} layout="vertical">
        <Form.Item name="id" hidden>
          <InputNumber />
        </Form.Item>
        <Space
          direction="vertical"
          size="small"
          style={{ padding: "0", /* backgroundColor: "#354A5FE5", */ maxWidth: "100%" }}
        >
          {/* Верхняя часть диалога - название, кнопки, атрибуты задания */}
          <OossaMaintenanceTaskGeneralInfo task={localTask} />
          <hr />
          {!!localTask.maintenance_execution.length && (
            // Средняя часть диалога - список работ
            <>
              <OossaMaintenanceTaskExecution task={localTask} />
              <hr />
            </>
          )}

          {/* Нижняя часть диалога - кто закрыл, какие файлы прикрепил */}
          <OossaMaintenanceTaskFooter task={localTask} />
          {/* Кнопка "Сохранить" */}
          <Row>
            <Col span={24}>
              <Button
                type="primary"
                size="large"
                className="w-100"
                htmlType="submit"
                loading={updateTaskResult.isLoading}
                disabled={checkMoment}
              >
                Сохранить
              </Button>
            </Col>
          </Row>
        </Space>
      </Form>
    );
  }
  return (
    <Row align="middle" justify="center" style={{ minHeight: "300px" }}>
      <Spin size="large" />
    </Row>
  );
}
