import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { v4 } from "uuid";

import dayjs, { apiDateTimeFormat } from "core/shared/tools/dayjs";
import { SignatureSideEnum } from "core/app/api/interfaces";

import { TRootState } from "..";

export interface ITaskSignature {
  id: string;
  taskId: number;
  url: string;
  side: SignatureSideEnum;
  signedAt: string;
  signatoryId: string;
  shiftDate: string;
  shiftName: string;
  transportName: string;
  hasBeenSent: boolean;
  requestAttemptsCount: number;
  errorMessage?: string;
}

interface ITaskSignatureState {
  signatures: ITaskSignature[];
}

const initialState: ITaskSignatureState = {
  signatures: [],
};

export const TaskSignatureSlice = createSlice({
  name: "task-signature",
  initialState,
  reducers: {
    addSignature: (
      state,
      action: PayloadAction<Omit<ITaskSignature, "id" | "hasBeenSent" | "signedAt" | "requestAttemptsCount">>
    ) => {
      const { taskId, side } = action.payload;
      state.signatures = state.signatures.filter(
        (signature) => !(signature.taskId === taskId && signature.side === side)
      );
      const signedAt = dayjs().format(apiDateTimeFormat);
      state.signatures.push({
        ...action.payload,
        id: v4(),
        signedAt,
        hasBeenSent: false,
        requestAttemptsCount: 0,
      });
    },
    deleteSignature: (state, action: PayloadAction<Pick<ITaskSignature, "id">>) => {
      const { id } = action.payload;
      state.signatures = state.signatures.filter((signature) => !(signature.id === id));
    },
    updateSignature: (
      state,
      action: PayloadAction<Pick<ITaskSignature, "id"> & Partial<Omit<ITaskSignature, "id">>>
    ) => {
      const { id } = action.payload;
      const selectedSignatureIndex = state.signatures.findIndex((signature) => signature.id === id);

      if (selectedSignatureIndex >= 0) {
        state.signatures[selectedSignatureIndex] = {
          ...state.signatures[selectedSignatureIndex],
          ...action.payload,
        };
      }
    },
  },
});

export const { addSignature, deleteSignature, updateSignature } = TaskSignatureSlice.actions;

export const signaturesToSendSelector = createSelector(
  (state: TRootState) => state.taskSignature,
  (state) => state.signatures
);

export const sentSignaturesSelector = createSelector(
  (state: TRootState) => state.taskSignature,
  (state) => state.signatures.filter((signature) => signature.hasBeenSent)
);

export const signaturesSelector = createSelector(
  (state: TRootState) => state.taskSignature,
  (state) => state.signatures
);
