import { ReactElement, useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import qs from "qs";
import { Form } from "antd";

import { useUnit } from "core/shared/unit/useUnit";
import { useGetDashboardLsoMaintenancePlanFactStatisticQuery } from "core/app/api/LSO";

import { getBarsBackgroundColors, getBarsLabels } from "../../Bars/utils";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export function BarsLso(): ReactElement {
  const unit = useUnit();
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const yearDate = Form.useWatch("year");
  const monthDate = Form.useWatch("month");
  const customerId = Form.useWatch("customer_id");
  const customerDepartmentId = Form.useWatch("customer_department_id");

  const queryParams = {
    unit_id: unit?.id,
    ...(yearDate && { year: yearDate.year() }),
    ...(monthDate && { month: monthDate.month() + 1 }),
    ...(customerId && { customer_id: customerId }),
    ...(customerDepartmentId && { customer_department_id: customerDepartmentId }),
  };

  const willShowCurrentDateFields =
    yearDate?.year() === new Date().getFullYear() && monthDate?.month() === new Date().getMonth();

  const { data } = useGetDashboardLsoMaintenancePlanFactStatisticQuery(qs.stringify(queryParams));

  const options: ChartOptions<"bar"> = {
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  let labels = [];
  let backgroundColors = [];

  labels = getBarsLabels(willShowCurrentDateFields);
  backgroundColors = getBarsBackgroundColors(willShowCurrentDateFields);

  const barValues = {
    month: data ? data.plans_for_the_month : 0,
    ...(willShowCurrentDateFields && { day: data ? data.plans_for_the_day : 0 }),
    fact: data ? data.facts_for_the_day : 0,
    ...(willShowCurrentDateFields && { diff: data ? data.facts_for_the_day - data.plans_for_the_day : 0 }),
    left: data ? data.plans_for_the_month - data.facts_for_the_day : 0,
  };

  const barData = {
    labels,
    datasets: [
      {
        label: "Стационарное ТО",
        data: Object.values(barValues),
        backgroundColor: backgroundColors,
        labels,
      },
    ],
  };

  useEffect(() => {
    if (chartContainerRef.current) {
      const chart = ChartJS.getChart("stationary-lso");

      if (chart) {
        chart.options.aspectRatio = chartContainerRef.current.clientWidth / chartContainerRef.current.clientHeight;
      }
    }
  }, []);

  return (
    <div
      ref={chartContainerRef}
      style={{
        height: "250px",
        width: "100%",
      }}
    >
      <Bar id="stationary-lso" options={options} data={barData} />
    </div>
  );
}
