import { Space } from "antd";

import { IAssistanceCarCheckList, EtoTaskStepNameEnum } from "core/app/api/interfaces";

import CheckListItem from "../../CheckListItem";

type Props = {
  checkList: IAssistanceCarCheckList;
  formPath: Array<string | number>;
  stepName: EtoTaskStepNameEnum;
};

export function CheckList({ checkList, formPath, stepName }: Props) {
  const { checks } = checkList;

  return (
    <Space direction="vertical" size={16}>
      {checks?.map((check, index) => (
        <CheckListItem stepName={stepName} formPath={[...formPath, "checks", index]} check={check} />
      ))}
    </Space>
  );
}
