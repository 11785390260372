import { Col } from "antd";
import { ReactElement } from "react";

import { IMaintenanceExecutionCheckListItem } from "core/app/api/interfaces";
import StationaryMaintenanceExecutionCheckListItem from "core/widgets/TaskModals/Stationary/Content/MaintenanceExecutions/StationaryMaintenanceExecutionCheckListItem";

interface OossaMaintenanceExecutionCheckProps {
  maintenanceExecutionId: number;
  checkListItem: IMaintenanceExecutionCheckListItem;
  show: boolean;
}

/** Элемент чек-листа задания на ТО для ООССА: наименование работы, текущий статус, всплывающий выбор статуса выполнения работы, комментарий. */
export default function OossaMaintenanceExecutionCheck(props: OossaMaintenanceExecutionCheckProps): ReactElement {
  const { maintenanceExecutionId, checkListItem, show } = props;

  if (show) {
    return (
      <Col>
        <StationaryMaintenanceExecutionCheckListItem
          maintenanceExecutionId={maintenanceExecutionId}
          checkListItem={checkListItem}
          show={show}
        />
      </Col>
    );
  }
  return <div />;
}
