import qs from "qs";

import { useGetEmployeesQuery } from "core/app/api";

import { useUnitId } from "../unit/useUnitId";

type TEmployeeFilters = {
  team_id?: number | null;
  availability_date?: string;
  availability_shift?: number;
  copy_teams_from_date?: string;
  copy_teams_from_shift_number?: number;
  is_driver?: boolean;
  working_date?: string;
  working_shift?: number;
};

interface IEmployeesQueryParam extends Partial<TEmployeeFilters> {
  unit_id: number | null;
}

export const useEmployees = (filters?: TEmployeeFilters, skip?: boolean) => {
  const [unit] = useUnitId();

  const paramsFromFilters: TEmployeeFilters = filters
    ? Object.fromEntries(Object.entries(filters).filter(([_, value]) => value !== undefined))
    : {};

  const queryParams: IEmployeesQueryParam = {
    ...paramsFromFilters,
    unit_id: unit,
  };

  const employeesQS = qs.stringify(queryParams);
  return useGetEmployeesQuery(employeesQS, { skip });
};
