import { Modal, Row, Col } from "antd";
import { ReactElement, useState, useEffect } from "react";

import Text from "core/lib/Text/Text";
import { useAppDispatch, useAppSelector } from "core/app/store";
import icWarning from "core/images/ic-warning.svg";
import { setTaskToReset } from "core/app/store/maintenance-attached-slice";
import { useResetTaskQuery, util } from "core/app/api";

function Title(): ReactElement {
  return (
    <Row gutter={8}>
      <Col>
        <img src={icWarning} alt="warning" />
      </Col>
      <Col style={{ alignSelf: "center" }}>
        <Text boldness="black" size="body1">
          Предупреждение
        </Text>
      </Col>
    </Row>
  );
}

/** Предупреждающее сообщение про удаление карточки технического обслуживания (ТО)
 * для навесного оборудования ПСПА и для СЛПИ. */
function WarningModal(): ReactElement {
  const { taskToReset } = useAppSelector((state) => state.maintenanceAttached);
  const dispatch = useAppDispatch();
  const [skip, setSkip] = useState(true);
  const [fullfilled, setFullfilled] = useState(false);
  const { isLoading, isUninitialized } = useResetTaskQuery(taskToReset, { skip });

  const handleOk = () => {
    setSkip(false);
  };
  const handleCancel = () => {
    dispatch(setTaskToReset(null));
  };

  useEffect(() => {
    setFullfilled(false);
  }, [taskToReset]);

  useEffect(() => {
    if (!isUninitialized) {
      setSkip(true);
      setFullfilled(true);
    }
  }, [isUninitialized]);

  useEffect(() => {
    if (fullfilled) {
      dispatch(util.invalidateTags(["month-plan", "task"]));
      dispatch(setTaskToReset(null));
    }
  }, [dispatch, fullfilled]);

  return (
    <div>
      <Modal
        title={<Title />}
        open={!!taskToReset}
        onOk={handleOk}
        okButtonProps={{
          loading: isLoading,
        }}
        cancelButtonProps={{
          disabled: isLoading,
        }}
        onCancel={handleCancel}
        cancelText="Нет"
        okText="Да"
      >
        <Row justify="center">
          <Text>Удалить черновик карточки ТО?</Text>
        </Row>
      </Modal>
    </div>
  );
}

export default WarningModal;
