import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";

import { Field } from "../Field";

interface TVolsObjectProps {
  task: ITask;
}

function VolsObject({ task }: TVolsObjectProps): ReactElement {
  return <Field label="Объект" value={task.vols_object?.name} />;
}

export { VolsObject };
