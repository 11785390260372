import { useState } from "react";
import { RadioChangeEvent, Form, InputNumber } from "antd";

import { RadioGroup } from "core/shared/components";

import { InsideOutside } from "./InsideOutside";

export enum LocationEnum {
  inside = "inside",
  outside = "outside",
}

export function Body() {
  const [location, setLocation] = useState<LocationEnum>(LocationEnum.outside);
  const handleChange = (value: RadioChangeEvent) => {
    setLocation(value.target.value);
  };

  return (
    <>
      <RadioGroup
        value={location}
        items={[
          {
            value: LocationEnum.outside,
            label: "снаружи кабины",
          },
          {
            value: LocationEnum.inside,
            label: "внутри кабины",
          },
        ]}
        onChange={handleChange}
      />
      <Form.Item name="checklist" hidden>
        <InputNumber />
      </Form.Item>
      <InsideOutside location={location} visible={location === LocationEnum.inside} />
      <InsideOutside location={location} visible={location === LocationEnum.outside} />
    </>
  );
}
