import {
  chartColorGreen,
  chartColorYellow,
  chartColorMagenta,
  chartColorRed,
  chartColorGrayBlue,
} from "uikit/themes/default/colors";

/** Вернуть текстовые метки для столбиков диаграммы статистики. */
export function getBarsLabels(isCurrentDate: boolean): string[] {
  return isCurrentDate ? ["Мес. план", "Тек. дата план", "Факт", "Отклонение", "Остаток"] : ["План", "Факт", "Остаток"];
}

/** Вернуть цвета для отображения столбиков диаграммы статистики. */
export function getBarsBackgroundColors(isCurrentDate: boolean): string[] /* то же, что CSSProperties["color"][] */ {
  return isCurrentDate
    ? [chartColorGreen, chartColorYellow, chartColorMagenta, chartColorRed, chartColorGrayBlue]
    : [chartColorGreen, chartColorMagenta, chartColorGrayBlue];
}
