import { ReactElement } from "react";

import { taskTypes } from "core/app/store/task-slice";
import { isGroup } from "core/shared/dashboard";

import { ICellProps } from "..";

function Type({ record }: ICellProps): ReactElement {
  if (isGroup(record)) {
    return <div />;
  }
  return <div>{taskTypes.find((item) => item.id === record.type)?.titleSuperShort}</div>;
}

export default Type;
