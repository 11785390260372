import { ReactElement } from "react";

import { useFullScreen } from "core/shared/main/store/useFullScreen";

interface FixedLayoutProps {
  children: ReactElement;
}

function FixedLayout({ children }: FixedLayoutProps) {
  const [fullScreen] = useFullScreen();
  return (
    <div style={{ minWidth: "1400px", width: "100%" }} className={!fullScreen ? "pl-3 pr-3 h-100 pt-8" : ""}>
      {children}
    </div>
  );
}

export { FixedLayout };
