import { StateNamesEnum, TaskSnapMomentEnum } from "core/app/api/interfaces";
import { useDisabledByLocation } from "core/shared/task/useDisabledByLocation";
import { useDisabledByShiftStatus } from "core/shared/task/useDisabledByShift";
import { useEtoTask } from "core/shared/task/useTask";
import { useTaskId } from "core/shared/task/useTaskId";

/**
 * Проверяет, должны ли быть заблокированы поля задачи на основе статуса смены,
 * страницы, с которой открыли форму и статуса задачи.
 * @returns
 */
export function useFieldsDisabled() {
  const [, , moment] = useTaskId();
  const { data: task } = useEtoTask();
  const disabledByShiftStatus = useDisabledByShiftStatus();
  const disabledByLocation = useDisabledByLocation();
  const disabledByTaskStatus = task?.state?.name === StateNamesEnum.New || task?.state?.name === StateNamesEnum.Closed;
  return (
    disabledByShiftStatus || disabledByLocation || disabledByTaskStatus || moment === TaskSnapMomentEnum.TEAM_DISBAND
  );
}

export function useStateButtonsDisabled() {
  const disabledByShiftStatus = useDisabledByShiftStatus();
  const disabledByLocation = useDisabledByLocation();
  return disabledByShiftStatus || disabledByLocation;
}
