import { Drawer } from "antd";

import { ILocalAttachment } from "core/app/api/interfaces";

import AttachmentsGallery from "./AttachmentGallery";

type TAttachmentViewerProps = {
  attachments: ILocalAttachment[];
  selectedAttachmentId: string;
  open: boolean;
  onClose: () => void;
};

function AttachmentViewer({ attachments, selectedAttachmentId, open, onClose }: TAttachmentViewerProps) {
  return (
    <Drawer open={open} onClose={onClose} width="100VW" zIndex={2000}>
      <AttachmentsGallery attachments={attachments} selectedAttachmentId={selectedAttachmentId} />
    </Drawer>
  );
}

export default AttachmentViewer;
