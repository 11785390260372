import { ReactElement } from "react";
import { Row, Col } from "antd";

import { isGroup, IGroup } from "core/shared/dashboard";
import { IDashboardShiftStatisticTasksByTeamsLsoTask } from "core/app/api/LSO/interfaces";
import { useTaskId } from "core/shared/task/useTaskId";

type TTableRecord = IDashboardShiftStatisticTasksByTeamsLsoTask | IGroup<IDashboardShiftStatisticTasksByTeamsLsoTask>;

interface CellProps {
  record: TTableRecord;
}

const styles = {
  cursor: "pointer",
  color: "#1890ff",
};

function Object({ record }: CellProps): ReactElement {
  const [, setTaskId] = useTaskId();

  if (isGroup(record)) {
    return <div>{record.name}</div>;
  }
  const handleShowTask = async () => {
    setTaskId(record.id);
  };
  return (
    <Row onClick={handleShowTask}>
      <Col style={styles}>{record.lso_object || "НЕ ВЫБРАНО"}</Col>
    </Row>
  );
}

export { Object };
