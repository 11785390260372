import { Form } from "antd";

import Text from "core/lib/Text/Text";
import dayjs, { displayDateFormat } from "core/shared/tools/dayjs";

interface Props {
  value: string | null;
}
export function ClosingDate({ value }: Props) {
  return (
    <Form.Item label="ДАТА ЗАКРЫТИЯ">
      {value && (
        <Text size="label1" boldness="semibold">
          {dayjs(value).format(displayDateFormat)}
        </Text>
      )}
    </Form.Item>
  );
}
