import { Form } from "antd";

import Text from "core/lib/Text/Text";
import { CheckItemGroupsEnum, IMaintenanceExecution } from "core/app/api/interfaces";
import { CheckListItem } from "core/widgets/TaskModals/shared/CheckListItem";

interface Props {
  title: string;
  group: CheckItemGroupsEnum;
  maintenanceExecution: IMaintenanceExecution;
  maintenanceExecutionIndex: number;
}

export function CheckListGroup({ title, group, maintenanceExecution, maintenanceExecutionIndex }: Props) {
  const checklistIndexes: number[] = [];
  maintenanceExecution.checklist.forEach((element, i) => {
    if (element.check.group_number === group) {
      checklistIndexes.push(i);
    }
  });

  return (
    <div>
      <div className="mb-1">
        <Text size="label1" boldness="semibold">
          {title}
        </Text>
      </div>
      {checklistIndexes.map((checklistIndex) => {
        const checkListItem = maintenanceExecution.checklist[checklistIndex];
        return (
          <Form.Item
            key={checkListItem.id}
            name={["maintenance_execution", maintenanceExecutionIndex, "checklist", checklistIndex]}
          >
            <CheckListItem show />
          </Form.Item>
        );
      })}
    </div>
  );
}
