import { useLocation } from "react-router-dom";

import { AppRoutes } from "core/shared/constants.routes";

const DISABLED_LOCATIONS = [
  AppRoutes.tasks,
  AppRoutes.slpi,
  AppRoutes.oossa,
  AppRoutes.equipments,
  AppRoutes.base,
  AppRoutes.pssLku,
  AppRoutes.pssLso,
  AppRoutes.sgps,
  AppRoutes.maintenance,
  AppRoutes.pssVols,
] as const;

type TDisabledLocations = typeof DISABLED_LOCATIONS[number];

export function useDisabledByLocation() {
  const { pathname } = useLocation();

  return DISABLED_LOCATIONS.includes(pathname as TDisabledLocations);
}
