import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";
import { hoursToString } from "core/shared/tools/hoursToString";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
  formatFn?: (value: number) => string;
}

function WorkTimeUsageHours({ task, formatFn }: FieldProps): ReactElement {
  let workTimeUsageHoursFormatted = "";
  const value = task.work_time_usage_hours || 0;
  if (formatFn) {
    workTimeUsageHoursFormatted = formatFn(value);
  } else {
    workTimeUsageHoursFormatted = hoursToString(value);
  }

  return <Field label="Остаток" value={workTimeUsageHoursFormatted} />;
}

export { WorkTimeUsageHours };
