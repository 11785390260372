import { Badge } from "antd";
import { ReactElement } from "react";

import Text from "core/lib/Text/Text";
import { IMaintenanceExecution } from "core/app/api/interfaces";

interface TabProps {
  maintenanceExecution: IMaintenanceExecution;
}

/** Закладка на карточке технического обслуживания (ТО).
 * Соответствует типу оборудования (системе) в навесном оборудовании ПСПА и в СЛПИ,
 * также используется (?) в стационарном оборудовании ПСПА и для СДГР. */
function Tab(props: TabProps): ReactElement {
  const { maintenanceExecution } = props;
  let breakingCount = 0;
  maintenanceExecution.checklist.forEach((checkItem) => {
    if (checkItem.value === 2) {
      breakingCount += 1;
    }
  });

  return (
    <Badge count={breakingCount}>
      <Text style={{ paddingLeft: 8, paddingRight: 12 }}>{maintenanceExecution.equipment_type.name}</Text>
    </Badge>
  );
}

export default Tab;
