import { ReactElement } from "react";
import { Card, Timeline, Row, Col, Space, Button } from "antd";
import TextArea from "antd/lib/input/TextArea";

import Text from "core/lib/Text/Text";
import { useGetEquipmentCommentJournalQuery } from "core/app/api";
import dayjs, { displayDateFormat } from "core/shared/tools/dayjs";

interface CommentsTimeLineProps {
  equipmentId: string | number;
}

function CommentsTimeLine({ equipmentId }: CommentsTimeLineProps): ReactElement {
  const { data } = useGetEquipmentCommentJournalQuery(`${equipmentId}`);

  return (
    <>
      <div className="mt-2 mb-2">
        <Text boldness="semibold" className="uppercase">
          Журнал комментариев
        </Text>
      </div>
      <Space className="mb-1">
        <Button size="small">Ед. оборуд.</Button>
        <Button size="small">Задача</Button>
      </Space>
      <Card bordered={false}>
        <Row gutter={16}>
          <Col span={12}>
            <Timeline mode="left">
              {data?.map((item) => (
                <Timeline.Item key={item.task_id} label={dayjs(item.closing_date).format(displayDateFormat)}>
                  {item.comment}
                </Timeline.Item>
              ))}
            </Timeline>
          </Col>
          <Col span={12}>
            <Text boldness="semibold" size="label4" className="uppercase">
              Комментарий к единице оборудования
            </Text>
            <TextArea rows={5} value="Комментарий к единице оборудования" />
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default CommentsTimeLine;
