import React from "react";
import { CloseOutlined } from "@ant-design/icons";

type PreviewImageProps = {
  images: { src: string; id: string }[];
  deleteImage: (id: string) => void;
};

function PreviewImages({ images, deleteImage }: PreviewImageProps) {
  return (
    <div className="preview-images">
      {images.map(({ id, src }) => (
        <div key={id} className="preview-images-item">
          <img src={src} alt="screenshot" />
          <CloseOutlined
            style={{ position: "absolute", top: 0, left: 0, margin: 10, color: "white", fontSize: 30 }}
            onClick={() => deleteImage(id)}
          />
        </div>
      ))}
    </div>
  );
}

export default PreviewImages;
