import { Tabs, TabsProps } from "antd";
import { useState } from "react";

import { RadioGroup, TRadioGroupOptionProps, TRadioGroupProps } from "../RadioGroup";

type TRadioGroupTabsProps = Omit<TabsProps, "renderTabBar"> & {
  radioGroupProps?: Omit<TRadioGroupProps, "value" | "items" | "onChange">;
};

function RadioGroupTabs({ items, activeKey, radioGroupProps, onChange, ...otherProps }: TRadioGroupTabsProps) {
  const [selectedTab, setSelectedTab] = useState(activeKey);
  const radioGroupTabItems: TRadioGroupOptionProps[] = (items || []).map((item) => ({
    label: item.label as string,
    value: item.key,
    disabled: item.disabled,
    forceRender: true,
  }));
  return (
    <Tabs
      activeKey={selectedTab}
      items={items}
      renderTabBar={() => (
        <RadioGroup
          value={selectedTab}
          items={radioGroupTabItems}
          onChange={(e) => {
            setSelectedTab(e.target.value);
            if (onChange) {
              onChange(e.target.value);
            }
          }}
          {...radioGroupProps}
        />
      )}
      {...otherProps}
    />
  );
}

export default RadioGroupTabs;
