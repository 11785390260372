import { Badge, Form } from "antd";
import { ReactElement } from "react";

import Text from "core/lib/Text/Text";
import { IMaintenanceExecution } from "core/app/api/interfaces";

interface TabProps {
  maintenanceExecution: IMaintenanceExecution;
  index: number;
}

/** Закладка на карточке технического обслуживания (ТО).
 * Соответствует типу оборудования (системе) в навесном оборудовании ПСПА,
 * также используется (?) в стационарном оборудовании ПСПА и для СДГР. */
function Tab({ maintenanceExecution, index }: TabProps): ReactElement {
  const maintenanceExecutionW = Form.useWatch<IMaintenanceExecution | undefined>(["maintenance_execution", index]);

  let breakingCount = 0;
  maintenanceExecutionW?.checklist.forEach((checkItem) => {
    if (checkItem.value === 2) {
      breakingCount += 1;
    }
  });

  return (
    <Badge count={breakingCount}>
      <Text style={{ paddingLeft: 8, paddingRight: 12 }}>{maintenanceExecution.equipment_type.name}</Text>
    </Badge>
  );
}

export default Tab;
