import React from "react";
import { Form, InputNumber, Row, Col, InputNumberProps } from "antd";

import { TimeInput } from "core/shared/components/TimeInput";
import Text from "core/lib/Text/Text";
import { ElapsedTimeTotal } from "core/widgets/TaskModals/shared/ElapsedTimeTotal";
import { ElapsedTimeMultiplier } from "core/widgets/TaskModals/shared/ElapsedTimeMultiplier";
import { getHoursFromTime, getTimeFromHours } from "core/shared/tools/dayjs";

/**
 * Обёртка для инпута с временем. Конвертирует из формата hh:mm:ss в часы с десятичной частью и обратно
 */
function InputNumberWrapper(props: InputNumberProps<string>) {
  const { value, onChange, ...rest } = props;
  const hours = getHoursFromTime(value, true);
  const handleChange = (newValue: string | null) => {
    const durationValue = getTimeFromHours(newValue);
    onChange?.(durationValue);
  };
  return <InputNumber value={`${hours}`} onChange={handleChange} {...rest} />;
}

interface Props {
  required?: boolean;
  name?: string;
  children: React.ReactNode;
}

function ElapsedTime({ children, required, name = "elapsed_time" }: Props) {
  return (
    <Form.Item
      label="ВРЕМЯ ВЫПОЛНЕНИЯ"
      name={name}
      required={required}
      rules={[
        {
          message: "",
          validator: (_, value) => {
            if (required && !value) {
              return Promise.reject();
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      {children}
    </Form.Item>
  );
}

interface ContainerProps {
  disabled?: boolean;
  required?: boolean;
  name?: string;
  useMultiplier?: boolean;
}
/**
 * Компонент для ввода времени выполнения задачи.
 * В зависимости от useMultiplier выбирает способ ввода времени.
 * Если useMultiplier = true, то ввод времени выполнения задач в виде калькулятора,
 * с указанием кол-ва человек и итоговой суммой, используется в большинстве задач.
 * Если useMultiplier = false то ввод времени в виде числового инпута в часах, используется в СЛПИ задачах.
 */
function ElapsedTimeWrapper({ useMultiplier = true, disabled, required, name = "elapsed_time" }: ContainerProps) {
  if (!useMultiplier) {
    return (
      <Col span={12}>
        <ElapsedTime required={required}>
          <InputNumberWrapper precision={1} min={`${0}`} max={`${719.9}`} disabled={disabled} />
        </ElapsedTime>
      </Col>
    );
  }
  return (
    <Row gutter={8} align="bottom">
      <Col span={9}>
        <ElapsedTime required={required}>
          <TimeInput disabled={disabled} />
        </ElapsedTime>
      </Col>
      <Col span={1}>
        <Form.Item>
          <Text size="label1" boldness="semibold">
            x
          </Text>
        </Form.Item>
      </Col>
      <Col span={6}>
        <ElapsedTimeMultiplier disabled={disabled} />
      </Col>
      <Col span={8}>
        <ElapsedTimeTotal />
      </Col>
    </Row>
  );
}

export { ElapsedTimeWrapper as ElapsedTime };
