import React, { ReactElement } from "react";
import TextArea from "antd/lib/input/TextArea";
import { Col, Row } from "antd";

import {
  IMaintenanceExecutionCheckListItem,
  IMaintenanceExecutionValuesItem,
  IMaintenanceExecutionWork,
} from "core/app/api/interfaces";
import { useDisabled } from "core/shared/task/useDisabled";
import { useAppDispatch } from "core/app/store";
import { setMaintenanceExecutionWorkComment } from "core/app/store/task-slice";

import OossaMaintenanceExecutionCheck from "./OossaMteCheck";
import OossaMaintenanceExecutionValue from "./OossaMteValue";
import OossaMaintenanceTaskExecutionGroupWork from "./OossaMteGroupWork";

interface OossaMaintenanceTaskExecutionWorkProps {
  maintenanceExecutionId: number;
  workItem: IMaintenanceExecutionWork;
  showGroupName: boolean;
}

export default function OossaMaintenanceTaskExecutionWork({
  maintenanceExecutionId,
  workItem,
  showGroupName,
}: OossaMaintenanceTaskExecutionWorkProps): ReactElement {
  const checkItem = workItem as IMaintenanceExecutionCheckListItem;
  const valueItem = workItem as IMaintenanceExecutionValuesItem;
  const disabled = useDisabled();
  const dispatch = useAppDispatch();

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    dispatch(setMaintenanceExecutionWorkComment({ workItemId: workItem.id, newValue }));
  };

  return (
    <>
      {/* Элемент чек-листа */}
      {showGroupName && <OossaMaintenanceTaskExecutionGroupWork workItem={workItem} />}
      {checkItem.check && (
        <OossaMaintenanceExecutionCheck
          key={workItem.id}
          maintenanceExecutionId={maintenanceExecutionId}
          checkListItem={checkItem}
          show
        />
      )}
      {/* Элемент измеренного значения */}
      {valueItem.field && (
        <OossaMaintenanceExecutionValue
          key={workItem.id}
          maintenanceExecutionId={maintenanceExecutionId}
          valuesItem={valueItem}
        />
      )}
      {/* Комментарий к работе */}
      <Row>
        <Col span={22}>
          <div className="mb-2 pl-1">
            <TextArea rows={2} onChange={handleCommentChange} value={workItem.comment ?? ""} disabled={disabled} />
          </div>
        </Col>
      </Row>
    </>
  );
}
