import { Form, RadioChangeEvent } from "antd";

import "./styles.scss";
import { RadioGroup } from "core/shared/components";

function TypeSwitchField() {
  const form = Form.useFormInstance();

  const handleChange = (e: RadioChangeEvent) => {
    form.setFieldValue("requestCategoryId", null);
    form.setFieldValue("transportId", null);
    form.setFieldValue("equipmentTypeId", null);
    form.setFieldValue("equipmentId", null);
    form.setFieldValue("creatioEquipmentId", null);
  };

  return (
    <Form.Item name="isMounted" colon={false}>
      <RadioGroup
        items={[
          {
            value: false,
            label: "Стационарное оборудование",
          },
          {
            value: true,
            label: "Навесное оборудование",
          },
        ]}
        onChange={handleChange}
      />
    </Form.Item>
  );
}

export default TypeSwitchField;
