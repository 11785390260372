import { ReactElement, useEffect } from "react";
import { Tabs, Drawer } from "antd";

import { IEtoTask, ITask, TaskAttachmentCategoryEnum } from "core/app/api/interfaces";
import { useGetTaskAttachmentsQuery } from "core/app/api";
import { MediaContext, useMediaContextInitialValue } from "core/shared/task/context/mediaContext";
import { getAttachmentsCategoryCount, mediaCategoryTitles } from "core/shared/task";

import { Сategory } from "./Сategory";
import { MediaGallery } from "./MediaGallery";
import { SaveAllBtn } from "./SaveAllBtn";
import { Badge } from "./Badge";

interface MediaProps {
  task: ITask | IEtoTask;
}

/** Закладки и список медиа-файлов в карточке задания. */
function Media({ task }: MediaProps): ReactElement {
  const { data } = useGetTaskAttachmentsQuery(task.id);
  const contextValue = useMediaContextInitialValue();
  const { setTaskId, setSelectedMediaId, selectedMediaId } = contextValue;

  useEffect(() => {
    if (task) {
      setTaskId(task.id);
    }
  }, [setTaskId, task]);

  const items = [
    {
      label: (
        <Badge count={getAttachmentsCategoryCount(data, TaskAttachmentCategoryEnum.FilesByTask)}>
          {mediaCategoryTitles.files_by_task}
        </Badge>
      ),
      key: "item-0",
      children: <Сategory task={task} category={TaskAttachmentCategoryEnum.FilesByTask} />,
    },
    {
      label: (
        <Badge count={getAttachmentsCategoryCount(data, TaskAttachmentCategoryEnum.WorkPlace)}>
          {mediaCategoryTitles.work_place}
        </Badge>
      ),
      key: "item-1",
      children: <Сategory task={task} category={TaskAttachmentCategoryEnum.WorkPlace} />,
    },
    {
      label: (
        <Badge count={getAttachmentsCategoryCount(data, TaskAttachmentCategoryEnum.Before)}>
          {mediaCategoryTitles.before_or_in_progress}
        </Badge>
      ),
      key: "item-2",
      children: <Сategory task={task} category={TaskAttachmentCategoryEnum.Before} />,
    },
    {
      label: (
        <Badge count={getAttachmentsCategoryCount(data, TaskAttachmentCategoryEnum.After)}>
          {mediaCategoryTitles.after_completion}
        </Badge>
      ),
      key: "item-3",
      children: <Сategory task={task} category={TaskAttachmentCategoryEnum.After} />,
    },
  ];
  return (
    <MediaContext.Provider value={contextValue}>
      <Tabs items={items} tabBarExtraContent={<SaveAllBtn taskId={task.id} />} />
      <Drawer
        open={!!selectedMediaId}
        onClose={() => {
          setSelectedMediaId(null);
        }}
        width="100VW"
        zIndex={2000}
      >
        {data && <MediaGallery attachments={data} />}
      </Drawer>
    </MediaContext.Provider>
  );
}

export default Media;
