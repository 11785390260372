import { ReactElement, useContext } from "react";
import { Row, Col, Divider } from "antd";

import Text from "core/lib/Text/Text";
import icShowel from "core/images/ic-showel.svg";
import icHammer from "core/images/ic-hammer.svg";
import icAxes from "core/images/ic-axes.svg";
import { useGetDashboardShiftStatisticTasksQuery } from "core/app/api";
import { TaskTypesIdsEnum } from "core/app/api/interfaces";
import { ShiftContext } from "core/shared/dashboard/context/shiftContext";

import Stat from "./Stat";

function Header(): ReactElement {
  const { queryString, teamId } = useContext(ShiftContext);

  const {
    data: dashboardShiftStatisticTasks,
    isSuccess,
    isFetching,
  } = useGetDashboardShiftStatisticTasksQuery(queryString, {
    skip: !queryString,
  });

  let statistic = dashboardShiftStatisticTasks;
  if (teamId) {
    statistic = dashboardShiftStatisticTasks?.by_team?.find((item) => item.id === teamId);
  }

  return (
    <div>
      {!isFetching && isSuccess && statistic && (
        <Row>
          <Col flex={20}>
            <Row justify="center">
              <Col>ИТОГО</Col>
            </Row>
            <Stat data={statistic} />
          </Col>
          <Col flex={1}>
            <Divider type="vertical" className="h-100" />
          </Col>
          <Col flex={20}>
            <Row justify="center" gutter={8}>
              <Col>
                <img width="24px" height="24px" src={icAxes} alt="" />
              </Col>
              <Col>
                <Text size="label2">АВР</Text>
              </Col>
            </Row>
            <Stat data={statistic.by_type?.find((item) => item.name === TaskTypesIdsEnum.RECOVERY)} />
          </Col>
          <Col flex={1}>
            <Divider type="vertical" className="h-100" />
          </Col>
          <Col flex={20}>
            <Row justify="center" gutter={8}>
              <Col>
                <img width="24px" height="24px" src={icShowel} alt="" />
              </Col>
              <Col>
                <Text size="label2">ЗНО</Text>
              </Col>
            </Row>
            <Stat data={statistic.by_type?.find((item) => item.name === TaskTypesIdsEnum.SERVICE_REQUEST)} />
          </Col>
          <Col flex={1}>
            <Divider type="vertical" className="h-100" />
          </Col>
          <Col flex={20}>
            <Row justify="center" gutter={8}>
              <Col>
                <img width="24px" height="24px" src={icHammer} alt="" />
              </Col>
              <Col>
                <Text size="label2">ТО</Text>
              </Col>
            </Row>
            <Stat data={statistic.by_type?.find((item) => item.name === TaskTypesIdsEnum.MAINTENANCE)} />
          </Col>
        </Row>
      )}
    </div>
  );
}

export default Header;
