import { Button as AntButton, ButtonProps } from "antd";
import clsx from "clsx";

import "./styles.scss";

type TButtonProps = ButtonProps;

export function Button({ children, className, ...otherProps }: TButtonProps) {
  return (
    <AntButton className={clsx("custom-button", className)} {...otherProps}>
      {children}
    </AntButton>
  );
}
