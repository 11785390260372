import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
}
function TypeMaintenance({ task }: FieldProps): ReactElement {
  return <Field label="Тип ТО" value={task?.maintenance_type?.name ?? "Не определено"} />;
}
export { TypeMaintenance };
