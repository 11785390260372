import { MaintenanceExecutions } from "core/widgets/TaskModals/Stationary/Content/MaintenanceExecutions";

import { useLsoMaintenanceContext } from "../hooks";

function Content() {
  const { task } = useLsoMaintenanceContext();

  if (!task?.maintenance_execution?.length) {
    return null;
  }

  return <MaintenanceExecutions task={task} />;
}

export default Content;
