/** @jsxImportSource @emotion/react */
import { CSSObject } from "@emotion/react";
import { ReactElement } from "react";

interface BaseLayoutProps {
  children: ReactElement;
  fixed?: boolean;
}

function BaseLayout({ children, fixed }: BaseLayoutProps) {
  const styles: CSSObject = { display: "inline-block" };
  if (fixed) {
    styles.minWidth = "100%";
  } else {
    styles.width = "100%";
  }
  return (
    <div className="pl-3 pr-3 h-100 pt-8" css={styles}>
      {children}
    </div>
  );
}

export { BaseLayout };
