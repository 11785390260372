import { useGetWorkTypesQuery } from "core/app/api";
import { TGetWorkType } from "core/app/api/types";
import { ApiSliceEnum } from "core/app/api/enums";

import { useApiSlice } from "../useApiSlice";

/** Если задать specificApiSlice выдаст workTypes
 * по конкретному слайсу, иначе выдаст по слайсу через useApiSlice */
export function useWorkTypes<T extends ApiSliceEnum>(specificApiSlice?: T) {
  const apiSliceByUnit = useApiSlice();
  const apiSlice = specificApiSlice ?? apiSliceByUnit;
  const queryResults = useGetWorkTypesQuery(apiSlice, { skip: !apiSlice });
  return {
    ...queryResults,
    data: queryResults.data as unknown as TGetWorkType<typeof apiSlice>[] | undefined,
    currentData: queryResults.currentData as unknown as TGetWorkType<ApiSliceEnum>[] | undefined,
  };
}
