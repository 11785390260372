import { Row, Col } from "antd";
import { ReactElement } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { OossaSystemTypeEnum, ITask } from "core/app/api/interfaces";
import { AccumulatorElapsedTime, PlannedEffort } from "core/widgets/TaskModals/shared/Fields";
import { Field } from "core/widgets/TaskModals/shared/Fields/Field";
import StateButtons from "core/widgets/TaskModals/shared/StateButtons/FormControlled";
import TaskTitle from "core/widgets/TaskModals/shared/TaskTitle";
import { useGetOossaTechNodeQuery } from "core/app/api";
import { useGetOossaObjectQuery, useGetCustomerDepartmentQuery } from "core/app/api/api";
import { useOossaSystem } from "core/shared/oossa/api/useOossaSystems";

import TextBadge from "./TextBadge";

interface Props {
  task: ITask;
}

/** Заглавная часть (раздел) в карточке ТО для ООССА.
 * Содержит иконку, наименование задания, кнопки, атрибуты задания. */
export default function OossaMaintenanceTaskGeneralInfo(props: Props): ReactElement {
  const { task } = props;
  const oossaSystem = useOossaSystem(task.system_id);
  const { data: oossaObject } = useGetOossaObjectQuery(task.oossa_object_id ?? skipToken);
  const { data: customerDepartment } = useGetCustomerDepartmentQuery(task.customer_department_id ?? skipToken);
  const { location } = task;
  const { data: oossaTechNode } = useGetOossaTechNodeQuery(task.oossa_tech_node_id ?? skipToken);
  const oossaEquipment = oossaTechNode?.oossaequipments[0]; // СМЗиС - только одно оборудование, АСКУЭ - может быть несколько единиц оборудования (но здесь не используется)
  const oossaEquipmentType = oossaEquipment?.equipment_type; // СМЗиС - только один тип СИ
  const techNodeAddress = oossaTechNode?.address?.name;

  const badgeColor = "#13C2C2";
  const badgeBgColor = "#E6FFFB";

  return (
    <div>
      <Row>
        <Col span={12}>
          <TaskTitle icon task={task} size="h6" />
        </Col>
        <Col span={12}>
          <Row justify="end" className="mb-2">
            <Col className="mt-2">
              <StateButtons task={task} hideClearButton takeMteFromTask />
            </Col>
          </Row>
        </Col>
      </Row>
      <div style={{ marginTop: "-12px", marginBottom: "20px" }}>
        <TextBadge text={oossaSystem?.name || "?"} color={badgeColor} bgColor={badgeBgColor} />
      </div>
      {oossaSystem?.code_enum !== OossaSystemTypeEnum.SMZIS ? (
        // Если АСКУЭ, АСКУЭ ЭЭ
        <Row className="mb-1">
          <Col span={12}>
            <Field label="Локация" value={location?.name} />
            <Field label="Балансодержатель" value={customerDepartment?.name} />
            <Field label="Объект" value={oossaObject?.name} />
          </Col>
          <Col span={12}>
            <Field label="Адрес" value={techNodeAddress} />
            <PlannedEffort task={task} />
            <AccumulatorElapsedTime task={task} />
          </Col>
        </Row>
      ) : (
        // Если СМЗиС
        <div>
          <Row className="mb-1">
            <Col span={11}>
              <Field label="Локация" value={location?.name} />
              <Field label="Балансодержатель" value={customerDepartment?.name} />
              <Field label="Объект" value={oossaObject?.name} />
              <Field label="Адрес" value={techNodeAddress} />
              <Field label="Тип СИ" tooltip="Тип средства измерения" value={oossaEquipmentType?.name} />
            </Col>
            <Col span={1} />
            <Col span={11}>
              <Field label="Модель" value={oossaEquipment?.model} />
              <Field label="Тип связи" value={oossaEquipment?.connection_type} />
              <PlannedEffort task={task} />
              <AccumulatorElapsedTime task={task} />
              <Field label="Дата поверки" value={oossaEquipment?.verification_date} />
              <Field label="Высота" value={oossaTechNode?.height} />
            </Col>
          </Row>
          <Row className="mb-1">
            <Col span={11}>
              <Field label="Производитель" value={oossaEquipment?.manufacturer?.name} />
            </Col>
            <Col span={1} />
            <Col span={11}>
              <Field label="Серийный номер" value={oossaEquipment?.serial_number} />
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}
