import { FormInstance } from "antd";

export interface IHideErrorMessageOnSuccessfulCheckParams<T extends object> {
  changedValues: T;
  form: FormInstance<T>;
  hideErrorMessage: () => void;
}
export const hideErrorMessageOnSuccessfulCheck = <T extends object>(
  params: IHideErrorMessageOnSuccessfulCheckParams<T>
) => {
  const { changedValues, form, hideErrorMessage } = params;
  const changedValuesKeys = Object.keys(changedValues);
  const hideMessageOnSuccess = () => {
    const errorList = form.getFieldsError(changedValuesKeys);
    const hasErrors = errorList.some((field) => field.errors.length > 0);
    if (!hasErrors) {
      hideErrorMessage();
    }
  };
  /** Почему-то успешный результат проверки передаётся в onRejected */
  form.validateFields([changedValuesKeys]).then(hideMessageOnSuccess).catch(hideMessageOnSuccess);
};
