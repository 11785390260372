import { Row, Col, Input, Form, Space, InputNumber } from "antd";
import React, { ReactElement } from "react";

import { CheckItemGroupsEnum, IMaintenanceExecution } from "core/app/api/interfaces";
import { useDisabled } from "core/shared/task/useDisabled";

import Value from "./Value";
import { CheckListGroup } from "./CheckListGroup";

const { TextArea } = Input;

interface MaintenanceExecutionProps {
  maintenanceExecution: IMaintenanceExecution;
  index: number;
}

/** Закладка на карточке технического обслуживания (ТО).
 * Соответствует виду оборудования (системе) в навесном оборудовании ПСПА и в СЛПИ.
 * В ООССА не используется. */
function MaintenanceExecution({ maintenanceExecution, index }: MaintenanceExecutionProps): ReactElement {
  const disabled = useDisabled();

  return (
    <Row gutter={16}>
      <Col span={13}>
        <Form.Item name={["maintenance_execution", index, "id"]} hidden>
          <InputNumber />
        </Form.Item>
        {/* На форме не используется, но на бэке это поле обязательное */}
        <Form.Item name={["maintenance_execution", index, "values"]} label="values" hidden>
          <InputNumber />
        </Form.Item>
        <Space direction="vertical">
          <CheckListGroup
            title="РАБОТОСПОСОБНОСТЬ"
            maintenanceExecution={maintenanceExecution}
            maintenanceExecutionIndex={index}
            group={CheckItemGroupsEnum.Performance}
          />
          <CheckListGroup
            title="РАБОТЫ"
            maintenanceExecution={maintenanceExecution}
            maintenanceExecutionIndex={index}
            group={CheckItemGroupsEnum.Work}
          />
        </Space>
      </Col>
      <Col span={11}>
        <div>
          {maintenanceExecution.values.map((valueItem, valueIndex) => (
            <Value key={valueItem.id} maintenanceExecutionIndex={index} value={valueItem} valueIndex={valueIndex} />
          ))}
        </div>
        <div>
          <Form.Item label="КОММЕНТАРИЙ" name={["maintenance_execution", index, "comment"]}>
            <TextArea rows={5} disabled={disabled} />
          </Form.Item>
        </div>
        <div>
          <Form.Item label="ИСПОЛЬЗУЕМЫЕ МАТЕРИАЛЫ" name={["maintenance_execution", index, "used_materials"]}>
            <TextArea rows={5} disabled={disabled} />
          </Form.Item>
        </div>
      </Col>
    </Row>
  );
}

export default MaintenanceExecution;
