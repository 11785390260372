import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
}

function RequestDescription({ task }: FieldProps): ReactElement {
  return <Field label="Описание проблемы" value={task.request_description} />;
}

export { RequestDescription };
