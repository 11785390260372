import { createSlice } from "@reduxjs/toolkit";

interface IDate {
  date: string;
}

const initialState: IDate = {
  date: new Date().toISOString(),
};

export const DateSlice = createSlice({
  name: "date",
  initialState,
  reducers: {
    setDate(state, action) {
      state.date = action.payload.date;
    },
  },
});

export const { setDate } = DateSlice.actions;
