import { useEffect } from "react";
import { Col, DatePicker, Form, Row, Select } from "antd";

import dayjs from "core/shared/tools/dayjs";
import { useCustomers } from "core/shared/customer/api/useCustomers";
import { useCustomerDepartments } from "core/shared/main/useCustomerDepartments";

import "./styles.scss";

export function Filters() {
  const form = Form.useFormInstance();
  const selectedCustomerId = Form.useWatch("customer_id");
  const selectedCustomerDepartmentId = Form.useWatch("customer_department_id");

  let customerDepartmentsQueryParams: Record<string, any> = {};
  if (selectedCustomerId) {
    customerDepartmentsQueryParams = { customer_id: selectedCustomerId };
  }

  const { data: customers, isLoading: isCustomersLoading } = useCustomers();
  const { data: customerDepartments, isLoading: isCustomerDepartmentsLoading } =
    useCustomerDepartments(customerDepartmentsQueryParams);

  useEffect(() => {
    if (customerDepartments && selectedCustomerDepartmentId) {
      const selectedCustomerDepartment = customerDepartments.find((item) => item.id === selectedCustomerDepartmentId);
      if (selectedCustomerDepartment) {
        form.setFieldValue("customer_id", selectedCustomerDepartment.customer_id);
      }
    }
  }, [customerDepartments, form, selectedCustomerDepartmentId]);

  return (
    <Row className="stat-filters" gutter={[16, 8]}>
      <Col span={12}>
        <Form.Item name="year" initialValue={dayjs(new Date())} label="ГОД" colon={false}>
          <DatePicker className="stat-filters__input" format="YYYY" picker="year" allowClear={false} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="month" initialValue={dayjs(new Date())} label="МЕСЯЦ" colon={false}>
          <DatePicker className="stat-filters__input" format="MMMM" picker="month" allowClear={false} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="customer_id" label="ЗАКАЗЧИК" colon={false}>
          <Select
            className="stat-filters__input"
            allowClear
            loading={isCustomersLoading}
            onChange={(value) => {
              form.setFieldValue("customer_id", value || null);
              form.setFieldValue("customer_department_id", null);
            }}
          >
            {customers?.map((customer) => (
              <Select.Option key={customer.id} value={customer.id}>
                {customer.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="customer_department_id" label="ПОДРАЗДЕЛЕНИЕ" colon={false}>
          <Select
            className="stat-filters__input"
            allowClear
            loading={isCustomerDepartmentsLoading}
            onChange={(value) => {
              form.setFieldValue("customer_department_id", value);
            }}
          >
            {customerDepartments?.map((department) => (
              <Select.Option key={department.id} value={department.id}>
                {department.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
}
