export enum ApiSliceEnum {
  oossa = "oossa",
  pspa = "pspa",
  slpi = "slpi",
  lso = "lso",
  pspaKf = "pspa-kf",
  lku = "lku",
  vols = "vols",
}

export enum LkuEquipmentTypeEnum {
  BKT = "БКТ",
  KRT = "КРТ",
}

export enum VolsEntityTypeEnum {
  CROSS = "CROSS",
  LINE = "LINE",
}

export enum CodeGroupEnum {
  LOCATION = "location",
  TREE = "tree",
}

export enum MaintenanceTOTypeEnum {
  "ТО1" = "ТО1",
  "ТО2" = "ТО2",
  "ТО3" = "ТО3",
  "ТО4" = "ТО4",
}
