import { Input, Button, Divider, Row, Col, Alert } from "antd";
import { ReactElement, useState, useRef, ChangeEvent, MouseEvent } from "react";
import { PlusOutlined } from "@ant-design/icons";
import type { InputRef } from "antd";

import { useCreateCustomerPositionMutation } from "core/app/api";
import { PositionRoleEnum, IError } from "core/app/api/interfaces";
import { useUnit } from "core/shared/unit/useUnit";

interface DropdownProps {
  menu: ReactElement;
}

function Dropdown({ menu }: DropdownProps): ReactElement {
  const unit = useUnit();
  const [name, setName] = useState("");
  const inputRef = useRef<InputRef>(null);
  const [request, result] = useCreateCustomerPositionMutation();

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const addItem = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (unit) {
      request({
        name,
        type: PositionRoleEnum.CUSTOMER_REPRESENTATIVE,
        service: unit.service.id,
      });
    }
  };

  const error = result.error as IError | undefined;

  return (
    <>
      {menu}
      <Divider style={{ margin: "8px 0" }} />
      {error?.data &&
        error.data.name?.map((errorLine) => (
          <div style={{ padding: "0 8px 4px" }}>
            <Alert message={errorLine} type="error" />
          </div>
        ))}
      <Row style={{ padding: "0 8px 4px" }} gutter={8}>
        <Col>
          <Input width="100%" ref={inputRef} value={name} onChange={onNameChange} />
        </Col>
        <Col>
          <Button
            disabled={!name.length}
            loading={result.isLoading}
            type="text"
            icon={<PlusOutlined />}
            onClick={addItem}
          >
            ДОБАВИТЬ ДОЛЖНОСТЬ
          </Button>
        </Col>
      </Row>
    </>
  );
}

export { Dropdown };
