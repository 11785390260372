import { Row, Col } from "antd";
import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";
import { PlannedDate, Customer, AccumulatorElapsedTime, Effort } from "core/widgets/TaskModals/shared/Fields";
import { ZabbixAlert } from "core/widgets/Equipment/ZabbixAlert";
import { VolsObject } from "core/widgets/TaskModals/shared/Fields/VolsObject";
import { VolsDepartment } from "core/widgets/TaskModals/shared/Fields/VolsDepartment";
import { VolsLineNumber } from "core/widgets/TaskModals/shared/Fields/VolsLineNumber";
import { VolsEquipmentType } from "core/widgets/TaskModals/shared/Fields/VolsEquipmentType";

interface GeneralInfoProps {
  task: ITask;
}

/** Заглавная часть (раздел) в карточке ТО стационарного оборудования ПСПА, СДГР, СЛПИ, ООССА. */
function GeneralInfo(props: GeneralInfoProps): ReactElement {
  const { task } = props;

  return (
    <>
      <ZabbixAlert equipmentId={task.equipment?.id} />
      <Row className="mb-1">
        <Col>
          <PlannedDate task={task} />
          <Customer task={task} />
          <VolsDepartment task={task} />
          <VolsObject task={task} />
          <VolsLineNumber task={task} />
          <VolsEquipmentType task={task} />
          <Effort task={task} />
          <AccumulatorElapsedTime task={task} />
        </Col>
      </Row>
    </>
  );
}

export default GeneralInfo;
