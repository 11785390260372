import React, { useState } from "react";
import { Popover, Row, Col, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import Text from "core/lib/Text/Text";
import { EtoTaskAttachmentCategoryEnum, TaskAttachmentCategoryEnum } from "core/app/api/interfaces";

import { UploadMedia } from "../Upload";
import MediaCreation from "../MediaCreation";

interface SelectMediaSourceProps {
  taskId: number;
  category: TaskAttachmentCategoryEnum | EtoTaskAttachmentCategoryEnum;
}

function SelectMediaSource({ taskId, category }: SelectMediaSourceProps) {
  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const content = (
    <Row gutter={12}>
      <Col span={12}>
        <Button shape="circle" type="default" onClick={hide}>
          <MediaCreation category={category} taskId={taskId} />
        </Button>
      </Col>
      <Col span={12}>
        <UploadMedia taskId={taskId} category={category} hide={hide} />
      </Col>
    </Row>
  );

  return (
    <Popover trigger="click" content={content} open={open} onOpenChange={handleOpenChange} placement="top">
      <div>
        <PlusOutlined />
        <div>
          <Text size="label3">Добавить</Text>
        </div>
        <div>
          <Text size="label3">файл</Text>
        </div>
      </div>
    </Popover>
  );
}

export default SelectMediaSource;
