import { ReactElement, useContext } from "react";

import { ShiftContext } from "core/shared/dashboard/context/shiftContext";

import StatisticByEquipmentTypesOrObjects from "./ByEquipmentTypesOrObjects";
import StatisticByTeam from "./ByTeam";

/** Основная часть статистики по объектам/заявкам или по бригадам.
 * Для любого участка. */
export default function StatisticBody(): ReactElement {
  const { teamId } = useContext(ShiftContext);

  if (teamId) {
    return <StatisticByTeam />;
  }

  return <StatisticByEquipmentTypesOrObjects />;
}
