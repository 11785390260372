import { useState, ReactNode } from "react";
import { Typography } from "antd";

const { Paragraph: AntParagraph } = Typography;

interface Props {
  children: ReactNode;
}
export function Paragraph({ children }: Props) {
  const [ellipsis, setEllipsis] = useState(true);
  return (
    <AntParagraph
      onClick={() => {
        setEllipsis(!ellipsis);
      }}
      ellipsis={ellipsis ? { rows: 4 } : false}
      style={{ marginBottom: 0, cursor: "pointer" }}
    >
      {children}
    </AntParagraph>
  );
}
