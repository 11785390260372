import { Form, Select } from "antd";
import qs from "qs";

import { useGetEquipmentsQuery } from "core/app/api";
import { IDictionaryItem } from "core/shared/interfaces";
import { TaskTypesIdsEnum } from "core/app/api/interfaces";
import { useUnitId } from "core/shared/unit/useUnitId";
import dayjs, { apiDateFormat } from "core/shared/tools/dayjs";

interface EquipmentFieldProps {
  required?: true;
  taskType?: TaskTypesIdsEnum;
}

function EquipmentField({ required, taskType }: EquipmentFieldProps) {
  const [unitId] = useUnitId();
  const isMounted = Form.useWatch("isMounted");
  const equipmentTypeId = Form.useWatch("equipmentTypeId");
  const locationId = Form.useWatch("locationId");
  const transportId = Form.useWatch("transportId");
  const form = Form.useFormInstance();
  const queryParams: Record<string, string | number | boolean | undefined | null> = {
    unit: unitId,
    is_mounted: isMounted,
    active_for: dayjs().format(apiDateFormat),
  };
  if (equipmentTypeId) {
    queryParams.equipment_type = equipmentTypeId;
  }
  if (locationId) {
    queryParams.location = locationId;
  }
  if (taskType === TaskTypesIdsEnum.OTHER) {
    queryParams.is_mounted = false;
  }

  const queryString = qs.stringify(queryParams);
  const { data, isFetching } = useGetEquipmentsQuery(queryString);

  const options = (data?.results || []).map((item: IDictionaryItem) => ({
    label: item.name,
    value: item.id,
  }));

  let disabled = false;
  if (taskType === TaskTypesIdsEnum.OTHER && transportId) {
    disabled = true;
  }

  return (
    <Form.Item
      label="ЕДИНИЦА ОБОРУДОВАНИЯ"
      name="equipmentId"
      colon={false}
      rules={required && [{ required: true, message: "" }]}
    >
      <Select
        className="equipment-field"
        allowClear
        disabled={disabled}
        size="large"
        dropdownMatchSelectWidth
        optionFilterProp="children"
        showSearch
        loading={isFetching}
        onChange={(val) => {
          const equipment = (data?.results || []).find((item) => item.id === val);
          if (equipment) {
            form.setFieldValue("equipmentTypeId", equipment.equipment_type_id);
            form.setFieldValue("locationId", equipment.location_id);
            if (equipment.transport) {
              form.setFieldValue("transportId", equipment.transport.id);
            }
          } else {
            form.setFieldValue("locationId", null);
          }
          form.validateFields(["locationId", "equipmentTypeId"]);
        }}
        filterOption={(input, option) =>
          (option?.label?.toString() ?? "").toLowerCase().includes(input.trim().toLowerCase())
        }
        options={options}
      />
    </Form.Item>
  );
}

export default EquipmentField;
