import { skipToken } from "@reduxjs/toolkit/query/react";

import { useGetItsmDelayReasonsQuery } from "core/app/api";
import { useApiSlice } from "core/shared/main/useApiSlice";
import { ApiSliceEnum } from "core/app/api/enums";

export function useItsmDelayReasons() {
  const stateSlice = useApiSlice();
  /**
   * Для pspa-kf, lku и lso, на бэкенде не существует соответствующих точек для получения
   * причин приостановки, для них используется pspa
   */
  const slice =
    stateSlice === ApiSliceEnum.pspaKf || stateSlice === ApiSliceEnum.lku || stateSlice === ApiSliceEnum.lso
      ? ApiSliceEnum.pspa
      : stateSlice;

  return useGetItsmDelayReasonsQuery(slice || skipToken);
}
