import { ReactElement } from "react";

import { useUnit } from "core/shared/unit/useUnit";
import { TaskModal } from "core/widgets/TaskModals";

import Header from "./Header";
import StatisticBody from "./Body";
import { StatisticTeamSelectorPspa } from "./TeamSelectorPspa";
import { StatisticTeamSelectorSlpi } from "./TeamSelectorSlpi";
import StatisticTeamSelectorLso from "./TeamSelectorLso";
import { StatisticTeamSelectorOossa } from "./TeamSelectorOossa";

export default function RightColShiftsStatistic(): ReactElement {
  const unit = useUnit();

  return (
    <>
      <div
        style={{
          display: "flex",
          flexFlow: "column",
          height: "100%",
        }}
      >
        <div
          className="mb-4"
          style={{
            flex: "0 1 auto",
          }}
        >
          <Header />
        </div>
        <div
          className="mb-2"
          style={{
            flex: "0 1 auto",
          }}
        >
          {unit?.isPSPA() && <StatisticTeamSelectorPspa />}
          {unit?.isSLPI() && <StatisticTeamSelectorSlpi />}
          {unit?.isLSO() && <StatisticTeamSelectorLso />}
          {unit?.isOOSSA() && <StatisticTeamSelectorOossa />}
        </div>
        <div
          style={{
            flex: "1 1 auto",
            display: "flex",
            alignItems: "center",
            alignSelf: "center",
            width: "100%",
          }}
        >
          <StatisticBody />
        </div>
      </div>
      <TaskModal />
    </>
  );
}
