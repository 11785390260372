import { Button } from "antd";

import { useTaskId } from "core/shared/task/useTaskId";

import { IJournalRecord } from "..";

interface CellProps {
  record: IJournalRecord;
}

export default function NumberCell({ record }: CellProps) {
  const [, setTaskId] = useTaskId();
  return (
    <Button
      type="link"
      size="small"
      onClick={() => {
        setTaskId(record.key);
      }}
    >
      {record.number}
    </Button>
  );
}
