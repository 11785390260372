import { ReactElement } from "react";

import { ITask, TaskTypesIdsEnum } from "core/app/api/interfaces";
import { getSystemsString } from "core/pages/SLPI/Yearly/SlpiObject/tools";
import { isLkuTask, isLsoTask, isOossaTask, isSlpiTask, isVolsTask } from "core/shared/task";
import { OossaTaskCaption } from "core/widgets/Oossa/OossaMaintenanceTaskContent/OossaMaintenanceTaskGeneralInfo/OossaTaskCaption";

interface TaskCaptionProps {
  task: ITask;
}

const EMPTY = "Не выбрано";

const getTaskCaptionString = (task: ITask) => {
  if (task.type === TaskTypesIdsEnum.OTHER) {
    return task.work_type?.name || EMPTY;
  }
  if (task.type === TaskTypesIdsEnum.ETO && task.transport) {
    return task.transport.name;
  }
  if (task.is_mounted && task.transport) {
    return task.transport.name;
  }
  if (!task.is_mounted && task.equipment) {
    return task.equipment.name;
  }
  if (!task.is_mounted && isSlpiTask(task) && task.system_complex?.systems) {
    return getSystemsString(task.system_complex.systems);
  }
  if (isLsoTask(task)) {
    return task.lso_object?.name || EMPTY;
  }
  if (isLkuTask(task) && task.lku_equipment?.name) {
    return `${task.lku_rack?.name} ${task.lku_equipment?.name}`;
  }
  if (isVolsTask(task) && task.line?.name) {
    return task.line?.name;
  }
  return EMPTY;
};

/** Наименование (элемент) карточки задания (ТО подвесных, ТО стационарных, АВР, доп. работ). */
export function TaskCaption({ task }: TaskCaptionProps): ReactElement {
  if (isOossaTask(task)) return <OossaTaskCaption task={task} />;

  const caption = getTaskCaptionString(task);

  return <span data-task-id={task.id}>{caption}</span>;
}
