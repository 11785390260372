import { ReactElement } from "react";
import clsx from "clsx";
import plugin from "dayjs/plugin/duration";

import dayjs from "core/shared/tools/dayjs";
import { isGroup } from "core/shared/dashboard";
import { formatHours as formatHoursHH } from "core/shared/main/tools/formatHours";
import { EffortTypeEnum } from "core/models/enums";

import { ICellProps } from "..";

interface ElapsedTimeProps extends ICellProps {
  effortType: EffortTypeEnum;
}

/** Фактическая загрузка в ячейке таблицы статистики по команде. */
export default function ElapsedTime({ record, effortType }: ElapsedTimeProps): ReactElement {
  let effort: plugin.Duration;
  let elapsedTimeTotal: plugin.Duration;

  if (isGroup(record)) {
    if (!record.children?.length) {
      return <div />;
    }
    elapsedTimeTotal = record.children.reduce((duration, row) => {
      const { elapsed_time: elapsedTime, elapsed_time_multiplier: elapsedTimeMultiplier } = row;
      return duration.add({
        seconds: dayjs.duration(elapsedTime).asSeconds() * elapsedTimeMultiplier,
      });
    }, dayjs.duration(0));
    effort = record.children.reduce((duration, row) => duration.add(dayjs.duration(row.effort)), dayjs.duration(0));
  } else {
    const { elapsed_time: elapsedTime, elapsed_time_multiplier: elapsedTimeMultiplier } = record;
    elapsedTimeTotal = dayjs.duration({}).add({
      seconds: dayjs.duration(elapsedTime).asSeconds() * elapsedTimeMultiplier,
    });
    effort = dayjs.duration(record.effort);
  }
  const hours = Math.floor(elapsedTimeTotal.asHours());
  const minutes = elapsedTimeTotal.minutes();
  const timeStr = `${hours}ч. ${minutes}м.`;

  if (effortType === EffortTypeEnum.MAN_HOURS) {
    return (
      <div
        className={clsx({
          positive: elapsedTimeTotal.asSeconds() <= effort.asSeconds(),
          negative: elapsedTimeTotal.asSeconds() > effort.asSeconds(),
        })}
      >
        {formatHoursHH(elapsedTimeTotal.asHours())}
      </div>
    );
  }
  return (
    <div
      className={clsx({
        positive: elapsedTimeTotal.asSeconds() <= effort.asSeconds(),
        negative: elapsedTimeTotal.asSeconds() > effort.asSeconds(),
      })}
    >
      {timeStr}
    </div>
  );
}
