import { Checkbox, FormInstance, Form } from "antd";

interface IncludeDetailedResultForCaseProps {
  disabled: boolean;
  form: FormInstance;
}

function IncludeDetailedResultForCase({ disabled, form }: IncludeDetailedResultForCaseProps) {
  return (
    <Form.Item valuePropName="checked" name="include_detailed_result_for_case">
      <Checkbox disabled={disabled}>Включение комментариев в ответ конечного пользователя</Checkbox>
    </Form.Item>
  );
}

export { IncludeDetailedResultForCase };
