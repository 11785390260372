import { Form } from "antd";

import Text from "core/lib/Text/Text";
import dayjs from "core/shared/tools/dayjs";

export function ElapsedTimeTotal() {
  const multiplier = Form.useWatch("elapsed_time_multiplier");
  const elapsedTime = Form.useWatch("elapsed_time");
  let parts: string[] = [];
  if (elapsedTime) {
    parts = (elapsedTime as string).split(":");
  }

  /**
   * При простом создании минуты более 60 не пересчитываются в доп час.
   * При добавлении через метод .add() - пересчитываются
   */
  const elapsedTimeDuration = dayjs.duration({}).add({
    hours: parseInt(parts[0]?.replaceAll("_", "0"), 10) * multiplier,
    minutes: parseInt(parts[1]?.replaceAll("_", "0"), 10) * multiplier,
    seconds: parseInt(parts[2]?.replaceAll("_", "0"), 10) * multiplier,
  });

  // Весь интервал в целых часах
  const H = Math.floor(elapsedTimeDuration.asHours());
  // Оставшиеся минуты
  const mm = elapsedTimeDuration.minutes();

  return (
    <Form.Item>
      <Text size="label1" boldness="semibold">
        = {`${H}ч. ${mm}мин.`}
      </Text>
    </Form.Item>
  );
}
