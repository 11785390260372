import React, { ReactElement } from "react";
import { Row, Col } from "antd";

import Text from "core/lib/Text/Text";
import { useGetEquipmentTypeQuery } from "core/app/api";

interface EquipmentTypeModelProps {
  equipmentTypeId: string | number;
  model?: string;
}

function EquipmentTypeModel({ equipmentTypeId, model }: EquipmentTypeModelProps): ReactElement {
  const { data } = useGetEquipmentTypeQuery(equipmentTypeId);

  return (
    <Row>
      <Col>
        <Text size="label2">Модель {data?.name}:&nbsp;</Text>
      </Col>
      <Col>
        <Text size="label2" boldness="black">
          {model}
        </Text>
      </Col>
    </Row>
  );
}

export default EquipmentTypeModel;
