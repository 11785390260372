import { Col, Form, Row, Space, Typography } from "antd";

import Text from "core/lib/Text/Text";

import "./styles.scss";
import AttachmentsList from "./AttachmentList";

function AttachmentsField() {
  return (
    <Row className="attachments-field">
      <Col>
        <Text className="attachments-field__label" size="label4">
          ДОПОЛНИТЕЛЬНЫЕ МАТЕРИАЛЫ:
        </Text>
      </Col>
      <Col span={24}>
        <Space className="attachments-field__info" direction="vertical">
          <Typography.Text className="label4 attachments-field__info-item">
            Поддерживаемые форматы Jpg, Png, Xlsx, Docx, Pdf
          </Typography.Text>
          <Typography.Text className="label4 attachments-field__info-item">
            Общий объем всех загружаемых файлов не более 20 Мб
          </Typography.Text>
        </Space>
      </Col>
      <Col span={24}>
        <Form.Item name="attachments" getValueFromEvent={(_) => _}>
          <AttachmentsList />
        </Form.Item>
      </Col>
    </Row>
  );
}

export default AttachmentsField;
