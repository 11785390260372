import { Box } from "@mui/material";
import { ReactElement } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ConfigProvider } from "antd";

import Login from "core/pages/Login";
import { useAppSelector } from "core/app/store";
import EquipmentModal from "core/widgets/EquipmentModal";
import { PrintTaskReportModal } from "core/widgets/PrintTaskReportModal";
import { AppBar } from "core/widgets/AppBar";
import { RecoveryTasksNotificationsWrapper } from "core/widgets/RecoveryTasksNotifications";
import CreateTaskModal from "core/widgets/CreateTaskModal";
import SignatureWorkerWrapper from "core/widgets/SignatureWorkerWrapper";
import { useUpdateUserPermissions } from "core/shared/useUpdateUserPermissions";
import { locale } from "core/shared/tools/locale";
import { MatomoTagManager } from "core/widgets/MatomoTagManager";

import LayoutRoutes from "./routes";

import "./styles.scss";

function App(): ReactElement {
  const { token } = useAppSelector((store) => store.token);

  useUpdateUserPermissions();

  if (!token) {
    return <Login />;
  }

  return (
    <ConfigProvider locale={locale}>
      <CssBaseline />
      <Box className="h-100">
        <Box sx={{ flexGrow: 1 }}>
          <AppBar />
        </Box>
        <LayoutRoutes />
      </Box>
      <EquipmentModal />
      <CreateTaskModal />
      <PrintTaskReportModal />
      <RecoveryTasksNotificationsWrapper />
      <SignatureWorkerWrapper />
      <MatomoTagManager />
    </ConfigProvider>
  );
}

export default App;
