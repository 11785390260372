import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";
import dayjs, { displayDateFormat } from "core/shared/tools/dayjs";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
}

function SuspendedUntil({ task }: FieldProps): ReactElement {
  const suspendedUntilFormatted = dayjs(task.suspended_until).isValid()
    ? dayjs(task.suspended_until).format(displayDateFormat)
    : "";
  return <Field label="Приостановлено до" value={suspendedUntilFormatted} />;
}

export { SuspendedUntil };
