/* eslint-disable camelcase */
import qs from "qs";

import { apiSlice, extractResults } from "core/app/api/api";

import { IResponseBody, ITask } from "../interfaces";

import {
  IApproveVolsCrossMonthPlanPayload,
  IGetVolsCrossMonthPlansQueryParams,
  IGetVolsCrossMonthPlansStatsQueryParams,
  IGetVolsCrossMonthPlansTotalsByObjectsQueryParams,
  IGetVolsMonthPlanStatusQueryParams,
  IUpdateVolsCrossMonthPlanPayload,
  IVolsCross,
  IVolsCrossesMonthPlanByObject,
  IVolsCrossesMonthPlanStats,
  IVolsCrossMonthPlan,
  IVolsLine,
  IVolsMonthPlanStatus,
  TCreateVolsTaskBody,
  IVolsObject,
  IVolsTask,
} from "./interfaces";

export const volsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVolsCrossMonthPlansStats: builder.query<IVolsCrossesMonthPlanStats, IGetVolsCrossMonthPlansStatsQueryParams>({
      query: (queryParams) => `vols-cross-month-plans/stat/?${qs.stringify(queryParams)}`,
      providesTags: ["vols-cross-month-plans-stats"],
    }),
    getVolsCrossMonthPlansTotalsByObjects: builder.query<
      IResponseBody<IVolsCrossesMonthPlanByObject>,
      IGetVolsCrossMonthPlansTotalsByObjectsQueryParams
    >({
      query: (queryParams) => `vols-cross-month-plans/totals-by-objects/?${qs.stringify(queryParams)}`,
      providesTags: (response) => [
        { type: "vols-cross-month-plans-totals-by-objects", id: "LIST" },
        ...(response?.results || []).map(({ id }) => ({
          type: "vols-cross-month-plans-totals-by-objects" as const,
          id,
        })),
      ],
    }),
    getVolsMonthPlanStatus: builder.query<IVolsMonthPlanStatus[], IGetVolsMonthPlanStatusQueryParams>({
      query: (queryParams) => `vols-month-plan-status/?${qs.stringify(queryParams)}`,
      providesTags: ["vols-month-plan-status"],
      transformResponse: extractResults,
    }),
    getVolsCrossMonthPlans: builder.query<IVolsCrossMonthPlan[], IGetVolsCrossMonthPlansQueryParams>({
      query: (queryParams) => `vols-cross-month-plans/?${qs.stringify(queryParams)}`,
      providesTags: (_, __, { object_id }) => [{ type: "vols-cross-month-plans", id: object_id }],
      transformResponse: extractResults,
    }),
    updateVolsCrossMonthPlan: builder.mutation<void, IUpdateVolsCrossMonthPlanPayload>({
      query: ({ id, object_id, ...body }) => ({
        url: `vols-cross-month-plans/${id}/`,
        method: `PATCH`,
        body,
      }),
      invalidatesTags: (_, __, { object_id }) => [
        { type: "vols-cross-month-plans-totals-by-objects", id: object_id },
        { type: "vols-cross-month-plans", id: object_id },
        "vols-cross-month-plans-stats",
      ],
    }),
    approveVolsCrossMonthPlan: builder.mutation<void, IApproveVolsCrossMonthPlanPayload>({
      query: (body) => ({
        url: `vols-cross-month-plans/approve/`,
        method: "POST",
        body,
      }),
      invalidatesTags: [
        "vols-month-plan-status",
        { type: "vols-cross-month-plans-totals-by-objects", id: "LIST" },
        "vols-cross-month-plans",
      ],
    }),
    undoApproveVolsCrossMonthPlan: builder.mutation<void, IApproveVolsCrossMonthPlanPayload>({
      query: (body) => ({
        url: `vols-cross-month-plans/undo-approve/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["vols-month-plan-status", { type: "vols-cross-month-plans-totals-by-objects", id: "LIST" }],
    }),
    createVolsTask: builder.mutation<IVolsTask, TCreateVolsTaskBody>({
      query: (body) => ({
        url: `vols-tasks/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["tasks", "open-tasks", "shifttask", "tasks-filtered", "kanban-board"],
    }),
    getVolsObjects: builder.query<IVolsObject[], string>({
      query: (queryString) => `vols-object/?${queryString}`,
      providesTags: ["vols-object"],
      transformResponse: extractResults,
    }),
    getVolsLines: builder.query<IVolsLine[], string>({
      query: (queryString) => `vols-line/?${queryString}`,
      providesTags: ["vols-line"],
      transformResponse: extractResults,
    }),
    getVolsCrosses: builder.query<IVolsCross[], string>({
      query: (queryString) => `vols-cross/?${queryString}`,
      providesTags: ["vols-cross"],
      transformResponse: extractResults,
    }),
    updateVolsTask: builder.mutation({
      query: (task) => ({
        url: `vols-tasks/${task.id}/`,
        method: "PATCH",
        body: task,
      }),
      async onQueryStarted({ queryString, ...taskUpdate }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData("getOpenTasks", queryString, (cachedTasks) => {
            const cachedTaskToUpdate = cachedTasks.results.find((cachedTask: ITask) => cachedTask.id === taskUpdate.id);
            if (cachedTaskToUpdate) {
              Object.assign(cachedTaskToUpdate, taskUpdate);
            }
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: "task", id: arg.id },
        { type: "open-tasks", id: arg.id },
        "tasks",
        "shifttask",
        "tasks-filtered",
        "kanban-board",
        "month-plan",
        "tasks/statistics-by-days",
        "dashboard/shift-statistic/tasks",
        "dashboard/shift-statistic/tasks/by-equipment-types",
        "dashboard/shift-statistic/tasks/by-objects/slpi",
      ],
    }),
  }),
});

export const {
  useGetVolsCrossMonthPlansTotalsByObjectsQuery,
  useGetVolsCrossMonthPlansStatsQuery,
  useGetVolsMonthPlanStatusQuery,
  useGetVolsCrossMonthPlansQuery,
  useUpdateVolsCrossMonthPlanMutation,
  useApproveVolsCrossMonthPlanMutation,
  useUndoApproveVolsCrossMonthPlanMutation,
  useCreateVolsTaskMutation,
  useGetVolsObjectsQuery,
  useGetVolsLinesQuery,
  useGetVolsCrossesQuery,
  useUpdateVolsTaskMutation,
} = volsApiSlice;
