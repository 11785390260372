import { Space, Typography } from "antd";

import "./styles.scss";
import { useAppSelector } from "core/app/store";

const { Text } = Typography;

function JournalStats() {
  const signatures = useAppSelector((state) => state.taskSignature.signatures);
  const notSentSignaturesCount = signatures.filter((signature) => !signature.hasBeenSent).length;
  const sentWithErrorSignaturesCount = signatures.filter((signature) => signature.errorMessage).length;
  return (
    <Space className="journal-stats" direction="horizontal" size={16}>
      <Space className="journal-stats__item" direction="vertical" size={0} align="center">
        <Text className="journal-stats__item__text journal-stats__item__text--primary">{signatures.length}</Text>
        <Text className="journal-stats__item__text journal-stats__item__text--secondary">Всего подписей</Text>
      </Space>
      <Space className="journal-stats__item" direction="vertical" size={0} align="center">
        <Text className="journal-stats__item__text journal-stats__item__text--primary">{notSentSignaturesCount}</Text>
        <Text className="journal-stats__item__text journal-stats__item__text--secondary">Не оправленных</Text>
      </Space>
      <Space className="journal-stats__item" direction="vertical" size={0} align="center">
        <Text className="journal-stats__item__text journal-stats__item__text--primary">
          {sentWithErrorSignaturesCount}
        </Text>
        <Text className="journal-stats__item__text journal-stats__item__text--secondary">С ошибкой</Text>
      </Space>
    </Space>
  );
}

export default JournalStats;
