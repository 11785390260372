import { createSlice } from "@reduxjs/toolkit";

interface ITasksForShiftState {
  teamId: number | null;
  disbanded: boolean;
  shiftId: number | null;
}

const initialState: ITasksForShiftState = {
  teamId: null,
  disbanded: false,
  shiftId: null,
};

export const TasksForShiftSlice = createSlice({
  name: "tasks-for-shift",
  initialState,
  reducers: {
    setTeamId(state, action: { payload: { id: number | null; disbandedStatus: boolean; shiftTeamId: number | null } }) {
      state.teamId = action.payload.id;
      state.disbanded = action.payload.disbandedStatus;
      state.shiftId = action.payload.shiftTeamId;
    },
  },
});

export const { setTeamId } = TasksForShiftSlice.actions;
