import dayjs from "dayjs";

import { displayDateFormat } from "core/shared/tools/dayjs";

import { ICellRendererProps } from "../../interfaces";

function ShiftDateCellRenderer({ record }: ICellRendererProps) {
  return <div>{dayjs(record.shiftDate).format(displayDateFormat)}</div>;
}

export default ShiftDateCellRenderer;
