import { ReactElement, useEffect } from "react";

import Text from "core/lib/Text/Text";
import { useAppSelector, useAppDispatch } from "core/app/store";
import { reset as resetTasks } from "core/app/store/tasks-slice";
import { reset as resetMaintenance } from "core/app/store/maintenance-slice";
import { reset as resetMaintenanceAttached } from "core/app/store/maintenance-attached-slice";
import { reset as resetMain } from "core/app/store/main-slice";
import { setVersion } from "core/app/store/version-slice";

function VersionNumber(): ReactElement {
  const { version: stateVersion } = useAppSelector((state) => state.version);
  const dispatch = useAppDispatch();
  const envVersion = process.env.REACT_APP_VERSION;

  useEffect(() => {
    if (envVersion && stateVersion !== envVersion) {
      dispatch(resetTasks());
      dispatch(resetMaintenance());
      dispatch(resetMaintenanceAttached());
      dispatch(resetMain());
      dispatch(setVersion(envVersion));
    }
  }, [dispatch, envVersion, stateVersion]);

  return (
    <div style={{ padding: "6px 16px" }}>
      <Text size="label2">
        <>v.{envVersion}</>
      </Text>
    </div>
  );
}

export default VersionNumber;
