import { ReactElement } from "react";
import { Row, Col, Alert, Card } from "antd";

import icAlertTriangle from "core/images/ic-alert-triangle.svg";
import Text from "core/lib/Text/Text";
import { useGetEquipmentQuery } from "core/app/api";

import Skeletons from "../Skeletons";

import MaintenanceTable from "./MaintenanceTable";
import CommentsTimeLine from "./CommentsTimeLine";
import MaintenanceSchedule from "./MaintenanceSchedule";
import Unit from "./Unit";
import EquipmentType from "./EquipmentType";
import IsMounted from "./IsMounted";
import EquipmentTypeModel from "./EquipmentTypeModel";
import { ZabbixAlert } from "./ZabbixAlert";

interface EquipmentProps {
  equipmentId: number;
}

function Equipment({ equipmentId }: EquipmentProps): ReactElement {
  const { data: equipment, isLoading: isLoadingEquipment } = useGetEquipmentQuery(`${equipmentId}`);

  return (
    <Row>
      {isLoadingEquipment ? (
        <Skeletons />
      ) : (
        <Col span={24}>
          <Card bordered={false}>
            {equipment?.has_malfunction && (
              <Alert
                className="mb-3"
                message="Статус: Неисправно"
                type="warning"
                showIcon
                icon={<img className="ic-alert-triangle" src={icAlertTriangle} alt="" />}
              />
            )}
            <ZabbixAlert equipmentId={equipmentId} />
            <Row className="mb-1">
              <Col>
                <Text size="body1" boldness="black" data-id={equipment?.id}>
                  {equipment?.name}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={10}>
                <Row>
                  <Col>
                    <Text size="label2">Номер единицы оборудования:&nbsp;</Text>
                  </Col>
                  <Col>
                    <Text size="label2" boldness="black" className="blue">
                      01484420
                    </Text>
                  </Col>
                </Row>
                {equipment?.equipment_type_id && <IsMounted equipmentTypeId={equipment?.equipment_type_id} />}
                {equipment?.equipment_type_id && <EquipmentType equipmentTypeId={equipment?.equipment_type_id} />}
                {equipment?.location?.unit && <Unit unitId={equipment?.location?.unit} />}
                <Row>
                  <Col>
                    <Text size="label2">Местоположение (участок):&nbsp;</Text>
                  </Col>
                  <Col>
                    <Text size="label2" boldness="black">
                      {equipment?.location?.name}
                    </Text>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Text size="label2">Заказчик:&nbsp;</Text>
                  </Col>
                  <Col>
                    <Text size="label2" boldness="black">
                      ЗФ ПАО &quot;ГМК Норильский никель&quot;
                    </Text>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Text size="label2">Категория оборудования:&nbsp;</Text>
                  </Col>
                  <Col>
                    <Text size="label2" boldness="black">
                      Система
                    </Text>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Text size="label2">Дата последней поверки:&nbsp;</Text>
                  </Col>
                  <Col>
                    <Text size="label2" boldness="black">
                      &nbsp;
                    </Text>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Text size="label2">Плановая дата следующей поверки:&nbsp;</Text>
                  </Col>
                  <Col>
                    <Text size="label2" boldness="black">
                      &nbsp;
                    </Text>
                  </Col>
                </Row>
                {equipment?.equipment_type_id && (
                  <EquipmentTypeModel
                    equipmentTypeId={equipment?.equipment_type_id}
                    model={equipment?.equipment_model}
                  />
                )}
              </Col>
              <Col span={14}>
                <MaintenanceSchedule equipmentId={equipmentId} />
              </Col>
            </Row>
          </Card>
          <MaintenanceTable equipmentId={equipmentId} />
          <CommentsTimeLine equipmentId={equipmentId} />
        </Col>
      )}
    </Row>
  );
}

export default Equipment;
