import { ReactElement, useContext } from "react";
import { Empty } from "antd";

import { StatisticTeamSelector } from "core/shared/dashboard/ui/TeamSelector";
import { ShiftContext } from "core/shared/dashboard/context/shiftContext";
import { useGetDashboardShiftStatisticTasksByTeamsLsoQuery } from "core/app/api/LSO";

export default function StatisticTeamSelectorLso(): ReactElement {
  const { queryString } = useContext(ShiftContext);
  const { data, isSuccess } = useGetDashboardShiftStatisticTasksByTeamsLsoQuery(queryString, { skip: !queryString });

  return isSuccess && data ? <StatisticTeamSelector teams={data} /> : <Empty />;
}
