import { DatePicker, Form, Row } from "antd";
import dayjs from "dayjs";

interface IPspaMaintenanceDashboardFiltersProps {
  year: number;
  month: number;
  onChangeYear: (year: number) => void;
  onChangeMonth: (month: number) => void;
}

export function PspaMaintenanceDashboardFilters({
  year,
  month,
  onChangeYear,
  onChangeMonth,
}: IPspaMaintenanceDashboardFiltersProps) {
  return (
    <>
      <Row>
        <Form.Item
          name="year"
          initialValue={dayjs(new Date(year, month, 1))}
          label={<p style={{ textTransform: "uppercase" }}>Год</p>}
          colon={false}
          style={{ width: "100%" }} /* для отображения элемента формы на всю ширину родительского элемента */
          labelCol={{ span: 24 }} /* для отображения метки на отдельной строке */
          wrapperCol={{ span: 24 }} /* для отображения элемента ввода на всю ширину элемента формы */
        >
          <DatePicker
            style={{ width: "100%" }}
            format="YYYY"
            picker="year"
            allowClear={false}
            onChange={(_, s) => s && onChangeYear(parseInt(s, 10))}
          />
        </Form.Item>
      </Row>
      <Row>
        <Form.Item
          name="month"
          initialValue={dayjs(new Date())}
          label={<p style={{ textTransform: "uppercase" }}>Месяц</p>}
          colon={false}
          style={{ width: "100%" }}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <DatePicker
            style={{ width: "100%" }}
            format="MMMM"
            picker="month"
            allowClear={false}
            onChange={(v, _) => v && onChangeMonth(v.month() + 1)}
          />
        </Form.Item>
      </Row>
    </>
  );
}
