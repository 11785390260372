import { createContext, useState, useMemo, useContext } from "react";

const MediaContext = createContext({
  taskId: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTaskId: (arg: number) => {},
  selectedMediaId: null as null | number,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedMediaId: (arg: null | number) => {},
});

const useMediaContextInitialValue = () => {
  const [taskId, setTaskId] = useState<number>(0);
  const [selectedMediaId, setSelectedMediaId] = useState<null | number>(null);
  const contextValue = useMemo(
    () => ({
      taskId,
      setTaskId,
      selectedMediaId,
      setSelectedMediaId,
    }),
    [selectedMediaId, taskId]
  );

  return contextValue;
};

const useMediaContext = () => useContext(MediaContext);

export { useMediaContextInitialValue, MediaContext, useMediaContext };
