import { groupBy, get, Dictionary } from "lodash";

export function groupWithOrder<T>(checklists: T[] | undefined, path: string): Dictionary<T[]> {
  let i = 0;
  let currentGroupKey = "";
  const checklistsByPlace = groupBy(checklists, (item: T) => {
    const groupName = get(item, path);
    let groupKey = `${groupName}_${i}`;
    if (currentGroupKey === groupKey) {
      return groupKey;
    }
    i += 1;
    groupKey = `${groupName}_${i}`;
    currentGroupKey = groupKey;
    return groupKey;
  });
  return checklistsByPlace;
}
