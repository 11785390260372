import { ReactElement } from "react";
import { Row, Col } from "antd";

import Text from "core/lib/Text/Text";

interface PageTitleProps {
  title: string;
}

function PageTitle({ title }: PageTitleProps): ReactElement {
  return (
    <Row>
      <Col>
        <Text size="body1" boldness="semibold">
          {title}
        </Text>
      </Col>
    </Row>
  );
}

export default PageTitle;
