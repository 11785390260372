import { Select, Form } from "antd";
import { ReactElement } from "react";

import { IDictionaryItem, ISelectOption } from "core/shared/interfaces";
import { useDisabled } from "core/shared/task/useDisabled";
import { useUnitRepairAreas } from "core/shared/main/useUnitRepairAreas";

function RepairAreaSelect(): ReactElement {
  const { data: repairAreas, isLoading } = useUnitRepairAreas();
  const disabled = useDisabled();

  let options: ISelectOption[] = [];

  if (repairAreas) {
    options = repairAreas.map((unit: IDictionaryItem) => ({
      label: unit.name,
      value: unit.id,
    }));
  }

  return (
    <Form.Item label="РЕМОНТНЫЙ УЧАСТОК" name="repair_area_id">
      <Select
        loading={isLoading}
        disabled={disabled}
        style={{ width: "100%" }}
        placeholder="Ремонтный участок"
        dropdownStyle={{ zIndex: 2000 }}
        options={options}
      />
    </Form.Item>
  );
}

export default RepairAreaSelect;
