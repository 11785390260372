import { Table, TableProps } from "antd";

import { ITaskSignature } from "core/app/store/task-signature-slice";
import { useAppSelector } from "core/app/store";

import RowNumberCellRenderer from "./renderers/RowNumberCellRenderer";
import ShiftDateCellRenderer from "./renderers/ShiftDateCellRenderer";
import ShiftNumberCellRenderer from "./renderers/ShiftNumberCellRenderer";
import TransportNumberCellRenderer from "./renderers/TransportNumberCellRenderer";
import SignatureTypeCellRenderer from "./renderers/SignatureTypeCellRenderer";
import SignatureDateCellRenderer from "./renderers/SignatureDateRenderer";
import "./styles.scss";
import SignatureRequestStatusCellRenderer from "./renderers/SignatureRequestStatusCellRenderer";
import SignatureRequestAttemptsCountCellRenderer from "./renderers/SignatureRequestAttemptsCountCellRenderer";
import SignatureRequestErrorCellRenderer from "./renderers/SignatureRequestErrorCellRenderer";
import { ActionsCellRenderer } from "./renderers/ActionsCellRenderer";

const columns: TableProps<ITaskSignature>["columns"] = [
  {
    key: 1,
    title: "№",
    render: (_, record, index) => <RowNumberCellRenderer record={record} index={index} />,
    className: "journal-table__column",
    width: 40,
  },
  {
    key: 2,
    title: "ДАТА СМЕНЫ",
    render: (_, record, index) => <ShiftDateCellRenderer record={record} />,
    className: "journal-table__column journal-table__column--colored",
  },
  {
    key: 3,
    title: "№ БРИГАДЫ",
    render: (_, record, index) => <ShiftNumberCellRenderer record={record} />,
    className: "journal-table__column journal-table__column--colored",
  },
  {
    key: 4,
    title: "№ МАШИНЫ",
    render: (_, record, index) => <TransportNumberCellRenderer record={record} />,
    className: "journal-table__column journal-table__column--colored",
  },
  {
    key: 5,
    title: "ТИП ПОДПИСАНТА",
    render: (_, record, index) => <SignatureTypeCellRenderer record={record} />,
    className: "journal-table__column journal-table__column--colored",
  },
  {
    key: 6,
    title: "ДАТА ПОДПИСИ",
    render: (_, record, index) => <SignatureDateCellRenderer record={record} />,
    className: "journal-table__column journal-table__column--colored",
  },
  {
    key: 7,
    title: "СТАТУС ОТПРАВКИ",
    render: (_, record, index) => <SignatureRequestStatusCellRenderer record={record} />,
    className: "journal-table__column journal-table__column--colored",
  },
  {
    key: 8,
    title: "ПОПЫТОК ОТПРАВКИ",
    render: (_, record, index) => <SignatureRequestAttemptsCountCellRenderer record={record} />,
    className: "journal-table__column journal-table__column--colored",
  },
  {
    key: 9,
    title: "ТЕКСТ ОШИБКИ",
    render: (_, record, index) => <SignatureRequestErrorCellRenderer record={record} />,
    className: "journal-table__column journal-table__column--colored",
  },
  {
    key: 10,
    title: "ДЕЙСТВИЯ",
    render: (_, record, index) => <ActionsCellRenderer record={record} />,
    className: "journal-table__column journal-table__column--colored",
  },
];

function JournalTable() {
  const signatures = useAppSelector((state) => state.taskSignature.signatures);

  return <Table className="journal-table" pagination={false} columns={columns} dataSource={signatures} rowKey="id" />;
}

export default JournalTable;
