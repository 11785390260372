import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
}

export function OossaSystem({ task }: FieldProps): ReactElement {
  return <Field label="Система" value={task.oossa_system?.name || task.system?.name} />;
}
