import { useCallback } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import qs from "qs";

import { ITask, TaskDiscriminatorEnum, TaskSnapMomentEnum } from "core/app/api/interfaces";
import { useGetEtoTaskQuery, useGetTaskQuery } from "core/app/api";
import { useAppDispatch, useAppSelector } from "core/app/store";
import { setTask } from "core/app/store/task-slice";
import { useApiSlice } from "core/shared/main/useApiSlice";
import { useGetLsoTaskQuery } from "core/app/api/LSO";
import { ApiSliceEnum } from "core/app/api/enums";

import { useTaskId } from "../useTaskId";
import { useTaskInfo } from "../useTaskInfo";
import { useTeam } from "../../tasks-for-shift/api/useTeam";

export enum TaskExtraApiSliceEnum {
  eto = "eto",
  pspaWithEto = "pspa-with-eto",
}

export type TaskApiSlice = TaskExtraApiSliceEnum | ApiSliceEnum;

const mapDiscriminatorToSlice = new Map<TaskDiscriminatorEnum, TaskApiSlice>([
  [TaskDiscriminatorEnum.PSPA_TASK, ApiSliceEnum.pspa],
  [TaskDiscriminatorEnum.SLPI_TASK, ApiSliceEnum.slpi],
  [TaskDiscriminatorEnum.OOSSA_TASK, ApiSliceEnum.oossa],
  [TaskDiscriminatorEnum.ETO_PSPA_TASK, TaskExtraApiSliceEnum.eto],
]);

export function isITSM(task: ITask) {
  return !!task.request_id;
}

export function useEtoTask(taskId?: number | string | null) {
  const [stateTaskId] = useTaskId();
  if (!taskId) {
    taskId = stateTaskId;
  }
  return useGetEtoTaskQuery(taskId || skipToken);
}

export function useLsoTask(taskId?: number | string | null) {
  const [stateTaskId] = useTaskId();
  if (!taskId) {
    taskId = stateTaskId;
  }
  return useGetLsoTaskQuery(taskId || skipToken);
}

/**
 * Получает задачу с сервера по id.
 * Получение задачи может проходить в 1 или 2 запроса.
 * Если текущий участок не ПСПА получение в 1 запрос из точки специфичной для текущего участка "slpi-tasks" или "oossa-tasks".
 * Если ПСПА, сначала запрашивается задача из точки "pspa-with-eto-tasks".
 * Затем по полю discriminator определяется, является ли эта задача ЕТО или нет.
 * Делается запрос 2 к специфичной точке "eto-tasks" или "pspa-tasks".
 * Передаю пламенный привет, коллегам спроектировавшим так апишку на бэке ;)
 */
const useTask = (taskId?: number | string | null, moment?: TaskSnapMomentEnum | null) => {
  /**
   * Если id задачи не передан через аргумент, возьмём его из стейта
   */
  const [stateTaskId] = useTaskId();
  if (!taskId) {
    taskId = stateTaskId;
  }

  /**
   * Получение статуса задачи - для получения слепка задачи из расформированной бригады.
   */
  const [shiftNumber, date] = useTaskInfo();

  const team = useTeam();
  /**
   *query для запроса disband
   */
  const query = {
    date: team?.id ? date : undefined,
    moment: moment ?? undefined,
    shift_number: shiftNumber && team?.id ? shiftNumber : undefined,
    team_id: team?.id,
  };
  const queryString = qs.stringify(query);
  /**
   * Получаем срез апишки на основании текущего участка
   */
  let unitApiSlice: TaskApiSlice | null = useApiSlice();
  /**
   * Но если срез апишки === pspa его надо подменить на pspaWithEto
   * т.к. только из этой точки можно получить задачи типа pspa и eto
   * но не полные а только для определения является ли это задача pspa или eto
   */
  if (unitApiSlice === ApiSliceEnum.pspa) {
    unitApiSlice = TaskExtraApiSliceEnum.pspaWithEto;
  }

  /**
   * Получаем задачу с сервера
   */
  const result1 = useGetTaskQuery(
    taskId && unitApiSlice
      ? {
          taskId,
          slice: unitApiSlice,
          query:
            unitApiSlice !== TaskExtraApiSliceEnum.pspaWithEto && moment === TaskSnapMomentEnum.TEAM_DISBAND
              ? `snap?${queryString}`
              : "",
        }
      : skipToken
  );

  /**
   * По полю discriminator определим из какой точки надо получить полную задачу.
   * Нас интересует только eto и pspa
   */
  let taskApiSlice: TaskApiSlice | undefined;
  if (result1.data) {
    taskApiSlice = mapDiscriminatorToSlice.get(result1.data.discriminator);
  }

  /**
   * Если первый запрос делали из точки pspaWithEto, надо делать второй
   * Этот уже будет сделан или к точке ЕТО или к PSPA
   */
  const result2 = useGetTaskQuery(
    taskId && taskApiSlice && unitApiSlice === TaskExtraApiSliceEnum.pspaWithEto
      ? {
          taskId,
          slice: taskApiSlice,
          query: moment === TaskSnapMomentEnum.TEAM_DISBAND ? `snap?${queryString}` : "",
        }
      : skipToken
  );

  if (unitApiSlice !== TaskExtraApiSliceEnum.pspaWithEto) {
    return result1;
  }
  return result2;
};

/**
 * @deprecated Это плохой паттерн, не стоит хранить задачу в стейте, используй useTask()
 * @returns
 */
const useLocalTask = (): [ITask | null, (task: ITask) => void] => {
  const { task } = useAppSelector((state) => state.task);
  const dispatch = useAppDispatch();

  const set = useCallback(
    (newTask: ITask) => {
      dispatch(setTask(newTask));
    },
    [dispatch]
  );

  if (!task) {
    return [task, set];
  }

  return [task, set];
};

export { useTask, useLocalTask };
