import { useCallback } from "react";

import { useAppSelector, useAppDispatch } from "core/app/store";
import { setFullScreen } from "core/app/store/main-slice";

const useFullScreen = (): [boolean, (value: boolean) => void] => {
  const { fullScreen } = useAppSelector((state) => state.main);
  const dispatch = useAppDispatch();
  const set = useCallback(
    (value: boolean) => {
      dispatch(setFullScreen(value));
    },
    [dispatch]
  );

  return [fullScreen, set];
};

export { useFullScreen };
