import React from "react";
import clsx from "clsx";
import { Row, Col } from "antd";

import decorList from "./decorList";
import "./style.scss";

function LogoDecor() {
  return (
    <>
      {decorList.map((item) => (
        <Row key={item.id} className={clsx([item.class, "decorElement"])}>
          <Col sm={9} lg={14} xl={16} xxl={24}>
            <img src={item.name} alt={item.alt} />
          </Col>
        </Row>
      ))}
    </>
  );
}

export default LogoDecor;
