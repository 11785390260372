import { Row, Col } from "antd";
import { useEffect, useState } from "react";

import Text from "core/lib/Text/Text";
import { useNetworkState } from "core/shared/main/api/useNetworkState";
import { ITaskSignature } from "core/app/store/task-signature-slice";
import { useGetEmployeeSignatureQuery } from "core/app/api";
import { EtoTaskStepNameEnum, IEtoTask } from "core/app/api/interfaces";

import { EtoTaskSignatureSideEnum } from "../../../interfaces";

interface SignatureCompletedProps {
  side: EtoTaskSignatureSideEnum;
  task: IEtoTask;
  stepName: EtoTaskStepNameEnum;
  localSignature?: ITaskSignature;
}

function SignatureCompleted({ side, task, stepName, localSignature }: SignatureCompletedProps) {
  const { isOnline } = useNetworkState();
  const step = task.steps.find((s) => s.step_name === stepName);
  const [signatureSrc, setSignatureSrc] = useState<string | undefined>();
  const signatureId =
    side === EtoTaskSignatureSideEnum.SENIOR_ELECTRICIAN
      ? Number(step?.mechanic_signature_id)
      : Number(step?.driver_signature_id);
  const { data, isSuccess, isError } = useGetEmployeeSignatureQuery(signatureId, {
    skip: !signatureId,
  });

  useEffect(() => {
    if (!isOnline && localSignature) {
      setSignatureSrc(localSignature.url);
    } else if (data && isSuccess) {
      setSignatureSrc(data.signature);
    }
  }, [data, isError, isSuccess, isOnline, localSignature]);

  let sideName = "";
  if (side === EtoTaskSignatureSideEnum.SENIOR_ELECTRICIAN) {
    sideName = "выпускающий электромеханик";
  }
  if (side === EtoTaskSignatureSideEnum.MACHINIST) {
    sideName = "машинист";
  }
  return (
    <>
      <Row>
        <Col>
          <Text size="label1" transform="uppercase" boldness="semibold">
            {sideName}
          </Text>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={14} style={{ alignSelf: "center" }}>
          <Row>
            <Col>
              <Text transform="uppercase" size="label4" boldness="semibold">
                {data?.executor.position}
              </Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text size="label1" boldness="black" className="blue">
                {data?.executor.full_name}
              </Text>
            </Col>
          </Row>
        </Col>
        <Col span={10}>
          <img style={{ maxWidth: "100%", border: "1px solid #d9d9d9" }} src={signatureSrc} alt="" />
        </Col>
      </Row>
    </>
  );
}

export { SignatureCompleted };
