import { Badge, Radio, RadioGroupProps, RadioProps } from "antd";
import clsx from "clsx";
import { RadioButtonProps } from "antd/lib/radio/radioButton";
import "./styles.scss";

export type TRadioGroupOptionProps = RadioButtonProps & { label: string };

export type TRadioGroupProps<T extends TRadioGroupOptionProps = TRadioGroupOptionProps> = Omit<
  RadioProps,
  "children"
> & {
  uppercase?: boolean;
  items: T[];
  size?: RadioGroupProps["size"];
  showCount?: boolean;
  count?: number | ((value: T["value"]) => number);
  defaultValue?: RadioGroupProps["defaultValue"];
};

function RadioGroup<T extends TRadioGroupOptionProps = TRadioGroupOptionProps>({
  items,
  className,
  size = "large",
  uppercase = true,
  showCount = false,
  count,
  defaultValue,
  ...radioGroupProps
}: TRadioGroupProps<T>) {
  return (
    <Radio.Group
      className={clsx("radio-group", "w-100", className)}
      size={size}
      buttonStyle="solid"
      defaultValue={defaultValue}
      {...radioGroupProps}
    >
      {items.map(({ value, label, disabled, defaultChecked }) => {
        const countValue = typeof count === "function" ? count(value) : count;
        return (
          <Radio.Button
            className={clsx("radio-group__item", {
              uppercase,
            })}
            disabled={disabled}
            value={value}
            defaultChecked={defaultChecked}
            key={value}
          >
            {label}
            {showCount && <Badge style={{ position: "absolute" }} offset={[30, -30]} count={countValue} />}
          </Radio.Button>
        );
      })}
    </Radio.Group>
  );
}

export { RadioGroup };
