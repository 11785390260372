import { ReactElement } from "react";

import { TaskTypesIdsEnum } from "core/app/api/interfaces";
import { isGroup } from "core/shared/dashboard";

import { ICellProps } from "..";
import { Paragraph } from "../Paragraph";

export function Comment({ record }: ICellProps): ReactElement {
  if (isGroup(record)) {
    return <div />;
  }
  if (record.type === TaskTypesIdsEnum.MAINTENANCE) {
    return <Paragraph>{record.comment}</Paragraph>;
  }
  if (record.type === TaskTypesIdsEnum.OTHER) {
    return <Paragraph>{record.request_comment}</Paragraph>;
  }
  return <Paragraph>{record.note}</Paragraph>;
}
