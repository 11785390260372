import { Space } from "antd";

import { IEtoTask, EtoTaskStepNameEnum } from "core/app/api/interfaces";
import RadioGroupTabs from "core/shared/components/RadioGroupTabs";
import { useCurrentStep } from "core/widgets/AssistanceCarTaskModal/hooks/useCurrentStep";

import { useAssistanceCarMaintenanceContext } from "../../hooks/useAssistanceCarMaintenanceContext";

import { PreShiftCheck } from "./PreShiftCheck";
import { PerformingEto } from "./PerformingEto";
import { PostShiftCheck } from "./PostShiftCheck";
import Media from "./Media";
import { SignatureButtons } from "./SignatureButtons";

type TBodyProps = {
  task: IEtoTask;
};

function Body({ task }: TBodyProps) {
  const currentStep = useCurrentStep();
  const postShiftCheckDisabled = currentStep?.step_name !== EtoTaskStepNameEnum.POST_SHIFT_CHECK;
  const { errorsSteps, setSelectedStepTab } = useAssistanceCarMaintenanceContext();

  const maintenanceItems = [
    {
      label: "Приемка машины",
      key: EtoTaskStepNameEnum.PRE_SHIFT_CHECK,
      children: <PreShiftCheck task={task} />,
      forceRender: true,
    },
    {
      label: "Проведение ЕТО",
      key: EtoTaskStepNameEnum.PERFORMING_ETO,
      children: <PerformingEto task={task} />,
      forceRender: true,
    },
    {
      label: "Конец смены",
      key: EtoTaskStepNameEnum.POST_SHIFT_CHECK,
      disabled: postShiftCheckDisabled,
      children: <PostShiftCheck task={task} />,
      forceRender: currentStep?.step_name === EtoTaskStepNameEnum.POST_SHIFT_CHECK,
    },
  ];

  return (
    <Space className="w-100 mb-4" direction="vertical" size={16}>
      <RadioGroupTabs
        activeKey={currentStep?.step_name || EtoTaskStepNameEnum.PRE_SHIFT_CHECK}
        items={maintenanceItems}
        radioGroupProps={{
          showCount: true,
          count: (value) => {
            if (value in EtoTaskStepNameEnum) {
              return errorsSteps[value as keyof typeof EtoTaskStepNameEnum];
            }
            return 0;
          },
        }}
        onChange={(activeStep: string) => setSelectedStepTab(activeStep as EtoTaskStepNameEnum)}
      />
      <Media task={task} />
      <SignatureButtons task={task} />
    </Space>
  );
}

export default Body;
