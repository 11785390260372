import { ReactElement } from "react";
import clsx from "clsx";
import { Col, Row } from "antd";

import { ITask } from "core/app/api/interfaces";
import {
  AccumulatorElapsedTime,
  Customer,
  CustomerDepartment,
  Effort,
  RequestCreationDatetime,
  VolsLine,
  VolsObject,
  WorkType,
} from "core/widgets/TaskModals/shared/Fields";

type TVolsGeneralInfoProps = {
  task: ITask;
};

export function VolsGeneralInfo({ task }: TVolsGeneralInfoProps): ReactElement {
  return (
    <Row gutter={16} className={clsx("mb-1", "mt-1")}>
      <Col span={12}>
        <WorkType task={task} showDescription />
        <Customer task={task} />
        <CustomerDepartment label="Подразделение" task={task} />
        <VolsObject task={task} />
        <VolsLine task={task} />
      </Col>
      <Col span={12}>
        <RequestCreationDatetime task={task} />
        <Effort task={task} />
        <AccumulatorElapsedTime task={task} />
      </Col>
    </Row>
  );
}
