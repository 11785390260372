import { ReactElement } from "react";

import { useTask } from "core/shared/task/useTask";

import WarningModal from "./WarningModal";
import Content from "./Content";

import "../../styles.scss";
import "./styles.scss";

export const checklists = [
  {
    id: 3262694,
    check: {
      id: 6336,
      order_number: "1",
      title: "Контроль работы резервного питания",
      description:
        "Стресс тест. Убедиться, что считыватель продолжает работать, при отключении питания на шкаф коммутатора (питание на считыватель от отключенного от эл. сети БКМ присутствует). Работет:   __да__ /  __нет__",
      choices: [],
      is_required: false,
      equipment_type: {
        id: 1326,
        name: "H800",
        maintenance_quantity: 12,
        is_mounted: false,
        system: 44,
        system_name: "H800",
      },
      inside: false,
      place: {
        id: 3,
        name: "У устройства",
      },
      power: {
        id: 2,
        name: "Требуется питание",
      },
      operation_kind: null,
      operation_time: "00:02:00",
      photo_point: {
        id: 1,
        name: "",
      },
      photo_for: 1,
    },
    value: 0,
    comment: "",
    maintenance_execution_id: 506912,
  },
  {
    id: 3262695,
    check: {
      id: 6336,
      order_number: "1",
      title: "Контроль работы резервного питания",
      description:
        "Стресс тест. Убедиться, что считыватель продолжает работать, при отключении питания на шкаф коммутатора (питание на считыватель от отключенного от эл. сети БКМ присутствует). Работет:   __да__ /  __нет__",
      choices: [],
      is_required: false,
      equipment_type: {
        id: 1326,
        name: "H800",
        maintenance_quantity: 12,
        is_mounted: false,
        system: 44,
        system_name: "H800",
      },
      inside: true,
      place: {
        id: 3,
        name: "У устройства",
      },
      power: {
        id: 2,
        name: "Без питания",
      },
      operation_kind: null,
      operation_time: "00:02:00",
      photo_point: {
        id: 1,
        name: "",
      },
      photo_for: 1,
    },
    value: 0,
    comment: "",
    maintenance_execution_id: 506912,
  },
  {
    id: 3262695,
    check: {
      id: 6336,
      order_number: "1",
      title: "Контроль работы резервного питания",
      description:
        "Стресс тест. Убедиться, что считыватель продолжает работать, при отключении питания на шкаф коммутатора (питание на считыватель от отключенного от эл. сети БКМ присутствует). Работет:   __да__ /  __нет__",
      choices: [],
      is_required: false,
      equipment_type: {
        id: 1326,
        name: "H800",
        maintenance_quantity: 12,
        is_mounted: false,
        system: 44,
        system_name: "H800",
      },
      inside: true,
      place: {
        id: 3,
        name: "У устройства",
      },
      power: {
        id: 1,
        name: "Требуется питание",
      },
      operation_kind: null,
      operation_time: "00:02:00",
      photo_point: {
        id: 1,
        name: "",
      },
      photo_for: 1,
    },
    value: 0,
    comment: "",
    maintenance_execution_id: 506912,
  },
];

/** Обертка:
 * либо карточка технического обслуживания для навесного оборудования ПСПА,
 * либо предупреждающее сообщение про удаление карточки. */
function MountedTaskForm(): ReactElement {
  const task = useTask();

  return (
    <>
      {!!task && <Content />}
      <WarningModal />
    </>
  );
}

export default MountedTaskForm;
