import { Button, Modal, Form, Row, Col, Space, Select } from "antd";
import { Dayjs } from "dayjs";

import dayjs, { apiDateFormat, displayDateFormat } from "core/shared/tools/dayjs";
import Text from "core/lib/Text/Text";
import DatePicker from "core/widgets/DatePicker";
import { useLazyGetPrintTasksFormQuery } from "core/app/api";
import { useUnitId } from "core/shared/unit/useUnitId";
import { useUnit } from "core/shared/unit/useUnit";
import { usePrintTaskReportModalOpen } from "core/shared/tasks/usePrintTaskReportModalOpen";
import { PrintTaskReportModalStatesEnum } from "core/app/store/temp-slice";
import { IPrintTasksFormProps } from "core/app/api/interfaces";
import { useUnits } from "core/shared/unit/useUnits";
import { IUnit } from "core/shared/unit/types";

const { RangePicker } = DatePicker;

interface IFormValues {
  dateRange: [Dayjs, Dayjs];
  units: number[];
}

function PrintTaskReportModal() {
  const [form] = Form.useForm();
  const [trigger, result] = useLazyGetPrintTasksFormQuery();
  const [unitId] = useUnitId();
  const unit = useUnit();
  const [printTaskReportModalState, setPrintTaskReportModalState] = usePrintTaskReportModalOpen();
  const units = useUnits();

  const handleCancel = () => {
    setPrintTaskReportModalState(PrintTaskReportModalStatesEnum.Closed);
  };

  const handleFinish = (values: IFormValues) => {
    const [dateFrom, dateTo] = values.dateRange;

    let unitsIds: number[] = [];
    if (values.units) {
      unitsIds = values.units;
    } else if (unitId) {
      unitsIds = [unitId];
    }
    const queryParams: IPrintTasksFormProps = {
      unitsNames: [""],
      queryProps: {
        date_from: dateFrom.format(apiDateFormat),
        date_to: dateTo.format(apiDateFormat),
        tasks_url: `${window.location.origin}/tasks-for-shift`,
      },
    };
    if (unitsIds.length) {
      queryParams.unitsNames = units?.filter((item) => unitsIds.includes(item.id)).map((item) => item.name);
      queryParams.queryProps.unit = unitsIds;
    }
    trigger(queryParams);
  };

  return (
    <Modal
      width={375}
      open={printTaskReportModalState !== PrintTaskReportModalStatesEnum.Closed}
      onCancel={handleCancel}
      footer={false}
    >
      <div className="mb-3">
        <Text boldness="black" size="h5" className="uppercase">
          Скачать задачи
        </Text>
      </div>
      <Form
        form={form}
        layout="vertical"
        initialValues={{ dateRange: [dayjs(), dayjs()], units: [unit?.id] }}
        onFinish={handleFinish}
      >
        {printTaskReportModalState === PrintTaskReportModalStatesEnum.AnyUnit && (
          <Form.Item label="УЧАСТОК" colon={false} name="units">
            <Select value={unitId} style={{ minWidth: "280px" }} mode="multiple">
              {units
                ?.filter((item: IUnit) => item.isPSPA())
                .map((item: { id: number; name: string }) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item required label="ДИАПАЗОН ДАТ" name="dateRange">
          <RangePicker style={{ width: "100%" }} format={displayDateFormat} allowClear={false} />
        </Form.Item>
        <Row justify="end" className="mt-4">
          <Col>
            <Space size="large">
              <Button type="default" onClick={handleCancel}>
                ОТМЕНИТЬ
              </Button>
              <Button type="primary" htmlType="submit" loading={result.isLoading}>
                СКАЧАТЬ
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export { PrintTaskReportModal };
