/** @jsxImportSource @emotion/react */
import { ReactElement, ReactNode } from "react";
import { CSSObject } from "@emotion/react";

interface PageTitleContainerProps {
  children: ReactNode;
}

const styles: CSSObject = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginTop: 24,
  marginBottom: 16,
};

function PageTitleContainer({ children }: PageTitleContainerProps): ReactElement {
  return <div css={styles}>{children}</div>;
}

export { PageTitleContainer };
