import { ReactElement } from "react";

interface TextBadgeProps {
  text: string;
  color?: string;
  bgColor?: string;
  style?: object;
}

export default function TextBadge({ text, color, bgColor, style }: TextBadgeProps): ReactElement {
  const effectiveStyle = {
    borderRadius: "6px",
    borderWidth: "1px",
    borderStyle: "solid",
    color,
    borderColor: color || "black",
    backgroundColor: bgColor || "antiquewhite",
    padding: "2px 8px 2px 8px",
    ...style,
  };
  return <span style={effectiveStyle}>{text}</span>;
}
