import { Space } from "antd";

import Text from "core/lib/Text/Text";
import LegendBullet from "core/widgets/LegendBullet";

function TaskLegend() {
  return (
    <Space direction="horizontal" size={24}>
      <Space size={0}>
        <LegendBullet color="#7ED321" />
        <Text size="label3" boldness="semibold">
          План
        </Text>
      </Space>
      <Space size={0}>
        <LegendBullet color="#FA9C00" />
        <Text size="label3" boldness="semibold">
          Вне плана
        </Text>
      </Space>
    </Space>
  );
}

export default TaskLegend;
