import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
}

function Location({ task }: FieldProps): ReactElement {
  return <Field label="Местоположение (участок)" value={task.location?.name} />;
}

export { Location };
