import { Space } from "antd";

import ErrorMessage from "core/widgets/CreateTaskModal/ErrorMessage";
import TaskTitle from "core/widgets/TaskModals/shared/TaskTitle";
import StateButtons from "core/widgets/TaskModals/shared/StateButtons/FormControlled";

import { useLsoMaintenanceContext } from "../hooks";

import GeneralInfo from "./GeneralInfo";

function Header() {
  const { showErrorMessage, task, setShowErrorMessage } = useLsoMaintenanceContext();

  const handleValidationFailed = () => {
    setShowErrorMessage(true);
  };

  if (!task) {
    return null;
  }

  return (
    <Space className="w-100" direction="vertical" size="small">
      <TaskTitle task={task} icon />
      {showErrorMessage && (
        <div className="mb-3">
          <ErrorMessage message="Заполните все чек-листы и обязательные поля" description="" />
        </div>
      )}
      <StateButtons task={task} hideClearButton onValidationFailed={handleValidationFailed} />
      <GeneralInfo />
    </Space>
  );
}

export default Header;
