import { useUnit } from "core/shared/unit/useUnit";

import { Pspa } from "./Pspa";
import { Slpi } from "./Slpi";
import { Oossa } from "./Oossa";
import { Lso } from "./Lso";
import { Lku } from "./Lku";
import { Vols } from "./Vols";

export function Other() {
  const unit = useUnit();
  if (unit?.isPSPA()) {
    return <Pspa />;
  }
  if (unit?.isSLPI()) {
    return <Slpi />;
  }
  if (unit?.isOOSSA()) {
    return <Oossa />;
  }
  if (unit?.isLSO()) {
    return <Lso />;
  }
  if (unit?.isLKU()) {
    return <Lku />;
  }
  if (unit?.isVOLS()) {
    return <Vols />;
  }
  return <div />;
}
