import React, { ReactElement } from "react";
import { Row, Col } from "antd";

import Text from "core/lib/Text/Text";
import { useGetEquipmentTypeQuery } from "core/app/api";

interface EquipmentTypeProps {
  equipmentTypeId: string | number;
}

function EquipmentType({ equipmentTypeId }: EquipmentTypeProps): ReactElement {
  const { data } = useGetEquipmentTypeQuery(equipmentTypeId);

  return (
    <Row>
      <Col>
        <Text size="label2">Тип оборудования:&nbsp;</Text>
      </Col>
      <Col>
        <Text size="label2" boldness="black">
          {data?.name}
        </Text>
      </Col>
    </Row>
  );
}

export default EquipmentType;
