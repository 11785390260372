import { UnitServiceCodesEnum } from "../types";
import { useUnits } from "../useUnits";

/**
 * На основе доступных пользователю учасков возвращает массив доступных услуг(коды).
 * @returns serviceCodes
 */
export function useUserServiceCodes() {
  const units = useUnits();
  const serviceCodes = units?.reduce<UnitServiceCodesEnum[]>((acc, item) => {
    if (acc.includes(item.service.code)) {
      return acc;
    }
    return [...acc, item.service.code];
  }, []);
  return serviceCodes;
}
