import { ReactElement } from "react";
import { Row, Col, Card } from "antd";

import { useShiftContext, ShiftContext } from "core/shared/dashboard/context/shiftContext";

import LeftColShiftsStatistic from "./LeftCol";
import RightColShiftsStatistic from "./RightCol";

/** Статистика смен в меню "Статистика" по любому участку. */
export default function ShiftsStatistic(): ReactElement {
  const shiftContextValue = useShiftContext();

  return (
    <ShiftContext.Provider value={shiftContextValue}>
      <Card className="shifts">
        <Row gutter={24}>
          <Col span={4}>
            <LeftColShiftsStatistic />
          </Col>
          <Col span={20}>
            <RightColShiftsStatistic />
          </Col>
        </Row>
      </Card>
    </ShiftContext.Provider>
  );
}
