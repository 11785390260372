import { Col, Form } from "antd";

import RequestCategoryField from "core/widgets/CreateTaskModal/Fields/RequestCategoryField";
import { useUnit } from "core/shared/unit/useUnit";
import EffortField from "core/widgets/CreateTaskModal/Fields/EffortField";
import DescriptionField from "core/widgets/CreateTaskModal/Fields/DescriptionField";
import AttachmentsField from "core/widgets/CreateTaskModal/Fields/AttachmentsField";
import ShiftSelector from "core/widgets/CreateTaskModal/Fields/ShiftSelector";
import { EffortTypeEnum } from "core/models/enums";

import TransportField from "../../Other/Pspa/Fields/TransportField";
import CreatioEquipmentField from "../CreatioEquipmentField";
import EquipmentTypeField from "../../Other/Pspa/Fields/EquipmentTypeField";
import EquipmentField from "../../Other/Pspa/Fields/EquipmentField";
import LocationField from "../../Other/Pspa/Fields/LocationField";

function RecovertOrServiceRequestContent() {
  const unit = useUnit();
  const form = Form.useFormInstance();
  const isMounted = Form.useWatch<boolean>("isMounted", form);
  return (
    <>
      <Col span={12}>
        <RequestCategoryField />
      </Col>
      {isMounted && (
        <Col span={12}>
          <TransportField required />
        </Col>
      )}
      <Col span={12}>
        <CreatioEquipmentField />
      </Col>
      <Col span={12}>
        <EquipmentTypeField required />
      </Col>
      {!isMounted && (
        <Col span={12}>
          <EquipmentField required />
        </Col>
      )}
      <Col span={12}>
        <LocationField required />
      </Col>
      <Col span={12}>
        <EffortField effortType={unit?.isSLPI() ? EffortTypeEnum.MAN_HOURS : EffortTypeEnum.HOURS} required />
      </Col>
      <Col span={24}>
        <DescriptionField />
      </Col>
      <Col span={24}>
        <AttachmentsField />
      </Col>
      <Col className="mb-1 mt-1" span={24}>
        <ShiftSelector />
      </Col>
    </>
  );
}

export default RecovertOrServiceRequestContent;
