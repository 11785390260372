import { apiSlice, extractResults } from "core/app/api/api";
import { IResponseBody, ITask, TSendOtherPspaTaskBody } from "core/app/api/interfaces";

import { ApiSliceEnum, CodeGroupEnum } from "../enums";

import {
  ISgpsYearPlanMounted,
  ISgpsYearPlanStationary,
  ISgpsMonthPlanMounted,
  ISgpsMonthPlanStationary,
  IUpdateSgpsPlansByMonthPayload,
  IStatisticYear,
  IApproveSgpsMonthPlansPayload,
  ICreateSgpsMonthPlansCyclesPayload,
  TMakeSgpsMonthPlanAutoDistributionPayload,
  IMaintenanceTypeCycle,
  IUpdateSgpsMonthPlanMaintenanceTypePayload,
  IGroupTasksSgps,
} from "./interfaces";

const sgpsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSgpsListSdo: builder.query<{ id: number; name: string }[], string>({
      query: (params) => `self-moving-types/?${params}`,
      transformResponse: extractResults,
    }),
    getSgpsAnnualStatistics: builder.query<IStatisticYear, string>({
      query: (queryString) => `pspa-kf-month-plans/stat-and-approved-plans/?${queryString}`,
      providesTags: ["pspa-kf-month-stat"],
    }),
    getSgpsMonthPlans: builder.query<ISgpsYearPlanMounted[] | ISgpsYearPlanStationary[], string>({
      query: (queryString) => `pspa-kf-year-plans/?${queryString}`,
      providesTags: ["pspa-kf-year-plans"],
    }),
    getSgpsPlansByMonth: builder.query<ISgpsMonthPlanMounted[] | ISgpsMonthPlanStationary[], string>({
      query: (queryString) => `pspa-kf-month-plans/?${queryString}`,
      providesTags: ["pspa-kf-month-plans"],
    }),
    updateSgpsPlansByMonth: builder.mutation<null, IUpdateSgpsPlansByMonthPayload>({
      query: (payload) => {
        const { id, ...rest } = payload;
        return {
          url: `pspa-kf-month-plans/${id}/`,
          method: "PATCH",
          body: rest,
        };
      },
      invalidatesTags: ["pspa-kf-month-plans", "pspa-kf-month-stat"],
    }),
    approveSgpsMonthPlans: builder.mutation<null, IApproveSgpsMonthPlansPayload>({
      query: (payload) => ({
        url: `pspa-kf-month-plans/approve/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["pspa-kf-month-plans", "pspa-kf-month-stat"],
    }),
    undoApproveSgpsMonthPlans: builder.mutation<null, IApproveSgpsMonthPlansPayload>({
      query: (payload) => ({
        url: `pspa-kf-month-plans/undo-approve/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["pspa-kf-month-plans", "pspa-kf-month-stat"],
    }),
    createSgpsMonthPlansCycles: builder.mutation<any, ICreateSgpsMonthPlansCyclesPayload>({
      query: (payload) => ({
        url: `pspa-kf-year-plans/auto-cycle-distribution/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["pspa-kf-month-plans", "pspa-kf-year-plans", "pspa-kf-month-stat"],
    }),
    makeSgpsMonthPlanAutoDistribution: builder.mutation<void, TMakeSgpsMonthPlanAutoDistributionPayload>({
      query: (payload) => ({
        url: `/pspa-kf-month-plans/auto-maintenance-days-distribution/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["pspa-kf-month-plans", "pspa-kf-month-stat", "pspa-kf-year-plans"],
    }),
    updatePspaKfTask: builder.mutation({
      query: (task) => ({
        url: `pspa-kf-tasks/${task.id}/`,
        method: "PATCH",
        body: task,
      }),
      async onQueryStarted({ queryString, ...taskUpdate }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData("getOpenTasks", queryString, (cachedTasks) => {
            const cachedTaskToUpdate = cachedTasks.results.find((cachedTask: ITask) => cachedTask.id === taskUpdate.id);
            if (cachedTaskToUpdate) {
              Object.assign(cachedTaskToUpdate, taskUpdate);
            }
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: "task", id: arg.id },
        "tasks",
        "shifttask",
        "tasks-filtered",
        "kanban-board",
        "month-plan",
        "tasks/statistics-by-days",
        "dashboard/shift-statistic/tasks",
        "dashboard/shift-statistic/tasks/by-equipment-types",
        "dashboard/shift-statistic/tasks/by-objects/slpi",
      ],
    }),
    createPspaTask: builder.mutation<
      any,
      { pspaSlice: ApiSliceEnum.pspa | ApiSliceEnum.pspaKf; body: TSendOtherPspaTaskBody }
    >({
      query: ({ body, pspaSlice }) => ({
        url: `${pspaSlice}-tasks/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["tasks", "open-tasks", "shifttask", "tasks-filtered", "kanban-board"],
    }),
    getMaintenanceTypesCycle: builder.query<IMaintenanceTypeCycle[], string>({
      query: (queryString) => `maintenance-types/maintenance-cycle/?${queryString}`,
      providesTags: ["maintenance-types/maintenance-cycle"],
    }),
    updateSgpsMonthPlanMaintenanceType: builder.mutation<void, IUpdateSgpsMonthPlanMaintenanceTypePayload>({
      query: (payload) => {
        const { id, ...rest } = payload;
        return {
          url: `pspa-kf-month-plans/${id}/set-maintenance-type/`,
          method: "POST",
          body: rest,
        };
      },
      invalidatesTags: [
        "pspa-kf-month-plans",
        "pspa-kf-month-stat",
        "pspa-kf-year-plans",
        "pspa-kf-scheduled-task-count",
      ],
    }),

    getGroupTasksSGPS: builder.query<IGroupTasksSgps[], { code: CodeGroupEnum; queryString?: string }>({
      query: ({ code, queryString }) => `/pspa-kf-tasks/group-tasks/${code}/?${queryString}`,
      providesTags: ["sgps-group-tasks"],
    }),
  }),
});

export const {
  useGetGroupTasksSGPSQuery,
  useGetSgpsListSdoQuery,
  useGetSgpsMonthPlansQuery,
  useGetSgpsPlansByMonthQuery,
  useUpdateSgpsPlansByMonthMutation,
  useGetSgpsAnnualStatisticsQuery,
  useApproveSgpsMonthPlansMutation,
  useCreateSgpsMonthPlansCyclesMutation,
  useUndoApproveSgpsMonthPlansMutation,
  useMakeSgpsMonthPlanAutoDistributionMutation,
  useUpdatePspaKfTaskMutation,
  useGetMaintenanceTypesCycleQuery,
  useUpdateSgpsMonthPlanMaintenanceTypeMutation,
  useCreatePspaTaskMutation,
} = sgpsApiSlice;
