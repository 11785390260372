import { Tag } from "antd";

import { SignatureSideEnum } from "core/app/api/interfaces";

import { ICellRendererProps } from "../../interfaces";

function SignatureTypeCellRenderer({ record: { side } }: ICellRendererProps) {
  const color = side === SignatureSideEnum.Customer ? "orange" : "green";
  const text = side === SignatureSideEnum.Customer ? "ЗАКАЗЧИК" : "ИСПОЛНИТЕЛЬ";

  return <Tag color={color}>{text}</Tag>;
}

export default SignatureTypeCellRenderer;
