import { ReactElement } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { ITeam, ITeamTasks } from "core/app/api/interfaces";

export function getTeamName(team: ITeam | ITeamTasks): string {
  const { team_lead_short_name: teamLead, team_number: teamNumber } = team;
  if (teamLead) {
    return `№${teamNumber} ${teamLead}`;
  }
  return `Бригада №${teamNumber}`;
}

interface TeamNameProps {
  team: ITeam | ITeamTasks;
}
function TeamName({ team }: TeamNameProps): ReactElement {
  return (
    <div>
      {getTeamName(team)} {team.disbanded && <ExclamationCircleOutlined />}
    </div>
  );
}

export { TeamName };
