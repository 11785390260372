import { Dropdown, Button, MenuProps } from "antd";

import icMore from "core/images/ic-more-vert.svg";
import { useAppDispatch } from "core/app/store";
import { deleteSignature } from "core/app/store/task-signature-slice";
import { useSendSignature } from "core/shared/offline-mode/useSendSignature";

import { ICellRendererProps } from "../../interfaces";

export function ActionsCellRenderer({ record }: ICellRendererProps) {
  const dispatch = useAppDispatch();
  const sendSignature = useSendSignature();
  const menu: MenuProps = {
    items: [
      {
        label: "Удалить",
        key: "delete",
        onClick: () => {
          dispatch(
            deleteSignature({
              id: record.id,
            })
          );
        },
      },
      {
        label: "Отправить",
        key: "send",
        onClick: () => {
          sendSignature(record);
        },
      },
    ],
  };

  return (
    <Dropdown menu={menu} placement="bottomRight" trigger={["click"]}>
      <Button type="dashed" shape="circle" icon={<img className="ic-s" src={icMore} alt="Действия" />} />
    </Dropdown>
  );
}
