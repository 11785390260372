import { ReactElement } from "react";
import { Row, Col } from "antd";

import Text from "core/lib/Text/Text";
import { IDashboardShiftStatisticTasksByType, TaskTypesIdsEnum } from "core/app/api/interfaces";

import ProgressBar from "./ProgressBar";

interface ISystemStatRowProps {
  isMounted?: boolean;
  name: string | ReactElement;
  data?: IDashboardShiftStatisticTasksByType[];
  variant?: "slpi" | "pspa";
}

function SystemStatRow({ name, data, isMounted, variant = "pspa" }: ISystemStatRowProps): ReactElement {
  const color = isMounted ? "#32C5FF" : "#7ED321";
  let empty = true;
  data?.forEach((item) => {
    if (item.closed_sheduled_tasks || item.closed_unsheduled_tasks || item.sheduled_tasks || item.unsheduled_tasks) {
      empty = false;
    }
  });
  if (empty) {
    return <div />;
  }
  return (
    <Row gutter={24} className="mt-1">
      {!isMounted && (
        <>
          <Col span={variant === "pspa" ? 3 : 5} style={{ alignSelf: "center", lineHeight: 1 }}>
            <Text boldness="semibold" size="label3">
              {name || "НЕ УКАЗАНО"}
            </Text>
          </Col>
          <Col span={variant === "pspa" ? 21 : 19}>
            <Row>
              <Col span={8}>
                <ProgressBar data={data?.find((item) => item.name === TaskTypesIdsEnum.RECOVERY)} strokeColor={color} />
              </Col>
              <Col span={8}>
                <ProgressBar
                  data={data?.find((item) => item.name === TaskTypesIdsEnum.SERVICE_REQUEST)}
                  strokeColor={color}
                />
              </Col>
              <Col span={8}>
                <ProgressBar
                  data={data?.find((item) => item.name === TaskTypesIdsEnum.MAINTENANCE)}
                  strokeColor={color}
                />
              </Col>
            </Row>
          </Col>
        </>
      )}
      {isMounted && (
        <>
          <Col span={4} style={{ alignSelf: "center", lineHeight: 1 }}>
            <Text boldness="semibold" size="label3">
              {name || "НЕ УКАЗАНО"}
            </Text>
          </Col>

          <Col span={10}>
            <ProgressBar data={data?.find((item) => item.name === TaskTypesIdsEnum.RECOVERY)} strokeColor={color} />
          </Col>
          <Col span={10}>
            <ProgressBar
              data={data?.find((item) => item.name === TaskTypesIdsEnum.SERVICE_REQUEST)}
              strokeColor={color}
            />
          </Col>
        </>
      )}
    </Row>
  );
}

export default SystemStatRow;
