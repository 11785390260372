import { Divider, Space, Form, Input } from "antd";

import { IEtoTask, EtoTaskStepNameEnum } from "core/app/api/interfaces";
import Text from "core/lib/Text/Text";
import { useFieldsDisabled } from "core/widgets/AssistanceCarTaskModal/hooks/useDisabled";

import { CheckListGroup } from "../CheckListGroup";

import StateFields from "./StateFields";

type Props = {
  task: IEtoTask;
};

export function PostShiftCheck({ task }: Props) {
  const disabled = useFieldsDisabled();
  const engineHours = Form.useWatch(["transport", "engine_hours"]);
  const required = engineHours <= (task.transport.engine_hours || 0);

  return (
    <Space className="w-100" direction="vertical" size={32}>
      <CheckListGroup task={task} stepName={EtoTaskStepNameEnum.POST_SHIFT_CHECK} />
      <Divider style={{ margin: "8px 0" }} />
      <StateFields task={task} />
      <Form.Item
        name="closing_note"
        label={
          <Text size="label4" transform="uppercase">
            Общий комментарий
          </Text>
        }
        rules={[
          {
            validator: (_, value) => {
              if (!value && required) {
                return Promise.reject(new Error("Требуется указать причину, почему моточасы не увеличились"));
              }
              return Promise.resolve();
            },
          },
        ]}
        required={required}
      >
        <Input.TextArea disabled={disabled} rows={4} />
      </Form.Item>
    </Space>
  );
}
