export const permissionGroups = {
  menu: {
    key: "menu",
    description: "Элементы меню",
    permissions: {
      CAN_SEE: "Может видеть меню",
    },
    children: {
      shift_tasks: {
        key: "menu/shift_tasks",
        description: "Сменное задание",
        permissions: {
          CAN_SEE: 'Может видеть панель "Сменное задание"',
        },
        children: {
          teams: {
            key: "menu/shift_tasks/teams",
            description: "Формирование бригад",
            permissions: {
              CAN_SEE: 'Может видеть пункт меню "Формирование бригад"',
            },
          },
          tasks: {
            key: "menu/shift_tasks/tasks",
            description: "Формирование наряд задания",
            permissions: {
              CAN_SEE: 'Может видеть пункт меню "Формирование наряд задания"',
            },
          },
          tasks_for_teams: {
            key: "menu/shift_tasks/tasks_for_teams",
            description: "Распределение задач по бригадам",
            permissions: {
              CAN_SEE: 'Может видеть пункт меню "Распределение задач по бригадам"',
            },
          },
          tasks_for_shifts: {
            key: "menu/shift_tasks/tasks_for_shifts",
            description: "Задачи на смену",
            permissions: {
              CAN_SEE: 'Может видеть пункт меню "Задачи на смену"',
              CAN_SEE_IN_OFFLINE_MODE: 'Может видеть пункт меню "Задачи на смену" в режиме OFFLINE',
            },
          },
        },
      },

      planning: {
        key: "menu/planning",
        description: "Планирование ТО/Персонала",
        permissions: {
          CAN_SEE: 'Может видеть панель "Планирование ТО/Персонала"',
        },
        children: {
          pspa_maintenance: {
            key: "menu/planning/pspa_maintenance",
            description: "Техническое обслуживание ПСПА",
            permissions: {
              CAN_SEE: 'Может видеть пункт меню "Техническое обслуживание ПСПА"',
            },
          },
          slpi_maintenance: {
            key: "menu/planning/slpi_maintenance",
            description: "Техническое обслуживание СЛПИ",
            permissions: {
              CAN_SEE: 'Может видеть пункт меню "Техническое обслуживание СЛПИ"',
            },
          },
          oossa_maintenance: {
            key: "menu/planning/oossa_maintenance",
            description: "Техническое обслуживание ООССА",
            permissions: {
              CAN_SEE: 'Может видеть пункт меню "Техническое обслуживание ООССА"',
            },
          },
          pss_maintenance: {
            key: "menu/planning/pss_maintenance",
            description: "Техническое обслуживание ПСС",
            permissions: {
              CAN_SEE: 'Может видеть пункт меню "Техническое обслуживание ПСС"',
            },
          },
          sgps_maintenance: {
            key: "menu/planning/sgps_maintenance",
            description: "Техническое обслуживание СГПС",
            permissions: {
              CAN_SEE: 'Может видеть пункт меню "Техническое обслуживание СГПС"',
            },
          },
          lku_maintenance: {
            key: "menu/planning/lku_maintenance",
            description: "Техническое обслуживание ЛКУ",
            permissions: {
              CAN_SEE: 'Может видеть пункт меню "Техническое обслуживание ЛКУ"',
            },
          },
          employee_schedule: {
            key: "menu/planning/employee_schedule",
            description: "График работы персонала",
            permissions: {
              CAN_SEE: 'Может видеть пункт меню "График работы персонала"',
            },
          },
        },
      },

      reports: {
        key: "menu/reports",
        description: "Отчетность",
        permissions: {
          CAN_SEE: 'Может видеть панель "Отчетность"',
        },
        children: {
          statistics: {
            key: "menu/reports/statistics",
            description: "Статистика",
            permissions: {
              CAN_SEE: 'Может видеть пункт меню "Статистика"',
            },
          },
          team_rating: {
            key: "menu/reports/team_rating",
            description: "Рейтинг бригад",
            permissions: {
              CAN_SEE: 'Может видеть пункт меню "Рейтинг бригад"',
            },
          },
          opin_tasks_report: {
            key: "menu/reports/opin_tasks_report",
            description: "Выгрузка по заявкам для ОПиН",
            permissions: {
              CAN_SEE: 'Может видеть пункт меню "Выгрузка по заявкам для ОПиН"',
            },
          },
          equipments: {
            key: "menu/reports/equipments",
            description: "Карточка оборудования",
            permissions: {
              CAN_SEE: 'Может видеть пункт меню "Карточка оборудования"',
            },
          },
          daily_report: {
            key: "menu/reports/daily_report",
            description: "Отчет для проведения планерок",
            permissions: {
              CAN_SEE: 'Может видеть пункт меню "Отчет для проведения планерок"',
            },
          },
        },
      },
      reference: {
        key: "menu/reference",
        description: "Справка",
        permissions: {
          CAN_SEE: 'Может видеть панель "Справка"',
        },
        children: {
          video_instructions: {
            key: "menu/reference/video_instructions",
            description: "Видеоинструкции",
            permissions: {
              CAN_SEE: 'Может видеть пункт меню "Видеоинструкции"',
            },
          },
        },
      },
      dictionary_management: {
        key: "menu/dictionary_management",
        description: "Управление справочниками",
        permissions: {
          CAN_SEE: 'Может видеть панель "Управление справочниками"',
        },
        children: {
          dictionaries: {
            key: "menu/dictionary_management/dictionaries",
            description: "Справочники",
            permissions: {
              CAN_SEE: 'Может видеть пункт меню "Справочники"',
            },
          },
        },
      },
    },
  },

  pspa_maintenance: {
    key: "pspa_maintenance",
    description: "Техническое обслуживание ПСПА",
    permissions: {
      CAN_SEE: 'Может просматривать страницу "Техническое обслуживание ПСПА"',
    },
    children: {
      year_tab: {
        key: "pspa_maintenance/year_tab",
        description: 'Вкладка "Годовое ТО"',
        permissions: {
          CAN_SEE: 'Может просматривать вкладку "Годовое ТО"',
        },
      },
      month_stationary_tab: {
        key: "pspa_maintenance/month_stationary_tab",
        description: 'Вкладка "Месячное ТО стационарное"',
        permissions: {
          CAN_SEE: 'Может просматривать вкладку "Месячное ТО стационарное"',
        },
      },
      month_mounted_tab: {
        key: "pspa_maintenance/month_mounted_tab",
        description: 'Вкладка "Месячное ТО навесное"',
        permissions: {
          CAN_SEE: 'Может просматривать вкладку "Месячное ТО навесное"',
        },
      },
      month_mounted_approve: {
        key: "pspa_maintenance/month_mounted_approve",
        description: "Утверждение графика ТО",
        permissions: {
          UNIT_LEAD: "Начальник участка для утверждения планов",
          WORKSHOP_LEAD: "Начальник цеха для утверждения планов",
          CUSTOMER_REPRESENTATIVE: "Заказчик для утверждения планов",
        },
      },
    },
  },

  slpi_maintenance: {
    key: "slpi_maintenance",
    description: "Техническое обслуживание СЛПИ",
    permissions: {
      CAN_SEE: 'Может просматривать страницу "Техническое обслуживание СЛПИ"',
    },
    children: {
      year_tab: {
        key: "slpi_maintenance/year_tab",
        description: 'Вкладка "Годовое ТО"',
        permissions: {
          CAN_SEE: 'Может просматривать вкладку "Годовое ТО"',
        },
      },
      month_stationary_tab: {
        key: "slpi_maintenance/month_stationary_tab",
        description: 'Вкладка "Месячное ТО стационарное"',
        permissions: {
          CAN_SEE: 'Может просматривать вкладку "Месячное ТО стационарное"',
        },
      },
      month_mounted_tab: {
        key: "slpi_maintenance/month_mounted_tab",
        description: 'Вкладка "Месячное ТО навесное"',
        permissions: {
          CAN_SEE: 'Может просматривать вкладку "Месячное ТО навесное"',
        },
      },
    },
  },
  pss_maintenance: {
    key: "pss_maintenance",
    description: "Техническое обслуживание СЛПИ",
    permissions: {
      CAN_SEE: 'Может просматривать страницу "Техническое обслуживание СЛПИ"',
    },
    children: {
      year_tab: {
        key: "pss_maintenance/year_tab",
        description: 'Вкладка "Годовое ТО"',
        permissions: {
          CAN_SEE: 'Может просматривать вкладку "Годовое ТО"',
        },
      },
      month_stationary_tab: {
        key: "pss_maintenance/month_stationary_tab",
        description: 'Вкладка "Месячное ТО стационарное"',
        permissions: {
          CAN_SEE: 'Может просматривать вкладку "Месячное ТО стационарное"',
        },
      },
      month_mounted_tab: {
        key: "pss_maintenance/month_mounted_tab",
        description: 'Вкладка "Месячное ТО навесное"',
        permissions: {
          CAN_SEE: 'Может просматривать вкладку "Месячное ТО навесное"',
        },
      },
    },
  },

  oossa_maintenance: {
    key: "oossa_maintenance",
    description: "Техническое обслуживание ООССА",
    permissions: {
      CAN_SEE: 'Может просматривать страницу "Техническое обслуживание ООССА"',
    },
    children: {
      year_tab: {
        key: "oossa_maintenance/year_tab",
        description: 'Вкладка "Годовое ТО"',
        permissions: {
          CAN_SEE: 'Может просматривать вкладку "Годовое ТО"',
        },
      },
      month_stationary_tab: {
        key: "oossa_maintenance/month_stationary_tab",
        description: 'Вкладка "Месячное ТО стационарное"',
        permissions: {
          CAN_SEE: 'Может просматривать вкладку "Месячное ТО стационарное"',
        },
      },
      month_mounted_tab: {
        key: "oossa_maintenance/month_mounted_tab",
        description: 'Вкладка "Месячное ТО навесное"',
        permissions: {
          CAN_SEE: 'Может просматривать вкладку "Месячное ТО навесное"',
        },
      },
    },
  },

  tasks: {
    key: "tasks",
    description: "Формирование наряд задания",
    permissions: {
      CAN_SEE: 'Может просматривать страницу "Формирование наряд задания"',
    },
  },

  teams: {
    key: "teams",
    description: "Формирование бригад",
    permissions: {
      CAN_SEE: 'Может просматривать страницу "Формирование бригад"',
    },
  },

  employee_schedule: {
    key: "employee_schedule",
    description: "График работы персонала",
    permissions: {
      CAN_SEE: 'Может просматривать страницу "График работы персонала"',
    },
  },

  plans: {
    key: "plans",
    description: "Планирование на неделю",
    permissions: {
      CAN_SEE: 'Может просматривать страницу "Планирование на неделю"',
    },
  },

  tasks_for_teams: {
    key: "tasks_for_teams",
    description: "Распределение задач по бригадам",
    permissions: {
      CAN_SEE: 'Может просматривать страницу "Распределение задач по бригадам"',
    },
  },

  tasks_for_shifts: {
    key: "tasks_for_shifts",
    description: "Задачи на смену",
    permissions: {
      CAN_SEE: 'Может просматривать страницу "Задачи на смену"',
      CAN_SEE_IN_OFFLINE_MODE: 'Может просматривать страницу "Задачи на смену" в режиме OFFLINE',
    },
  },

  team_rating: {
    key: "team_rating",
    description: "Рейтинг бригад",
    permissions: {
      CAN_SEE: 'Может просматривать страницу "Рейтинг бригад"',
    },
  },

  dashboard: {
    key: "dashboard",
    description: "Статистика",
    permissions: {
      CAN_SEE_SHIFT_FACT: "Может видеть фактическую загрузку в отчёте по текущей смене",
    },
  },

  shared: {
    key: "shared",
    description: "Общие полномочия",
    children: {
      create_task_button: {
        key: "shared/create_task_button",
        description: "Кнопка создания заявок",
        permissions: {
          CAN_SEE: "Может видеть кнопку создания заявок",
          CAN_SEE_RECOVERY_ITEM: 'Может видеть пункт "АВР заявка" для кнопки создания заявок',
          CAN_SEE_SERVICE_REQUEST_ITEM: 'Может видеть пункт "ЗНО заявка" для кнопки создания заявок',
        },
      },
    },
  },

  create_task_modal: {
    key: "create_task_modal",
    description: 'Модальное окно "Создание заявки"',
    children: {
      any: {
        key: "create_task_modal/any",
        description: "Любого типа",
        permissions: {
          CAN_SELECT_SHIFT_AND_DATE: "Может устанавливать смену и дату при создании любой задачи",
        },
      },
      recovery: {
        key: "create_task_modal/recovery",
        description: "Создание заявки типа АВР",
        permissions: {
          CAN_SELECT_SHIFT_AND_DATE: "Может устанавливать смену и дату при создании задачи типа АВР",
        },
      },
      service_request: {
        key: "create_task_modal/service_request",
        description: "Создание заявки типа ЗНО",
        permissions: {
          CAN_SELECT_SHIFT_AND_DATE: "Может устанавливать смену и дату при создании задачи типа ЗНО",
        },
      },
    },
  },

  notifications: {
    key: "notifications",
    description: "Уведомления",
    children: {
      recovery_task: {
        key: "notifications/recovery_task",
        description: "Уведомления по задачам типа АВР",
        permissions: {
          CAN_SELECT_SHIFT_AND_DATE: "Может устанавливать смену и дату при обработке уведомления по задачам типа АВР",
        },
      },
    },
  },
} as const;
