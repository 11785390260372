import { ReactElement } from "react";
import { Col, Row } from "antd";

import { useDisabled } from "core/shared/task/useDisabled";
import Media from "core/widgets/TaskModals/shared/Media";
import { ITask } from "core/app/api/interfaces";
import Text from "core/lib/Text/Text";
import { EmployeesSelect } from "core/widgets/TaskModals/shared/EmployeesSelect/FormControlled";
import { ElapsedTime } from "core/widgets/TaskModals/shared/ElapsedTime";
import { ClosingDate } from "core/widgets/TaskModals/shared/ClosingDate";
import { Comment } from "core/widgets/TaskModals/shared/Comment";

interface OossaMaintenanceTaskFooterProps {
  task: ITask;
}

/** Раздел закрывающего в карточке технического обслуживания (ТО).
 * Используется для ООССА. */
export default function OossaMaintenanceTaskFooter({ task }: OossaMaintenanceTaskFooterProps): ReactElement {
  const disabled = useDisabled();

  return (
    <>
      <Row gutter={16}>
        <Col span={13}>
          <EmployeesSelect disabled={disabled} teamId={task.team_id} />
          <ElapsedTime disabled={disabled} />
          <Row>
            <Col>
              <ClosingDate value={task.planned_date} />
            </Col>
          </Row>
        </Col>
        <Col span={11}>
          <Comment disabled={disabled} />
        </Col>
      </Row>
      <div>
        <Text size="label4" boldness="semibold" className="uppercase">
          Дополнительные материалы:
        </Text>
      </div>
      <div>
        <Text size="label4" boldness="semibold" style={{ color: "#BAC5D1" }}>
          <div>Поддерживаемые форматы Jpg, Png, Xlsx, Docx</div>
          <div>Общий объем всех загружаемых файлов не более 20 Мб</div>
        </Text>
      </div>
      <div className="mt-2" />
      <Media task={task} />
    </>
  );
}
