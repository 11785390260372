import { useEffect, useCallback } from "react";
import { Card, Progress } from "antd";

import { fileProcessing } from "core/shared/tools/fileProcessing";
import { useUploadAttachmentMutation } from "core/app/api";

import { useFileBuffer } from "./useFileBuffer";

function UploadFileWorker() {
  const [upload, { status }] = useUploadAttachmentMutation();
  const { percentLoad, isWorkStatus, filesBuffer, deleteElemBuffer } = useFileBuffer();

  const saveFile = useCallback(async () => {
    if (isWorkStatus && filesBuffer.length) {
      const fileItem = filesBuffer[0];
      const { isLessThenLimit, isFileValid, filePreload } = await fileProcessing(fileItem.file, fileItem.compression);
      if (isLessThenLimit && isFileValid) {
        const formData = new FormData();
        formData.append("category", fileItem.category);
        formData.append("file", filePreload);
        upload({
          taskId: fileItem.taskId,
          body: formData,
        }).then();
      }
    }
  }, [filesBuffer, isWorkStatus, upload]);

  useEffect(() => {
    if (isWorkStatus && filesBuffer.length) {
      saveFile().then();
    }
  }, [isWorkStatus, filesBuffer, saveFile]);

  useEffect(() => {
    if (!isWorkStatus) return undefined;
    function beforeUnload(e: BeforeUnloadEvent) {
      e.preventDefault();
      // Стандартный текст будет проигнорирован большинством браузеров
      e.returnValue = "На странице остались несохраненные изменения. Вы уверены, что хотите уйти?"; // Необходимо для Chrome
      return "На странице остались несохраненные изменения. Вы уверены, что хотите уйти?"; // Необходимо для некоторых других браузеров
    }
    window.addEventListener("beforeunload", beforeUnload, { capture: true });
    return () => window.removeEventListener("beforeunload", beforeUnload, { capture: true });
  }, [isWorkStatus]);

  // Если статус запроса rejected вызовет таймер и попробует отправить снова
  useEffect(() => {
    if (status === "rejected") {
      const timeout = setTimeout(() => {
        saveFile().then();
      }, 5000);
      return () => clearTimeout(timeout);
    }
    if (status === "fulfilled" && filesBuffer.length) {
      deleteElemBuffer(filesBuffer[0].key);
    }
    return undefined;
  }, [status]);

  return isWorkStatus ? (
    <Card
      style={{ position: "fixed", bottom: 0, right: 0, margin: 10, opacity: 0.8 }}
      title={`Загрузка ${percentLoad === 100 ? "завершена" : "файлов"}`}
    >
      <Progress percent={percentLoad} />
    </Card>
  ) : null;
}

export default UploadFileWorker;
