import { ReactElement, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "core/app/store";
import { useMonthPlanFindOrCreateTaskMutation } from "core/app/api";
import { useUnitId } from "core/shared/unit/useUnitId";
import dayjs, { apiDateFormat } from "core/shared/tools/dayjs";
import { setFindOrCreateStationary } from "core/app/store/temp-slice";
import { useTaskId } from "core/shared/task/useTaskId";
import { useTask } from "core/shared/task/useTask";
import { TaskDiscriminatorEnum } from "core/app/api/interfaces";

import ContentSGPS from "./ContentSGPS";
import WarningModal from "./WarningModal";
import ContentLKU from "./ContentLku";
import Content from "./Content";
import "../styles.scss";
import "./styles.scss";
import ContentVols from "./ContentVols";

/** Обертка:
 * либо карточка технического обслуживания (ТО) для стационарного оборудования ПСПА, ООССА,
 * либо предупреждающее сообщение про удаление карточки. */
function StationaryTaskModal(): ReactElement {
  const { data: task } = useTask();

  return (
    <>
      {task && task.discriminator === TaskDiscriminatorEnum.PSPA_TASK ? <Content taskId={task.id} /> : null}
      {task && task.discriminator === TaskDiscriminatorEnum.SLPI_TASK ? <Content taskId={task.id} /> : null}
      {task && task.discriminator === TaskDiscriminatorEnum.PSPA_KF_TASK ? <ContentSGPS taskId={task.id} /> : null}
      {task && task.discriminator === TaskDiscriminatorEnum.LKU_TASK ? <ContentLKU taskId={task.id} /> : null}
      {task && task.discriminator === TaskDiscriminatorEnum.VOLS_TASK ? <ContentVols taskId={task.id} /> : null}
      <WarningModal />
    </>
  );
}

export function StationaryTaskModalWrapper(): ReactElement {
  const [unitId] = useUnitId();
  const { findOrCreateStationary } = useAppSelector((state) => state.temp);
  const dispatch = useAppDispatch();
  const [findOrCreateStationaryRequest, findOrCreateStationaryResult] = useMonthPlanFindOrCreateTaskMutation();
  const [, setTaskId] = useTaskId();

  useEffect(() => {
    if (
      findOrCreateStationary &&
      findOrCreateStationary.completedDate &&
      findOrCreateStationary.equipmentId &&
      findOrCreateStationary.monthPlanId &&
      unitId
    ) {
      findOrCreateStationaryRequest({
        unit_id: unitId,
        equipment_id: findOrCreateStationary.equipmentId,
        completed_date: dayjs(findOrCreateStationary.completedDate).format(apiDateFormat),
        month_plan_id: findOrCreateStationary.monthPlanId,
      });
    }
  }, [findOrCreateStationary, findOrCreateStationaryRequest, unitId]);

  useEffect(() => {
    if (findOrCreateStationaryResult.data?.task_id) {
      setTaskId(findOrCreateStationaryResult.data.task_id);
    }
  }, [findOrCreateStationaryResult.data?.task_id, setTaskId]);

  useEffect(
    () => () => {
      dispatch(setFindOrCreateStationary(null));
    },
    [dispatch]
  );

  return <div />;
}

export default StationaryTaskModal;
