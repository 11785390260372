import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
}

/** Атрибут "Заказчик" в карточке ТО. */
function Customer({ task }: FieldProps): ReactElement {
  return <Field label="Заказчик" value={task.customer?.name} />;
}

export { Customer };
