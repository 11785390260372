import { Form } from "antd";
import { ReactElement } from "react";

import Text from "core/lib/Text/Text";
import { useAppSelector } from "core/app/store";
import dayjs, { displayDateFormat } from "core/shared/tools/dayjs";

function ClosingDate(): ReactElement {
  const task = useAppSelector((state) => state.task.task);

  return (
    <Form.Item label="ДАТА ЗАКРЫТИЯ">
      {task && task.closing_date && (
        <Text size="label1" boldness="semibold">
          {dayjs(task.closing_date).format(displayDateFormat)}
        </Text>
      )}
    </Form.Item>
  );
}

export default ClosingDate;
