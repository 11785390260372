import { IEtoTask } from "core/app/api/interfaces";

import { Field } from "../Field";

type THourMeterReadingsProps = {
  task: IEtoTask;
};

export function HourMeterReadings({ task }: THourMeterReadingsProps) {
  return <Field label="Показания счетчика моточасов" value={`${task.transport?.engine_hours}`} />;
}
