import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IDailySummaryReportBodyField } from "core/app/api/interfaces";

interface IShiftsJournalInterface {
  fields: IDailySummaryReportBodyField[];
}

const initialState: IShiftsJournalInterface = {
  fields: [],
};

export const ShiftsJournalSlice = createSlice({
  name: "shifts-journal",
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    setFieldValue(state, action: PayloadAction<IDailySummaryReportBodyField>) {
      const { fields } = state;
      const index = fields.findIndex((item) => item.field === action.payload.field);
      if (index === -1) {
        return {
          fields: [...state.fields, action.payload],
        };
      }
      return {
        fields: [...state.fields.map((item, i) => (i === index ? action.payload : item))],
      };
    },
  },
});

export const { setFieldValue, reset } = ShiftsJournalSlice.actions;
