import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import { IAttachment } from "core/app/api/interfaces";
import { useMediaContext } from "core/shared/task/context/mediaContext";

import { MediaItem } from "./MediaItem";

import "./styles.scss";

interface MediaGalleryProps {
  attachments: IAttachment[];
}

/** Список медиа-файлов (карусель). */
function MediaGallery({ attachments }: MediaGalleryProps) {
  const { selectedMediaId } = useMediaContext();
  const selectedItem = attachments.findIndex((item) => item.id === selectedMediaId);
  return (
    <Carousel
      className="task-media-gallery"
      swipeable
      swipeScrollTolerance={5}
      emulateTouch
      showStatus
      showThumbs={false}
      selectedItem={selectedItem}
    >
      {attachments.map((attachment) => (
        <MediaItem key={attachment.id} attachment={attachment} />
      ))}
    </Carousel>
  );
}

export { MediaGallery };
