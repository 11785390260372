import { Form, Select } from "antd";

import { ISelectOption } from "core/shared/interfaces";
import { useTeam } from "core/shared/tasks-for-shift/api/useTeam";

interface Props {
  disabled?: boolean;
  required?: boolean;
  name?: string;
}
export function ElapsedTimeMultiplier({ disabled, required, name = "elapsed_time" }: Props) {
  const form = Form.useFormInstance();
  const teamId = form.getFieldValue("team_id");
  const team = useTeam(teamId);
  let options: ISelectOption[] = [];
  if (team?.employees) {
    options = team.employees.map((_: any, index: number) => ({
      label: `${index + 1}`,
      value: index + 1,
    }));
  }
  return (
    <Form.Item label="ЧЕЛОВЕК" name="elapsed_time_multiplier">
      <Select style={{ width: "100%" }} disabled={disabled} options={options} dropdownStyle={{ zIndex: 2000 }} />
    </Form.Item>
  );
}
