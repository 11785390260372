import { useMemo } from "react";

import { Text } from "core/lib/Text/Text";
import { groupWithOrder } from "core/shared/tools/groupWithOrder";

import { ICheckListItemWithIndex } from "../..";

import { EquipmentType } from "./EquipmentType";

interface Props {
  checklists: ICheckListItemWithIndex[];
}

export function Place({ checklists }: Props) {
  const checklistsByEquipmentType = useMemo(() => groupWithOrder(checklists, "check.equipment_type.name"), []);

  return (
    <div className="ml-4">
      {Object.entries(checklistsByEquipmentType).map(([equipmentType, equipmentTypeChecklists]) => (
        <div key={equipmentType}>
          <div className="mb-1">
            <Text boldness="semibold" size="label2" transform="uppercase" color="gray2">
              {equipmentType.includes("undefined") ? "Общее" : equipmentType.split("_")[0]}
            </Text>
          </div>
          <EquipmentType checklists={equipmentTypeChecklists} />
        </div>
      ))}
    </div>
  );
}
