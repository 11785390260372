import { ReactElement } from "react";
import { Skeleton } from "antd";

import { getUniqueArray } from "core/shared/tools";

interface SkeletonsProps {
  quantity?: number;
  size?: "large" | "small" | "default";
}

function Skeletons(props: SkeletonsProps): ReactElement {
  const { quantity = 3, size = "large" } = props;
  return (
    <>
      {getUniqueArray(quantity).map((uuid) => (
        // eslint-disable-next-line react/no-array-index-key
        <Skeleton.Input style={{ width: "100%" }} key={uuid} className="mb-1" active size={size} block />
      ))}
    </>
  );
}

export default Skeletons;
