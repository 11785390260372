import { Select, Form } from "antd";
import { ReactElement } from "react";

import { IDictionaryItem, ISelectOption } from "core/shared/interfaces";
import { useDisabled } from "core/shared/task/useDisabled";
import { useLocations } from "core/shared/useLocations";

function LocationSelect(): ReactElement {
  const { data: locations, isLoading } = useLocations();
  const disabled = useDisabled();

  let options: ISelectOption[] = [];

  if (locations?.results) {
    options = locations.results.map((location: IDictionaryItem) => ({
      label: location.name,
      value: location.id,
      key: location.id,
    }));
  }

  return (
    <Form.Item label="МЕСТОПОЛОЖЕНИЕ" name="location_id">
      <Select
        loading={isLoading}
        disabled={disabled}
        style={{ width: "100%" }}
        showSearch
        placeholder="Местоположение"
        optionFilterProp="children"
        dropdownStyle={{ zIndex: 2000 }}
        filterOption={(input, option) => (option?.label?.toString() ?? "").toLowerCase().includes(input.toLowerCase())}
        options={options}
      />
    </Form.Item>
  );
}

export default LocationSelect;
