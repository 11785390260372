import { ReactElement } from "react";
import { Select, Form } from "antd";
import qs from "qs";

import { useGetLkuEquipmentsQuery } from "core/app/api/LKU";

import { FIELD_NAMES } from "../../constants";

export function LkuEquipmentField(): ReactElement {
  const equipmentTypeId = Form.useWatch(FIELD_NAMES.EQUIPMENT_TYPE);
  const techNodeId = Form.useWatch(FIELD_NAMES.TECH_NODE);

  const queryString = qs.stringify({
    equipment_type_id: equipmentTypeId,
    rack_id: techNodeId,
  });
  const { data, isFetching } = useGetLkuEquipmentsQuery(queryString);

  const options = (data || []).map((item) => ({
    label: item.name,
    value: item.id,
  }));

  return (
    <Form.Item label="ЕД. ОБОРУД." name={FIELD_NAMES.EQUIPMENT} colon={false}>
      <Select
        loading={isFetching}
        size="large"
        allowClear
        optionFilterProp="children"
        showSearch
        filterOption={(input, option) =>
          (option?.label?.toString() ?? "").toLowerCase().includes(input.trim().toLowerCase())
        }
        options={options}
      />
    </Form.Item>
  );
}
