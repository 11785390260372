import { ReactElement } from "react";
import { Select, Form } from "antd";
import qs from "qs";

import { useGetOossaSystemsQuery } from "core/app/api/api";

import { useIsFieldRequired } from "../../useIsFieldRequired";
import { FIELD_NAMES } from "../../constants";

export function System(): ReactElement {
  const objectId = Form.useWatch("objectId");
  const required = useIsFieldRequired(FIELD_NAMES.SYSTEM);
  const form = Form.useFormInstance();

  const queryProps = {
    object_id: objectId,
  };
  const { data, isFetching } = useGetOossaSystemsQuery(qs.stringify(queryProps));

  const options = (data?.results || []).map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const handleSelectChange = () => {
    form.setFieldsValue({
      nodeId: undefined,
      equipmentId: undefined,
    });
  };

  return (
    <Form.Item
      label="СИСТЕМА"
      name={FIELD_NAMES.SYSTEM}
      colon={false}
      required={required}
      rules={[
        {
          required,
          message: "",
        },
      ]}
    >
      <Select
        loading={isFetching}
        size="large"
        allowClear
        optionFilterProp="children"
        showSearch
        filterOption={(input, option) =>
          (option?.label?.toString() ?? "").toLowerCase().includes(input.trim().toLowerCase())
        }
        options={options}
        onChange={handleSelectChange}
      />
    </Form.Item>
  );
}
