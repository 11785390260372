export function extractErrorMessage(e: any) {
  if (e.message) {
    return e.message;
  }

  const code = e.status || e.data?.code;
  const detail = e.data?.detail || e.data?.default_detail;

  if (code && detail) {
    return `${detail} (${code})`;
  }

  return "Неизвестная ошибка";
}
