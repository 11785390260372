import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IVersioinState {
  version: string;
}

const initialState: IVersioinState = {
  version: "0",
};

export const VersionSlice = createSlice({
  name: "version",
  initialState,
  reducers: {
    setVersion(state, action: PayloadAction<string>) {
      state.version = action.payload;
    },
  },
});

export const { setVersion } = VersionSlice.actions;
