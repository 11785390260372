import { Form, Input, Select, InputNumber } from "antd";
import React, { ReactElement } from "react";

import { IMaintenanceExecutionValuesItem } from "core/app/api/interfaces";
import { useDisabled } from "core/shared/task/useDisabled";

interface ValueProps {
  maintenanceExecutionIndex: number;
  valueIndex: number;
  value: IMaintenanceExecutionValuesItem;
}

/** Измеренное значение в карточке задания ТО.
 * В ООССА не используется. */
function Value(props: ValueProps): ReactElement {
  const { maintenanceExecutionIndex, value, valueIndex } = props;
  const disabled = useDisabled();

  const validationRules = [{ required: value.field.is_required, message: `Обязательное поле` }];

  return (
    <>
      <Form.Item hidden name={["maintenance_execution", maintenanceExecutionIndex, "values", valueIndex, "id"]}>
        <InputNumber />
      </Form.Item>
      <Form.Item
        label={value.field.title}
        name={["maintenance_execution", maintenanceExecutionIndex, "values", valueIndex, "value"]}
        rules={validationRules}
      >
        {value.field.choices.length > 0 ? (
          <Select value={value.value} disabled={disabled} className="w-100">
            {value.field.choices.map((choice) => (
              <Select.Option key={choice}>{choice}</Select.Option>
            ))}
          </Select>
        ) : (
          <Input disabled={disabled} />
        )}
      </Form.Item>
    </>
  );
}

export default Value;
