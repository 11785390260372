import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IToken {
  access: string;
  refresh: string;
}

interface ITokenState {
  token: IToken | null;
}

const initialState: ITokenState = {
  token: null,
};

export const TokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<IToken | null>) {
      state.token = action.payload;
    },
    logOut(state) {
      state.token = null;
    },
  },
});

export const { setToken, logOut } = TokenSlice.actions;
