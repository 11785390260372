import { ReactElement } from "react";
import { Space } from "antd";

import { useUnit } from "core/shared/unit/useUnit";

import { StatisticByTeamPspaTable } from "./ByTeamPspaTable";
import { StatisticByTeamSlpiTable } from "./ByTeamSlpiTable";
import { StatisticByTeamOossaTable } from "./ByTeamOossaTable";
import { StatisticByTeamLsoTable } from "./ByTeamLsoTable";
import TaskLegend from "./TaskLegend";

export default function StatisticByTeam(): ReactElement {
  const unit = useUnit();

  return (
    <Space direction="vertical" size={16}>
      <TaskLegend />
      {unit?.isPSPA() && <StatisticByTeamPspaTable />}
      {unit?.isSLPI() && <StatisticByTeamSlpiTable />}
      {unit?.isLSO() && <StatisticByTeamLsoTable />}
      {unit?.isOOSSA() && <StatisticByTeamOossaTable />}
    </Space>
  );
}
