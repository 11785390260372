import {
  useUpdatePspaTaskMutation,
  useUpdateOosaTaskMutation,
  useUpdateSlpiTaskMutation,
  useUpdateEtoTaskMutation,
} from "core/app/api";
import { useUpdatePspaKfTaskMutation } from "core/app/api/SGPS";
import { useUpdateLsoTaskMutation } from "core/app/api/LSO";
import { useUpdateLkuTaskMutation } from "core/app/api/LKU";
import { ITask, IEtoTask, TaskDiscriminatorEnum } from "core/app/api/interfaces";
import { useUpdateVolsTaskMutation } from "core/app/api/VOLS";

export function useUpdateTask(discriminator?: TaskDiscriminatorEnum) {
  const pspa = useUpdatePspaTaskMutation();
  const pspakf = useUpdatePspaKfTaskMutation();
  const ossa = useUpdateOosaTaskMutation();
  const slpi = useUpdateSlpiTaskMutation();
  const eto = useUpdateEtoTaskMutation();
  const lso = useUpdateLsoTaskMutation();
  const lku = useUpdateLkuTaskMutation();
  const vols = useUpdateVolsTaskMutation();
  if (!discriminator) {
    return pspa;
  }
  switch (discriminator) {
    case TaskDiscriminatorEnum.PSPA_TASK:
      return pspa;
    case TaskDiscriminatorEnum.PSPA_KF_TASK:
      return pspakf;
    case TaskDiscriminatorEnum.OOSSA_TASK:
      return ossa;
    case TaskDiscriminatorEnum.SLPI_TASK:
      return slpi;
    case TaskDiscriminatorEnum.ETO_PSPA_TASK:
      return eto;
    case TaskDiscriminatorEnum.LSO_TASK:
      return lso;
    case TaskDiscriminatorEnum.LKU_TASK:
      return lku;
    case TaskDiscriminatorEnum.VOLS_TASK:
      return vols;
    default:
      return pspa;
  }
}
