import { useUnit } from "core/shared/unit/useUnit";

import { Slpi } from "./Slpi";

export function ExtraMaintenance() {
  const unit = useUnit();
  if (unit?.isSLPI()) {
    return <Slpi />;
  }
  return <div />;
}
