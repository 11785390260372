import { Row, Col, Popover, Tooltip } from "antd";
import { ReactElement, useState } from "react";
import clsx from "clsx";

import Text from "core/lib/Text/Text";
import { IMaintenanceExecutionCheckListItem } from "core/app/api/interfaces";
import { useDisabled } from "core/shared/task/useDisabled";

import MaintenanceExecutionCheckMenu from "./MaintenanceExecutionCheckMenu";

/** Статус выполнения работы из чек-листа задания на ТО. */
export enum CheckStatusEnum {
  Unchecked = 0,
  Ok = 1,
  Broken = 2,
  Repaired = 3,
}

interface MaintenanceExecutionCheckProps {
  maintenanceExecutionId: number;
  checkListItem: IMaintenanceExecutionCheckListItem;
  show: boolean;
}

/** Элемент чек-листа задания на ТО: наименование работы, текущий статус, всплывающий выбор статуса выполнения работы. */
function StationaryMaintenanceExecutionCheckListItem(props: MaintenanceExecutionCheckProps): ReactElement {
  const { maintenanceExecutionId, checkListItem, show } = props;
  const [visible, setVisible] = useState(false);
  const disabled = useDisabled();

  const handleClick = () => {
    if (!disabled) {
      setVisible(!visible);
    }
  };

  if (show) {
    return (
      <Row key={checkListItem.id} className="mb-1">
        <Col span={22}>
          <Tooltip title={checkListItem?.check?.description}>
            <Text size="label2" boldness="semibold" className="list-item">
              {checkListItem?.check?.title}
            </Text>
          </Tooltip>
        </Col>
        <Col span={2}>
          <Row justify="end">
            <Col>
              <Popover
                open={visible}
                placement="bottom"
                content={
                  <MaintenanceExecutionCheckMenu
                    setVisible={setVisible}
                    maintenanceExecutionId={maintenanceExecutionId}
                    checkListItemId={checkListItem.id}
                  />
                }
                trigger="click"
              >
                {/* marker - квадратик текущего статуса, другие классы придают цвет */}
                <div
                  onClick={handleClick}
                  className={clsx("marker", "small", {
                    disabled,
                    unchecked: checkListItem.value === CheckStatusEnum.Unchecked,
                    ok: checkListItem.value === CheckStatusEnum.Ok,
                    broken: checkListItem.value === CheckStatusEnum.Broken,
                    repaired: checkListItem.value === CheckStatusEnum.Repaired,
                  })}
                />
              </Popover>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
  return <div />;
}

export default StationaryMaintenanceExecutionCheckListItem;
