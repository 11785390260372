import { Select, Form, FormInstance } from "antd";
import { ReactElement } from "react";
import qs from "qs";
import { DefaultOptionType } from "antd/lib/select";

import { useGetResponsibleGroupsQuery } from "core/app/api";
import { useUnitId } from "core/shared/unit/useUnitId";

// ID Itsm (СВР и АГЗ) для которых надо блокировать группу ответственных
const DISABLE_ITSM_EQUIPMENTS_IDS = ["bf9e7786-49b8-4acb-8aee-8dc23ebec708", "12c1b9a0-3fd0-44b5-bd28-c7c05b79a670"];

interface ResponsibleGroupSelectProps {
  disabled: boolean;
  form: FormInstance;
}

function ResponsibleGroupSelect({ disabled, form }: ResponsibleGroupSelectProps): ReactElement {
  const [unitId] = useUnitId();
  const itsmEquipment = form.getFieldValue("itsmEquipment");
  const { data: responsibleGroups } = useGetResponsibleGroupsQuery(
    qs.stringify({
      unit_id: unitId,
    })
  );

  let options: DefaultOptionType[] = [
    {
      label: "",
      value: "",
    },
  ];
  if (responsibleGroups) {
    options = responsibleGroups.map((responsibleGroup) => ({
      label: responsibleGroup.name,
      value: responsibleGroup.id,
    }));
  }

  let disabledByITSM = false;
  if (!itsmEquipment || DISABLE_ITSM_EQUIPMENTS_IDS.includes(itsmEquipment.id)) {
    disabledByITSM = true;
  }

  return (
    <Form.Item label="ГРУППА ОТВЕТСТВЕННЫХ" name="responsible_group_id">
      <Select
        disabled={disabled || disabledByITSM}
        style={{ width: "100%" }}
        dropdownStyle={{ zIndex: 2000 }}
        dropdownMatchSelectWidth={650}
        placement="bottomRight"
        options={options}
        allowClear
        showSearch
        filterOption={(input, option) => (option?.label?.toString() ?? "").toLowerCase().includes(input.toLowerCase())}
      />
    </Form.Item>
  );
}

export default ResponsibleGroupSelect;
