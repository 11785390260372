import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
  label?: string;
}

function EquipmentType({ task, label }: FieldProps): ReactElement {
  return <Field label={label || "Тип оборудования"} value={task.equipment_type?.name} />;
}

export { EquipmentType };
