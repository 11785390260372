import { Row, Col } from "antd";
import clsx from "clsx";

import { ITask } from "core/app/api/interfaces";
import {
  Customer,
  Department,
  RequestCreationDatetime,
  Effort,
  AccumulatorElapsedTime,
} from "core/widgets/TaskModals/shared/Fields";
import { LsoObject } from "core/widgets/TaskModals/shared/Fields/LsoObject";

type LsoGeneralInfoProps = {
  task: ITask;
};

export default function LsoGeneralInfo({ task }: LsoGeneralInfoProps) {
  return (
    <Row gutter={16} className={clsx("mb-1", "mt-1")}>
      <Col span={12}>
        <Customer task={task} />
        <Department task={task} />
        <LsoObject task={task} />
      </Col>
      <Col span={12}>
        <RequestCreationDatetime task={task} />
        <Effort task={task} />
        <AccumulatorElapsedTime task={task} />
      </Col>
    </Row>
  );
}
