import { PlusOutlined } from "@ant-design/icons";
import { message, Space, Upload } from "antd";
import type { RcFile } from "antd/es/upload/interface";

import Text from "core/lib/Text/Text";
import "./styles.scss";
import { fileToBase64 } from "core/shared/main/tools/fileToBase64";
import { ILocalAttachment } from "core/app/api/interfaces";
import { fileProcessing } from "core/shared/tools/fileProcessing";

type TUploadButtonProps = {
  onFileUpload: (file: ILocalAttachment) => void;
};

function UploadButton({ onFileUpload }: TUploadButtonProps) {
  const beforeUpload = async (file: RcFile) => {
    const { isLessThenLimit, isFileValid, filePreload } = await fileProcessing(file);
    if (isLessThenLimit && isFileValid) {
      try {
        const encodedFile = (await fileToBase64(filePreload)) as string;
        onFileUpload({
          uid: file.uid,
          mimeType: file.type,
          originalName: file.name,
          size: filePreload.size,
          lastModifiedDate: file.lastModifiedDate,
          file: encodedFile,
        });
      } catch (e) {
        console.error(e);
        message.error("При загрузке файла произошла ошибка");
        return false;
      }
    }
    return false;
  };

  const uploadButton = (
    <div className="upload-button__inner">
      <PlusOutlined className="add-icon" />
      <Space direction="vertical" size={0}>
        <Text size="label4">Добавить</Text>
        <Text size="label4">файл</Text>
      </Space>
    </div>
  );

  return (
    <Upload className="upload-button" name="import_file" showUploadList={false} beforeUpload={beforeUpload} multiple>
      {uploadButton}
    </Upload>
  );
}

export default UploadButton;
