import { ReactElement, useState, useContext, useEffect } from "react";
import { Row, Col, Space } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import Text from "core/lib/Text/Text";
import { shiftsIcons } from "core/shared/constants";
import { useUnitShifts } from "core/shared/unit/useUnitShifts";
import { ShiftContext } from "core/shared/dashboard/context/shiftContext";

function ShiftSelector(): ReactElement {
  const { setShiftNum, setTeamId } = useContext(ShiftContext);
  const { data: unitShifts } = useUnitShifts();
  const [shiftIndex, setShiftIndex] = useState<number>(0);
  const shift = unitShifts && unitShifts[shiftIndex];
  const shiftKey = shift?.number;
  const shiftIcons = shift && shiftsIcons[shift.code_enum];

  useEffect(() => {
    if (shiftKey) {
      setShiftNum(shiftKey);
    }
  }, [setShiftNum, shiftKey]);

  const handleRightClick = () => {
    if (!unitShifts) {
      return;
    }
    if (unitShifts && unitShifts.length - 1 > shiftIndex) {
      setShiftIndex(shiftIndex + 1);
    } else {
      setShiftIndex(0);
    }
    setTeamId("");
  };

  const handleLeftClick = () => {
    if (!unitShifts) {
      return;
    }
    if (shiftIndex > 0) {
      setShiftIndex(shiftIndex - 1);
    } else {
      setShiftIndex(unitShifts.length - 1);
    }
    setTeamId("");
  };

  return (
    <Row className="mt-3" align="middle" gutter={8}>
      <Col style={{ flex: "0 0 140px" }}>
        {shift && (
          <Space>
            <div>
              <img width={24} height={24} src={shiftIcons?.img} alt={shift.name} />
            </div>
            <div>
              <div data-shift-number={shiftKey} style={{ lineHeight: "initial" }}>
                <Text size="label2" style={{ color: "#AFC1C5" }} className="uppercase">
                  {shift.name}
                </Text>
              </div>
              <div style={{ lineHeight: "initial" }}>
                <Text style={{ color: "#AFC1C5" }} className="uppercase" size="label3">
                  СМЕНА
                </Text>
              </div>
            </div>
          </Space>
        )}
      </Col>
      {unitShifts?.length && unitShifts.length > 1 && (
        <Col style={{ flex: "0 0 52px" }}>
          <Space>
            <div className="pointer" onClick={handleLeftClick}>
              <LeftOutlined />
            </div>
            <div className="pointer" onClick={handleRightClick}>
              <RightOutlined />
            </div>
          </Space>
        </Col>
      )}
    </Row>
  );
}

export default ShiftSelector;
