import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
  label?: string;
}

/** Атрибут "Балансодержатель" в карточке ТО. */
function CustomerDepartment({ task, label }: FieldProps): ReactElement {
  return <Field label={label || "Балансодержатель"} value={task.customer_department?.name} />;
}

export { CustomerDepartment };
