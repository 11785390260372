/* eslint-disable no-underscore-dangle */
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { get } from "lodash";

import { useGetMatomoEnvironmentQuery } from "core/app/api";

const MATOMO_URL = "https://analytics.npr.nornick.ru/";
const REGRESS = "REGRESS";
const SITE_ID = "2";

type Paq = string[][];

declare global {
  interface Window {
    _paq: Paq;
  }
}

export function MatomoTagManager() {
  const { data: env } = useGetMatomoEnvironmentQuery("");
  const location = useLocation();

  useEffect(() => {
    if (env === REGRESS) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.defer = true;
      script.src = `${MATOMO_URL}/piwik.js`;
      document.body.appendChild(script);

      window._paq = window._paq || [];
      window._paq.push(["trackPageView"]);
      window._paq.push(["enableLinkTracking"]);
      window._paq.push(["setTrackerUrl", `${MATOMO_URL}/piwik.php`]);
      window._paq.push(["setSiteId", SITE_ID]);
    }
  }, [env]);

  useEffect(() => {
    const paq = get(window, "_paq") as Paq | undefined;
    if (paq) {
      paq.push(["setCustomUrl", location.pathname]);
      paq.push(["trackPageView"]);
    }
  }, [location]);

  return null;
}
