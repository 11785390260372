import { useEffect, useCallback } from "react";
import { QueryStatus } from "@reduxjs/toolkit/dist/query";

import { useAppSelector, useAppDispatch } from "core/app/store";
import { setUnitDepartmentId } from "core/app/store/main-slice";

import { useUnitDepartments } from "../useUnitDepartments";

export const useUnitDepartmentId = (): [number | null, (id: number) => void] => {
  const { data, status } = useUnitDepartments();
  const dispatch = useAppDispatch();
  const { unitDepartmentId } = useAppSelector((state) => state.main);

  const set = useCallback(
    (id: number | null) => {
      dispatch(setUnitDepartmentId(id));
    },
    [dispatch]
  );

  useEffect(() => {
    if (status === QueryStatus.fulfilled && !data?.length) {
      set(null);
    }
  }, [data, set, status]);

  return [unitDepartmentId, set];
};
