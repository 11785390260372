/** @jsxImportSource @emotion/react */
import { Modal, Row, Col, Form, message } from "antd";
import { useEffect, useState } from "react";
import { Dayjs } from "dayjs";

import dayjs, { apiDateFormat, displayDateFormat } from "core/shared/tools/dayjs";
import {
  useGetRecoveryTasksNotificationsQuery,
  useSkipRecoveryTasksNotificationMutation,
  usePlanTaskNotificationMutation,
  useGetUserSettingsTasksNotificationsQuery,
} from "core/app/api";
import { IPlanTasksNotificationError, UrgencyEnum } from "core/app/api/interfaces";
import { useUnitShifts } from "core/shared/unit/useUnitShifts";
import Text from "core/lib/Text/Text";
import { hoursToString } from "core/shared/tools/hoursToString";
import useUiPermissions from "core/shared/permissions/hooks/useUiPermissions";

import ShiftSelect from "./ShiftSelect";
import DateSelect from "./DateSelect";
import TeamSelect from "./TeamSelect";
import { Title } from "./Title";

import "./styles.scss";

const REFETCH_INTERVAL = 5000;

function RecoveryTasksNotifications() {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const { data, refetch } = useGetRecoveryTasksNotificationsQuery("");
  const [skipRequest, skipResult] = useSkipRecoveryTasksNotificationMutation();
  const [addRequest, addResult] = usePlanTaskNotificationMutation();

  const { CAN_SELECT_SHIFT_AND_DATE } = useUiPermissions("notifications/recovery_task");

  const { data: unitShifts } = useUnitShifts();
  const team = Form.useWatch("team", form);

  useEffect(() => {
    const timer = setInterval(refetch, REFETCH_INTERVAL);
    return () => clearInterval(timer);
  });

  useEffect(() => {
    if (data) {
      setOpen(true);
    }
  }, [data]);

  useEffect(() => {
    if (addResult.isSuccess) {
      message.success(`Задача запланирована!`);
    }
  }, [addResult.isSuccess]);

  useEffect(() => {
    if (addResult.isError) {
      message.error((addResult.error as IPlanTasksNotificationError).data.detail);
    }
  }, [addResult.data, addResult.error, addResult.isError]);

  if (!data || !data.count) {
    return <div />;
  }
  const notification = data.results[0];
  const { task } = notification;

  const handleCancel = () => {
    skipRequest({
      notification: notification.id,
    });
  };

  const handlePlan = () => {
    if (team) {
      addRequest({
        team: form.getFieldValue("team"),
        notification: notification.id,
      });
    } else {
      addRequest({
        date: dayjs(form.getFieldValue("date")).format(apiDateFormat),
        shift_number: form.getFieldValue("shift"),
        notification: notification.id,
      });
    }
  };

  const initialValues: Record<string, string | number | boolean | null | undefined | Dayjs> = {
    team: null,
    shift: unitShifts && unitShifts.length && unitShifts[0].number,
    date: dayjs(),
  };

  let disabled = true;
  if (CAN_SELECT_SHIFT_AND_DATE || team) {
    disabled = false;
  }

  const indexOfUrgency = Object.values(UrgencyEnum).indexOf(task.priority as unknown as UrgencyEnum);
  const priority = Object.keys(UrgencyEnum)[indexOfUrgency].toLowerCase();

  return (
    <Modal
      className={`recovery-tasks-notification ${priority}`}
      maskClosable={false}
      destroyOnClose
      centered
      width={600}
      open={open}
      title={<Title notification={notification} count={data.count} />}
      okText="ЗАПЛАНИРОВАТЬ"
      cancelText="ПРОПУСТИТЬ"
      okButtonProps={{
        style: {
          width: 200,
        },
        disabled,
        loading: addResult.isLoading,
      }}
      cancelButtonProps={{
        style: {
          width: 200,
        },
        loading: skipResult.isLoading,
      }}
      onCancel={handleCancel}
      onOk={handlePlan}
    >
      <Row>
        <Col span={24}>{task.description}</Col>
      </Row>
      {task.suspended_until && (
        <Row gutter={8} className="mt-1">
          <Col>
            <Text size="label2" boldness="semibold">
              Приостановлена до:&nbsp;
            </Text>
            <Text size="label2">{dayjs(task.suspended_until).format(displayDateFormat)}</Text>
          </Col>
        </Row>
      )}
      {task.work_time_usage_hours !== undefined && (
        <Row gutter={8} className="mt-1">
          <Col>
            <Text size="label2" boldness="semibold">
              Остаток:&nbsp;
            </Text>
            <Text size="label2">{hoursToString(task.work_time_usage_hours || 0)}</Text>
          </Col>
        </Row>
      )}
      <Form form={form} layout="vertical" initialValues={initialValues}>
        <Row gutter={16} css={{ marginTop: 24 }}>
          {CAN_SELECT_SHIFT_AND_DATE && (
            <>
              <Col span={8}>
                <DateSelect />
              </Col>
              <Col span={8}>
                <ShiftSelect />
              </Col>
            </>
          )}
          <Col span={CAN_SELECT_SHIFT_AND_DATE ? 8 : 24}>
            <TeamSelect required={!CAN_SELECT_SHIFT_AND_DATE} />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

function RecoveryTasksNotificationsWrapper() {
  const { data } = useGetUserSettingsTasksNotificationsQuery("");
  if (data?.task_notifications_enable) {
    return <RecoveryTasksNotifications />;
  }
  return null;
}

export { RecoveryTasksNotifications, RecoveryTasksNotificationsWrapper };
