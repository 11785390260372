import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";
import { isVolsTask } from "core/shared/task";
import { IVolsWorkType } from "core/app/api/VOLS/interfaces";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
  showDescription?: boolean;
}

/** Атрибут "Вид работ" в карточке ТО. */
function WorkType({ task, showDescription }: FieldProps): ReactElement {
  let value = task.work_type?.name;

  if (showDescription && isVolsTask(task)) {
    value = (task.work_type as IVolsWorkType)?.description;
  }

  return <Field label="Вид работ" value={value} />;
}

export { WorkType };
