import { Form, Modal, Input, Select } from "antd";
import { ReactElement, useEffect } from "react";
import { FormInstance } from "antd/es/form/Form";
import qs from "qs";

import { useCreateCustomerRepresentativeMutation, useGetPositionsQuery } from "core/app/api";
import { useUnitId } from "core/shared/unit/useUnitId";
import { IPosition, PositionRoleEnum } from "core/app/api/interfaces";

import { Dropdown } from "./Dropdown";

interface NewCustomerModalProps {
  open: boolean;
  setOpen: (arg: boolean) => void;
  form: FormInstance;
}

function NewCustomerModal({ open, setOpen, form: parentForm }: NewCustomerModalProps): ReactElement {
  const [form] = Form.useForm();
  const [request, result] = useCreateCustomerRepresentativeMutation();
  const [unitId] = useUnitId();
  const { data: positions } = useGetPositionsQuery(qs.stringify({ type: PositionRoleEnum.CUSTOMER_REPRESENTATIVE }));

  let options = [
    {
      label: "",
      value: 0,
    },
  ];
  if (positions) {
    options = positions.map((position: IPosition) => ({
      label: position.name,
      value: position.id,
    }));
  }

  const handleOk = () => {
    form.validateFields().then((values) => {
      if (unitId) {
        request({
          unit: unitId,
          firstName: values.firstName,
          lastName: values.lastName,
          middleName: values.middleName,
          position: values.position,
        });
      }
    });
  };

  useEffect(() => {
    if (result.isSuccess) {
      form.resetFields();
      parentForm.setFieldValue("executor", result.data.id);
      setOpen(false);
    }
  }, [form, parentForm, result.data?.id, result.isSuccess, setOpen]);

  const handleCancel = () => {
    form.resetFields();
    setOpen(false);
  };
  return (
    <Modal
      title="СОЗДАНИЕ ЗАКАЗЧИКА"
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="СОХРАНИТЬ"
      cancelText="ОТМЕНИТЬ"
      okButtonProps={{
        loading: result.isLoading,
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          firstName: "",
          lastName: "",
          middleName: "",
          position: null,
        }}
      >
        <Form.Item name="firstName" label="ИМЯ" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="lastName" label="ФАМИЛИЯ" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="middleName" label="ОТЧЕСТВО">
          <Input />
        </Form.Item>
        <Form.Item name="position" label="ДОЛЖНОСТЬ" rules={[{ required: true }]}>
          <Select
            style={{ width: "100%" }}
            showSearch
            allowClear
            optionFilterProp="children"
            dropdownStyle={{ zIndex: 2000 }}
            filterOption={(input, option) =>
              (option?.label?.toString() ?? "").toLowerCase().includes(input.toLowerCase())
            }
            // eslint-disable-next-line react/no-unstable-nested-components
            dropdownRender={(menu) => <Dropdown menu={menu} />}
            options={options}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export { NewCustomerModal };
