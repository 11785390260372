import { useMemo } from "react";

import { useGetUnitsQuery } from "core/app/api";
import { IUnit } from "core/shared/unit/types";

import { isSLPI, isPSPA, isOOSSA, isLSO, isLKU, isVOLS, isPSS, isPSPAZF, isPSPAKF, isSLPIKF } from "../tools";

const useUnits = (): IUnit[] | undefined => {
  const { data } = useGetUnitsQuery("");

  return useMemo(() => {
    if (data) {
      return data.map((unit) => ({
        ...unit,
        isSLPI,
        isPSPA,
        isOOSSA,
        isPSS,
        isLKU,
        isLSO,
        isVOLS,
        isPSPAZF,
        isPSPAKF,
        isSLPIKF,
      }));
    }
    return undefined;
  }, [data]);
};

export { useUnits };
