import { ReactElement } from "react";
import { Select, Form } from "antd";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import qs from "qs";

import { useGetOossaEquipmentsQuery } from "core/app/api/api";
import { useOossaSystem } from "core/shared/oossa/api/useOossaSystems";
import { OossaSystemTypeEnum } from "core/app/api/interfaces";

import { FIELD_NAMES } from "../../constants";
import { useIsFieldRequired } from "../../useIsFieldRequired";

export function Equipment(): ReactElement {
  const objectId = Form.useWatch("objectId");
  const systemId = Form.useWatch("systemId");
  const required = useIsFieldRequired(FIELD_NAMES.EQUIPMENT);
  const oossaSystem = useOossaSystem(systemId);
  const disabled =
    oossaSystem?.code_enum === OossaSystemTypeEnum.ASKUE || oossaSystem?.code_enum === OossaSystemTypeEnum.ASKUE_EE;

  const queryString = qs.stringify({
    system_id: systemId,
    object_id: objectId,
  });
  const { data, isFetching } = useGetOossaEquipmentsQuery(disabled ? skipToken : queryString);

  const options = (data?.results || []).map((item) => ({
    label: item.name,
    value: item.id,
  }));

  return (
    <Form.Item
      label="ЕД. ОБОРУД."
      name={FIELD_NAMES.EQUIPMENT}
      colon={false}
      required={required}
      rules={[
        {
          required,
          message: "",
        },
      ]}
    >
      <Select
        loading={isFetching}
        size="large"
        allowClear
        optionFilterProp="children"
        showSearch
        disabled={disabled}
        filterOption={(input, option) =>
          (option?.label?.toString() ?? "").toLowerCase().includes(input.trim().toLowerCase())
        }
        options={options}
      />
    </Form.Item>
  );
}
