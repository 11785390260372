import qs from "qs";

import { useGetSlpiDirectObjectsQuery } from "core/app/api";
import { useUnitId } from "core/shared/unit/useUnitId";

const useUnitObjects = (queryParams: Record<string, string | number | null>) => {
  const [unitId] = useUnitId();
  return useGetSlpiDirectObjectsQuery(
    qs.stringify({
      unit_id: unitId,
      ...queryParams,
    })
  );
};

export { useUnitObjects };
