import locale from "antd/es/locale/ru_RU";

if (locale.DatePicker) {
  locale.DatePicker.lang.shortMonths = [
    "Янв",
    "Фев",
    "Мар",
    "Апр",
    "Май",
    "Июн",
    "Июл",
    "Авг",
    "Сен",
    "Окт",
    "Ноя",
    "Дек",
  ];
}

export { locale };
