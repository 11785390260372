import { useEffect, useState } from "react";

import { useAppSelector } from "core/app/store";
import { ITaskSignature, signaturesSelector } from "core/app/store/task-signature-slice";
import { useNetworkState } from "core/shared/main/api/useNetworkState";
import { IEmployee, SignatureSideEnum, ITask } from "core/app/api/interfaces";
import { useCustomerRepresentatives } from "core/shared/useCustomerRepresentatives";
import { useEmployees } from "core/shared/useEmployees";

type TSignatureInfo = {
  signatory?: {
    fullName: string;
    position: string;
  };
  signature?: ITaskSignature;
};

function useFetchOfflineSignatures(task: ITask) {
  const { isOnline } = useNetworkState();
  const { data: customers, isSuccess: isCustomersRequestSuccess } = useCustomerRepresentatives({
    can_sign_maintenance_act: true,
  });
  const { data: employees, isSuccess: isEmployeesRequestSuccess } = useEmployees({ team_id: task.team_id });
  const signatures = useAppSelector(signaturesSelector);
  const [customerSignatureInfo, setCustomerSignatureInfo] = useState<TSignatureInfo>({});
  const [executorSignatureInfo, setExecutorSignatureInfo] = useState<TSignatureInfo>({});

  const customerOfflineSignature = signatures.find(
    (signature) => signature.taskId === task.id && signature.side === SignatureSideEnum.Customer
  );
  const executorOfflineSignature = signatures.find(
    (signature) => signature.taskId === task.id && signature.side === SignatureSideEnum.Executor
  );

  useEffect(() => {
    if (!isOnline && isCustomersRequestSuccess && customers && customerOfflineSignature) {
      const selectedCustomer = customers.find(
        (customer) => customer.id === Number(customerOfflineSignature.signatoryId)
      );
      if (selectedCustomer) {
        setCustomerSignatureInfo({
          signatory: {
            fullName: selectedCustomer.full_name,
            position: selectedCustomer.position_name,
          },
          signature: customerOfflineSignature,
        });
      }
    }
  }, [isOnline, customers, isCustomersRequestSuccess, customerOfflineSignature]);

  useEffect(() => {
    if (!isOnline && isEmployeesRequestSuccess && employees && employees.results && executorOfflineSignature) {
      const selectedEmployee: IEmployee = employees.results.find(
        (employee: IEmployee) => employee.id === Number(executorOfflineSignature.signatoryId)
      );
      if (selectedEmployee) {
        setExecutorSignatureInfo({
          signatory: {
            fullName: selectedEmployee.full_name,
            position: selectedEmployee.position,
          },
          signature: executorOfflineSignature,
        });
      }
    }
  }, [isOnline, employees, isEmployeesRequestSuccess, executorOfflineSignature]);

  return { customerSignatureInfo, executorSignatureInfo };
}

export default useFetchOfflineSignatures;
