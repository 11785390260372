import qs from "qs";
import { skipToken } from "@reduxjs/toolkit/query/react";

import { useService } from "core/shared/main/api/useService";
import { useGetSystemQuery, useGetSystemsQuery } from "core/app/api";
import { UnitServicesEnum } from "core/shared/unit/types";

export function useOossaSystems() {
  const oossaService = useService(UnitServicesEnum.OOSSA);
  let queryString: null | string = null;
  if (oossaService) {
    queryString = qs.stringify({
      service: oossaService.id,
    });
  }
  const { data } = useGetSystemsQuery(queryString ?? skipToken);
  return data;
}

export function useOossaSystem(id: number | null) {
  const { data } = useGetSystemQuery(id ?? skipToken);
  return data;
}
