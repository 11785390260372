import { createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";

import {
  SignatureSideEnum,
  ITask,
  TaskDiscriminatorEnum,
  TaskSnapMomentEnum,
  TaskTypesIdsEnum,
} from "core/app/api/interfaces";

/** Типы заявок на обслуживание (таблица данных). */
export const taskTypes = [
  {
    id: TaskTypesIdsEnum.PREVENTIVE,
    title: "ППР - Из планирования",
    titleShort: "Из планирования",
    titleSuperShort: "ППР",
  },
  {
    id: TaskTypesIdsEnum.MAINTENANCE,
    title: "ТО - График",
    titleShort: "График ТО",
    titleSuperShort: "ТО",
  },
  {
    id: TaskTypesIdsEnum.RECOVERY,
    title: "АВР",
    titleShort: "АВР",
    titleSuperShort: "АВР",
  },
  {
    id: TaskTypesIdsEnum.OTHER,
    title: "Прочее",
    titleShort: "Прочее",
    titleSuperShort: "Прочее",
  },
  {
    id: TaskTypesIdsEnum.SERVICE_REQUEST,
    title: "ЗНО",
    titleShort: "ЗНО",
    titleSuperShort: "ЗНО",
  },
  {
    id: TaskTypesIdsEnum.ETO,
    title: "ЕТО",
    titleShort: "ЕТО",
    titleSuperShort: "ЕТО",
  },
];

export type TSignatureStatus = SignatureSideEnum | null;

interface ITaskState {
  task: ITask | null;
  taskId: number | null;
  discriminator: TaskDiscriminatorEnum | null;
  signatureStatus: TSignatureStatus;
  momentStatus: null | TaskSnapMomentEnum;
}

const initialState: ITaskState = {
  task: null,
  taskId: null,
  discriminator: null,
  momentStatus: null,
  signatureStatus: null,
};

export const TaskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    setTask(state, action: { payload: ITask | null }) {
      state.task = action.payload;
      if (action.payload === null) {
        state.taskId = null;
        state.signatureStatus = null;
      } else {
        state.taskId = action.payload.id;
      }
    },
    setTaskId(
      state,
      action: {
        payload: {
          id: number | null;
          momentStatus: TaskSnapMomentEnum | null;
          discriminator: TaskDiscriminatorEnum | null;
        };
      }
    ) {
      const { id, momentStatus, discriminator } = action.payload;
      state.taskId = id;
      state.momentStatus = momentStatus;
      state.discriminator = discriminator;
      if (action.payload === null) {
        state.task = null;
        state.signatureStatus = null;
        state.momentStatus = null;
        state.discriminator = null;
      }
    },
    setCheckStatusById(state, action) {
      const { maintenanceExecutionId, checkListItemId, value } = action.payload;

      if (state.task) {
        const maintenanceExecution = state.task.maintenance_execution.find(
          (item) => item.id === maintenanceExecutionId
        );
        if (maintenanceExecution) {
          const checkItem = maintenanceExecution.checklist.find((item) => item.id === checkListItemId);
          if (checkItem) {
            checkItem.value = value;
          }
        }
      }
    },
    setMaintenanceExecutionWorkComment(state, action) {
      const { workItemId, newValue } = action.payload;
      if (state.task) {
        const maintenanceExecution = state.task.maintenance_execution;
        let workItem;
        maintenanceExecution.forEach((maintenanceExecutionItem) => {
          workItem = maintenanceExecutionItem.checklist.find((checkItem) => checkItem.id === workItemId);
          if (!workItem) workItem = maintenanceExecutionItem.values.find((valueItem) => valueItem.id === workItemId);
          if (workItem) {
            workItem.comment = newValue;
          }
        });
      }
    },
    setMaintenanceExecutor(state, action) {
      if (state.task) {
        set(state.task, "maintenance_executor_id", action.payload.id);
        set(state.task, "maintenance_executor", action.payload.id);
      }
    },
    setMaintenanceExecutionValueById(state, action) {
      const { maintenanceExecutionId, valuesItemId, value } = action.payload;
      if (state.task) {
        const maintenanceExecution = state.task.maintenance_execution.find(
          (item) => item.id === maintenanceExecutionId
        );
        if (maintenanceExecution) {
          const valueItem = maintenanceExecution.values.find((item) => item.id === valuesItemId);
          if (valueItem) {
            valueItem.value = value;
          }
        }
      }
    },
    setSignatureStatus(state, action: { payload: TSignatureStatus }) {
      state.signatureStatus = action.payload;
    },
  },
});

export const {
  setTask,
  setMaintenanceExecutionWorkComment,
  setMaintenanceExecutor,
  setTaskId,
  setMaintenanceExecutionValueById,
  setSignatureStatus,
  setCheckStatusById,
} = TaskSlice.actions;
