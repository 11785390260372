import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
}

function InventoryNumber({ task }: FieldProps): ReactElement {
  return <Field label="Инвентарный номер" value={task.system_complex?.inventory_number} />;
}

export { InventoryNumber };
