import { createContext, useContext, useMemo, useState } from "react";

export type TPlanningToolbarContextValues = {
  shouldShowPlan: boolean;
  shouldShowFact: boolean;
  setShowPlan: (show: boolean) => void;
  setShowFact: (show: boolean) => void;
};

export const PlanningToolbarContext = createContext<TPlanningToolbarContextValues>({
  shouldShowPlan: true,
  shouldShowFact: true,
  setShowPlan: (_) => _,
  setShowFact: (_) => _,
});

export function usePlanningToolbarContext() {
  return useContext(PlanningToolbarContext);
}

export function usePlanningToolbarContextValues(): TPlanningToolbarContextValues {
  const [shouldShowPlan, setShowPlan] = useState(true);
  const [shouldShowFact, setShowFact] = useState(true);

  return useMemo(
    () => ({
      shouldShowPlan,
      shouldShowFact,
      setShowPlan,
      setShowFact,
    }),
    [shouldShowPlan, shouldShowFact]
  );
}
