import { Form } from "antd";

import { CheckListItem } from "core/widgets/TaskModals/shared/CheckListItem";

import { ICheckListItemWithIndex } from "../../..";

interface Props {
  checklists: ICheckListItemWithIndex[];
}

export function EquipmentType({ checklists }: Props) {
  return (
    <div className="ml-4">
      {checklists.map((checklistsItem) => (
        <Form.Item key={checklistsItem.id} name={["checklist", checklistsItem.index]}>
          <CheckListItem show value={checklistsItem} />
        </Form.Item>
      ))}
    </div>
  );
}
