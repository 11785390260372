import Text from "core/lib/Text/Text";
import { useGetWhoamiQuery } from "core/app/api";

export function UserName() {
  const { data } = useGetWhoamiQuery("");
  return (
    <div style={{ lineHeight: "16px", textAlign: "center" }}>
      <Text size="label1" boldness="semibold">
        {data?.full_name}
      </Text>
      {data?.position && (
        <>
          <br />
          <Text size="label2" boldness="semibold">
            ({data.position})
          </Text>
        </>
      )}
    </div>
  );
}
