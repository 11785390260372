import { ReactElement } from "react";
import { Table } from "antd";

import { useDashboardShiftStatisticTasksByTeamsOossa } from "core/shared/dashboard/api/useDashboardShiftStatisticTasksByTeamsOossa";
import { IDashboardShiftStatisticTasksByTeamsOossaTask } from "core/app/api/interfaces";
import { IGroup } from "core/shared/dashboard";
import { EffortTypeEnum } from "core/models/enums";

import ClosingNote from "../Fields/ClosingNote";
import Effort from "../Fields/Effort";
import ElapsedTime from "../Fields/ElapsedTime";
import { Comment } from "../Fields/Comment";
import { NodeAndEquipment } from "../Fields/NodeAndEquipment";
import { OossaSystem } from "../Fields/OossaSystem";
import RequestDescription from "../Fields/RequestDescription";

type TableRecord =
  | IDashboardShiftStatisticTasksByTeamsOossaTask
  | IGroup<IDashboardShiftStatisticTasksByTeamsOossaTask>;

const columns = [
  {
    title: "ТУ/ЕД. ОБОРУД.",
    render: (_: string, record: TableRecord) => <NodeAndEquipment record={record} />,
    width: 200,
  },
  {
    title: "ОБЪЕКТ",
    dataIndex: "oossa_object",
  },
  {
    title: "СИСТЕМА",
    dataIndex: "system",
    render: (_: string, record: TableRecord) => <OossaSystem record={record} />,
    width: 100,
  },
  {
    title: "ЛОКАЦИЯ",
    dataIndex: "location",
    width: 100,
  },
  {
    title: "ОПИСАНИЕ ЗАДАЧИ",
    dataIndex: "request_description",
    width: 300,
    render: (_: string, record: TableRecord) => <RequestDescription record={record} />,
  },
  {
    title: "СТАТУС",
    dataIndex: "status",
  },
  {
    title: "КОММЕНТАРИЙ",
    dataIndex: "request_comment",
    render: (_: string, record: TableRecord) => <Comment record={record} />,
  },
  {
    title: "ПРИМЕЧАНИЕ К РАЗРЕШЕНИЮ",
    dataIndex: "closing_note",
    render: (_: string, record: TableRecord) => <ClosingNote record={record} />,
    width: 200,
  },
  {
    title: "ЗАГРУЗКА (ПЛАН)",
    dataIndex: "effort",
    render: (_: string, record: TableRecord) => <Effort record={record} effortType={EffortTypeEnum.HOURS} />,
  },
  {
    title: "ЗАГРУЗКА (ФАКТ)",
    dataIndex: "elapsed_time",
    render: (_: string, record: TableRecord) => <ElapsedTime record={record} effortType={EffortTypeEnum.HOURS} />,
  },
];

export function StatisticByTeamOossaTable(): ReactElement {
  const dataSource = useDashboardShiftStatisticTasksByTeamsOossa();

  return (
    <Table
      expandable={{
        defaultExpandAllRows: true,
        indentSize: 0,
      }}
      size="small"
      dataSource={dataSource}
      rowKey="id"
      columns={columns}
    />
  );
}
