import shift1 from "core/images/shift-1.svg";
import shift2 from "core/images/shift-2.svg";
import shift3 from "core/images/shift-3-dark.svg";
import shift3Light from "core/images/shift-3.svg";

import { UnitServiceCodesEnum } from "./unit/types";

export const dateFor = {
  base: "/",
  plan: "/plan",
};

export enum ShiftSlugsEnum {
  DAY = "DAY",
  EVENING = "EVENING",
  NIGHT = "NIGHT",
  DAY_2 = "DAY_2",
  NIGHT_2 = "NIGHT_2",
}

export const shiftsIcons = {
  [ShiftSlugsEnum.DAY]: {
    img: shift1,
    img2: shift1,
  },
  [ShiftSlugsEnum.DAY_2]: {
    img: shift1,
    img2: shift1,
  },
  [ShiftSlugsEnum.EVENING]: {
    img: shift2,
    img2: shift2,
  },
  [ShiftSlugsEnum.NIGHT]: {
    img: shift3,
    img2: shift3Light,
  },
  [ShiftSlugsEnum.NIGHT_2]: {
    img: shift3,
    img2: shift3Light,
  },
};

/** @deprecated Please use `shiftsIcons` and 'WorkRegime' data instead */
export const shifts: Record<number, { img: string; title: string; img2: string; slug: ShiftSlugsEnum }> = {
  1: {
    img: shift1,
    title: "дневная",
    img2: shift1,
    slug: ShiftSlugsEnum.DAY,
  },
  2: {
    img: shift2,
    title: "вечерняя",
    img2: shift2,
    slug: ShiftSlugsEnum.EVENING,
  },
  3: {
    img: shift3,
    title: "ночная",
    img2: shift3Light,
    slug: ShiftSlugsEnum.NIGHT,
  },
};

export const updateTimeout = 1000;

export const baseUrl = "/api/v1/";

export const space = 8;

/**
 * Роли пользователя
 */
export enum RoleEnum {
  /** Начальник цеха */
  WORKSHOP_LEAD = "workshop_lead",
  /** Начальник цеха ПСПА */
  PSPA_WORKSHOP_LEAD = "pspa_workshop_lead",
  /** Начальник цеха СЛПИ */
  SLPI_WORKSHOP_LEAD = "slpi_workshop_lead", // надеюсь, это в будущем разделится на две отдельные роли
  /** Руководитель службы */
  SERVICE_LEAD = "service_lead",
  /** Руководитель ПСПА */
  PSPA_SERVICE_LEAD = "pspa_service_lead",
  /** Руководитель СЛПИ */
  SLPI_SERVICE_LEAD = "slpi_service_lead",
  /** Представитель заказчика */
  CUSTOMER_REPRESENTATIVE = "customer_representative",
  /** Представитель заказчика с возможностью подписи */
  CUSTOMER_REPRESENTATIVE_SIGNATORY = "customer_representative_signatory",
  /** Старший электромеханик */
  SHIFT_LEAD = "shift_lead",
  /** Начальник участка */
  UNIT_LEAD = "unit_lead",
  /** Бригадир (производитель работ) */
  TEAM_LEAD = "team_lead",
  /** Машинист */
  DRIVER = "driver",
  /** Надёжник */
  RELIABILITY_ENGINEER = "reliability_engineer",
  /** Редактор справочников */
  CATALOG_ADMIN = "catalog_admin",
}

/**
 * Наборы наиболее часто используемых ролей
 */
/**
 * Все роли за исключением представителя заказчика и редактора справочников
 */
export const rolesWitoutRepresentativesAndCatalogAdmin = Object.values(RoleEnum).filter(
  (value: RoleEnum) =>
    ![RoleEnum.CUSTOMER_REPRESENTATIVE_SIGNATORY, RoleEnum.CUSTOMER_REPRESENTATIVE, RoleEnum.CATALOG_ADMIN].includes(
      value
    )
);

/**
 * Все роли за исключением производителя работ и старших электромехаников
 */
export const rolesWithoutTeamLeadAndShiftLead = Object.values(RoleEnum).filter(
  (value: RoleEnum) => ![RoleEnum.TEAM_LEAD, RoleEnum.SHIFT_LEAD].includes(value)
);

/**
 * Роли нач. цеха и нач службы без разграничения по услуге.
 * Услуга должна проверяться отдельно и не входить в название роли.
 * Позже эти роль должны быть объеденены в одну роль.
 */
export const workShopRoles = [RoleEnum.PSPA_WORKSHOP_LEAD, RoleEnum.SLPI_WORKSHOP_LEAD];
export const serviceLeadRoles = [RoleEnum.PSPA_SERVICE_LEAD, RoleEnum.SLPI_SERVICE_LEAD];

export const SERVICE_CODE_TO_NAME_MAP: Record<UnitServiceCodesEnum, string> = {
  [UnitServiceCodesEnum.LKU]: "ЛКУ",
  [UnitServiceCodesEnum.LSO]: "ЛСО",
  [UnitServiceCodesEnum.VOLS]: "ВОЛС",
  [UnitServiceCodesEnum.OOSSA]: "ООССА",
  [UnitServiceCodesEnum.PSPA_KF]: "ПСПА КФ",
  [UnitServiceCodesEnum.PSPA_ZF]: "ПСПА ЗФ",
  [UnitServiceCodesEnum.SLPI_KF]: "СЛПИ КФ",
  [UnitServiceCodesEnum.SLPI_ZF]: "СЛПИ ЗФ",
};
