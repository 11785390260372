import { Input, Form, FormInstance } from "antd";
import { ReactElement } from "react";

import { StateNamesEnum } from "core/app/api/interfaces";

const { TextArea } = Input;

interface ClosingNoteTextAreaProps {
  disabled: boolean;
  form: FormInstance;
}

function ClosingNoteTextArea({ disabled, form }: ClosingNoteTextAreaProps): ReactElement {
  return (
    <Form.Item
      label="ПРИМЕЧАНИЕ К ЗАКРЫТИЮ"
      name="closing_note"
      rules={[
        {
          validator: (_, value) => {
            const newState = form.getFieldValue("newState");
            if (newState === StateNamesEnum.Closed && !value) {
              return Promise.reject(new Error("Пожалуйста, введите ПРИМЕЧАНИЕ К ЗАКРЫТИЮ"));
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <TextArea rows={5} disabled={disabled} />
    </Form.Item>
  );
}

export default ClosingNoteTextArea;
