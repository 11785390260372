import { apiSlice, extractResults } from "core/app/api/api";

import { IResponseBody, ITask } from "../interfaces";

import {
  IApproveLkuMonthPlanPayload,
  ILkuMonthPlan,
  ILkuMonthPlanApprovalState,
  ILkuYearPlan,
  ILkuYearPlanByObject,
  IUpdateLkuMonthPlanPayload,
  IUpdateLkuYearPlanPayload,
  ILkuRack,
  ILkuObject,
  ILkuEquipment,
  IApproveLkuMonthPlanResponse,
  IMakeLkuMonthSchedulePayload,
  ILkuMonthStat,
  ILkuYearPlansByObjectsStat,
} from "./interfaces";

const lkuApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLkuYearPlansByObjects: builder.query<IResponseBody<ILkuYearPlanByObject>, string>({
      query: (queryString) => `lku-year-plans-by-objects/?${queryString}`,
      providesTags: ["lku-year-plans-by-objects"],
    }),
    getLkuYearPlans: builder.query<IResponseBody<ILkuYearPlan>, string>({
      query: (queryString) => `lku-year-plans/?${queryString}`,
      providesTags: ["lku-year-plans"],
    }),
    getLkuMonthPlans: builder.query<ILkuMonthPlan[], string>({
      query: (queryString) => `lku-month-plans/?${queryString}`,
      providesTags: ["lku-month-plans"],
      transformResponse: extractResults,
    }),
    getLkuYearPlansByObjectsStat: builder.query<ILkuYearPlansByObjectsStat, string>({
      query: (queryString) => `lku-year-plans-by-objects/lku-stat/?${queryString}`,
      providesTags: ["lku-year-plans/stat"],
    }),
    getLkuMonthPlansStat: builder.query<ILkuMonthStat, string>({
      query: (queryString) => `lku-month-plans/stat/?${queryString}`,
      providesTags: ["lku-month-plans/stat"],
    }),
    getLkuObjects: builder.query<ILkuObject[], string>({
      query: (queryString) => `lku-objects/?${queryString}`,
      providesTags: ["lku-objects"],
      transformResponse: extractResults,
    }),
    getLkuObject: builder.query<ILkuObject, number>({
      query: (id) => `lku-objects/${id}/`,
      providesTags: ["lku-objects"],
    }),
    getLkuRacks: builder.query<ILkuRack[], string>({
      query: (queryString) => `lku-racks/?${queryString}`,
      providesTags: ["lku-racks"],
      transformResponse: extractResults,
    }),
    getLkuRack: builder.query<ILkuRack, number>({
      query: (id) => `lku-racks/${id}/`,
      providesTags: ["lku-racks"],
    }),
    getLkuEquipments: builder.query<ILkuEquipment[], string>({
      query: (queryString) => `lku-equipments/?${queryString}`,
      providesTags: ["lku-equipments"],
      transformResponse: extractResults,
    }),
    getLkuEquipment: builder.query<ILkuEquipment, number>({
      query: (id) => `lku-equipments/${id}/`,
      providesTags: ["lku-equipments"],
    }),
    updateLkuYearPlan: builder.mutation<ILkuYearPlan, IUpdateLkuYearPlanPayload>({
      query: (payload) => ({
        url: `lku-year-plans/${payload.id}/`,
        method: "PATCH",
        body: {
          plans_by_months: payload.plans_by_months,
        },
      }),
      invalidatesTags: [
        "lku-year-plans-by-objects",
        "lku-year-plans/stat",
        "lku-year-plans",
        "lku-month-plans",
        "lku-month-plans/stat",
      ],
    }),
    createLkuTask: builder.mutation({
      query: (body) => ({
        url: "lku-tasks/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["tasks", "open-tasks", "shifttask", "tasks-filtered", "kanban-board"],
    }),
    updateLkuMonthPlan: builder.mutation<void, IUpdateLkuMonthPlanPayload>({
      query: (payload) => ({
        url: `lku-month-plans/${payload.id}/`,
        method: "PATCH",
        body: payload.body,
      }),
      invalidatesTags: ["lku-month-plans", "lku-month-plans/stat"],
    }),
    approveLkuMonthPlan: builder.mutation<IApproveLkuMonthPlanResponse, IApproveLkuMonthPlanPayload>({
      query(payload) {
        return {
          url: `lku-month-plans/approve/`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["lku-year-plans", "lku-month-plan-status", "lku-month-plans"],
    }),
    undoApproveLkuMonthPlan: builder.mutation<string, IApproveLkuMonthPlanPayload>({
      query(payload) {
        return {
          url: `lku-month-plans/undo-approve/`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["lku-year-plans", "lku-month-plan-status", "lku-month-plans"],
    }),
    getLkuMonthPlanApprovalState: builder.query<ILkuMonthPlanApprovalState[], string>({
      query: (queryString) => `lku-month-plan-status/?${queryString}`,
      providesTags: ["lku-month-plan-status"],
      transformResponse: extractResults,
    }),
    updateLkuTask: builder.mutation({
      query: (task) => ({
        url: `lku-tasks/${task.id}/`,
        method: "PATCH",
        body: task,
      }),
      async onQueryStarted({ queryString, ...taskUpdate }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData("getOpenTasks", queryString, (cachedTasks) => {
            const cachedTaskToUpdate = cachedTasks.results.find((cachedTask: ITask) => cachedTask.id === taskUpdate.id);
            if (cachedTaskToUpdate) {
              Object.assign(cachedTaskToUpdate, taskUpdate);
            }
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: "task", id: arg.id },
        "tasks",
        "shifttask",
        "tasks-filtered",
        "kanban-board",
        "month-plan",
        "tasks/statistics-by-days",
        "dashboard/shift-statistic/tasks",
        "dashboard/shift-statistic/tasks/by-equipment-types",
        "dashboard/shift-statistic/tasks/by-objects/slpi",
      ],
    }),

    makeLkuMonthSchedule: builder.mutation<any, IMakeLkuMonthSchedulePayload>({
      query(payload) {
        return {
          url: `lku-month-plans/make-schedule/`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["lku-month-plans", "lku-month-plans/stat"],
    }),
  }),
});

export const {
  useGetLkuYearPlansByObjectsStatQuery,
  useUpdateLkuYearPlanMutation,
  useGetLkuYearPlansByObjectsQuery,
  useGetLkuYearPlansQuery,
  useGetLkuMonthPlansQuery,
  useApproveLkuMonthPlanMutation,
  useUndoApproveLkuMonthPlanMutation,
  useGetLkuMonthPlanApprovalStateQuery,
  useUpdateLkuMonthPlanMutation,
  useGetLkuObjectsQuery,
  useGetLkuObjectQuery,
  useGetLkuRacksQuery,
  useGetLkuRackQuery,
  useGetLkuEquipmentsQuery,
  useGetLkuEquipmentQuery,
  useUpdateLkuTaskMutation,
  useCreateLkuTaskMutation,
  useMakeLkuMonthScheduleMutation,
  useGetLkuMonthPlansStatQuery,
} = lkuApiSlice;
