import { Button, Row, Col } from "antd";
import { ReactElement } from "react";

import Text from "core/lib/Text/Text";
import { ITask, StateNamesEnum } from "core/app/api/interfaces";
import { useGetActMutation } from "core/app/api";
import { PlannedDate, Customer, Unit, Location, AccumulatorElapsedTime } from "core/widgets/TaskModals/shared/Fields";
import { useNetworkState } from "core/shared/main/api/useNetworkState";
import LocationSelect from "core/widgets/TaskModals/shared/Mounted/LocationSelect";
import RepairAreaSelect from "core/widgets/TaskModals/shared/Mounted/RepairAreaSelect";

interface GeneralInfoProps {
  task: ITask;
}

/** Заглавная часть (раздел) в карточке ТО для навесного оборудования ПСПА. */
function GeneralInfo(props: GeneralInfoProps): ReactElement {
  const { task } = props;
  const [getActRequest, getActResult] = useGetActMutation();
  const { isOnline } = useNetworkState();

  const handlePrintClick = () => {
    getActRequest(task.id);
  };

  return (
    <>
      <Row gutter={16} className="mb-2">
        <Col span={12}>
          <LocationSelect />
        </Col>
        <Col span={12}>
          <RepairAreaSelect />
        </Col>
      </Row>
      <Row gutter={16} className="mb-1">
        <Col span={11}>
          <PlannedDate task={task} />
          <Customer task={task} />
          <Unit task={task} />
          <Location task={task} />
          <AccumulatorElapsedTime task={task} />
        </Col>
        <Col span={13}>
          <Row justify="end">
            <Col>
              {task?.state?.name === StateNamesEnum.Closed && (
                <Button disabled={!isOnline} onClick={handlePrintClick} loading={getActResult.isLoading}>
                  ПЕЧАТЬ АКТА
                </Button>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Text size="label3">Системы</Text>
            </Col>
          </Row>
          <Row>
            {task.maintenance_execution?.map((item) => (
              <Col className="mr-2" key={item.equipment_type.id}>
                <Text size="label1" boldness="black">
                  {item.equipment_type.name}
                </Text>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default GeneralInfo;
