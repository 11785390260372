import React from "react";
import {
  FileExcelOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  FileWordOutlined,
  VideoCameraOutlined,
  MailOutlined,
} from "@ant-design/icons";

interface FileTypeIconProps {
  originalName: string;
  mimeType: string;
  size?: number;
}

function FileTypeIcon({ originalName, mimeType, size = 22 }: FileTypeIconProps) {
  let icon = <FileTextOutlined style={{ fontSize: size }} />;
  const typeIcon = originalName.split(".").pop();

  const iconType: Record<string, any> = {
    image: <FileImageOutlined style={{ fontSize: size }} />,
    video: <VideoCameraOutlined style={{ fontSize: size }} />,
    pdf: <FilePdfOutlined style={{ fontSize: size }} />,
    docx: <FileWordOutlined style={{ fontSize: size }} />,
    doc: <FileWordOutlined style={{ fontSize: size }} />,
    xlsx: <FileExcelOutlined style={{ fontSize: size }} />,
    xls: <FileExcelOutlined style={{ fontSize: size }} />,
    msg: <MailOutlined style={{ fontSize: size }} />,
  };

  if (mimeType.includes("image")) {
    icon = iconType.image;
  } else if (mimeType.includes("video")) {
    return iconType.video;
  } else if (typeIcon && Object.prototype.hasOwnProperty.call(iconType, typeIcon)) {
    icon = iconType[typeIcon];
  }

  return icon;
}

export default FileTypeIcon;
