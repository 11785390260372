import { Row, Col, Input, Form, InputNumber } from "antd";
import { ReactElement } from "react";

import Text from "core/lib/Text/Text";
import { IMaintenanceExecution } from "core/app/api/interfaces";
import { useDisabled } from "core/shared/task/useDisabled";
import { CheckListItem } from "core/widgets/TaskModals/shared/CheckListItem";

const { TextArea } = Input;

interface Props {
  maintenanceExecution: IMaintenanceExecution;
  index: number;
}

export function MaintenanceExecution({ maintenanceExecution, index }: Props): ReactElement {
  const disabled = useDisabled();

  return (
    <Row gutter={16}>
      <Col span={13}>
        <Form.Item name={["maintenance_execution", index, "id"]} label="id" hidden>
          <InputNumber />
        </Form.Item>
        {/* На форме не используется, но на бэке это поле обязательное */}
        <Form.Item name={["maintenance_execution", index, "values"]} label="values" hidden>
          <InputNumber />
        </Form.Item>
        <div className="mb-3">
          <div className="mb-1">
            <Text size="label1" boldness="semibold">
              РАБОТЫ
            </Text>
          </div>
          {maintenanceExecution.checklist.map((checkItem, checkItemIndex) => (
            <Form.Item
              style={{ marginBottom: 8 }}
              key={checkItem.id}
              name={["maintenance_execution", index, "checklist", checkItemIndex]}
            >
              <CheckListItem key={checkItem.id} show />
            </Form.Item>
          ))}
        </div>
      </Col>
      <Col span={11}>
        <div>
          <Form.Item name={["maintenance_execution", index, "comment"]} label="КОММЕНТАРИЙ">
            <TextArea rows={5} disabled={disabled} />
          </Form.Item>
        </div>
        <div>
          <Form.Item name={["maintenance_execution", index, "used_materials"]} label="ИСПОЛЬЗУЕМЫЕ МАТЕРИАЛЫ">
            <TextArea rows={5} disabled={disabled} />
          </Form.Item>
        </div>
      </Col>
    </Row>
  );
}
