import { Row, Col, FormInstance, Form, Input } from "antd";
import { ReactElement } from "react";

import { useDisabled } from "core/shared/task/useDisabled";
import { useUnit } from "core/shared/unit/useUnit";

import UsedMaterialsTextArea from "../../shared/UsedMaterialsTextArea";
import { ElapsedTime } from "../../shared/ElapsedTime";

const { TextArea } = Input;

interface BodyProps {
  form: FormInstance;
}

function Body({ form }: BodyProps): ReactElement {
  const isDisabled = useDisabled();
  const unit = useUnit();

  const shouldShowMultiplier = !(unit?.isSLPI() || unit?.isLSO() || unit?.isLKU());

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Row>
          <Col span={12}>
            <ElapsedTime useMultiplier={shouldShowMultiplier} disabled={isDisabled} />
          </Col>
        </Row>
        <div>
          <Form.Item
            label="КОММЕНТАРИЙ"
            name="request_comment"
            required
            rules={[
              {
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject(new Error("Пожалуйста, введите КОММЕНТАРИЙ"));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <TextArea rows={5} disabled={isDisabled} />
          </Form.Item>
        </div>
        <div>
          <UsedMaterialsTextArea disabled={isDisabled} />
        </div>
      </Col>
    </Row>
  );
}

export default Body;
