interface IObjectNameProps {
  object: {
    name: string;
    inventory_number: string;
  };
}

function ObjectName({ object }: IObjectNameProps) {
  return (
    <>
      {object.name} <br />
      {object.inventory_number}
    </>
  );
}

export { ObjectName };
