import { IWorkRegime } from "core/app/api/interfaces";
import { useAppSelector } from "core/app/store";
import { useUnitShifts } from "core/shared/unit/useUnitShifts";

export const useCurrentShift = (shiftNumber?: number): IWorkRegime | null => {
  const currentShift = useAppSelector((state) => state.main.shift);
  const { data: unitShifts } = useUnitShifts();

  if (!shiftNumber) {
    return currentShift;
  }

  return unitShifts?.find((shift) => shift.number === shiftNumber) || null;
};
