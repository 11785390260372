import { Select, Form, FormInstance } from "antd";
import { ReactElement } from "react";

import { useUnitObjects } from "core/shared/slpi/api/useUnitObjects";

interface ObjectSelectProps {
  disabled: boolean;
  form: FormInstance;
}

function ObjectSelect({ disabled, form }: ObjectSelectProps): ReactElement {
  const customerDepartmentId = Form.useWatch("customer_department_id");

  const { data: objects } = useUnitObjects({ customer_department_id: customerDepartmentId });

  let options = [
    {
      label: "",
      value: 0,
    },
  ];
  if (objects) {
    options = objects.map((object) => {
      let label = "";
      if (object.inventory_number) {
        label = `(${object.inventory_number}) ${object.name}`;
      } else {
        label = `${object.name}`;
      }
      return {
        label,
        value: object.id,
      };
    });
  }

  return (
    <Form.Item label="ОБЪЕКТ" name="object_id">
      <Select
        disabled={disabled}
        style={{ width: "100%" }}
        showSearch
        allowClear
        optionFilterProp="children"
        dropdownStyle={{ zIndex: 2000 }}
        filterOption={(input, option) => (option?.label?.toString() ?? "").toLowerCase().includes(input.toLowerCase())}
        options={options}
        onChange={(value) => {
          if (value) {
            const newDepartmentId = objects?.find((object) => object.id === value)?.customer_department;
            form.setFieldValue("customer_department_id", newDepartmentId);
            form.setFieldValue("system_complex_id", null);
          }
        }}
      />
    </Form.Item>
  );
}

export { ObjectSelect };
