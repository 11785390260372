import { useCallback } from "react";

import { useAppDispatch, useAppSelector } from "core/app/store";
import { setTaskId } from "core/app/store/task-slice";
import { TaskDiscriminatorEnum, TaskSnapMomentEnum } from "core/app/api/interfaces";

export function useTaskId(): [
  number | null,
  (id: number | null, momentStatus?: TaskSnapMomentEnum | null, discriminator?: TaskDiscriminatorEnum | null) => void,
  TaskSnapMomentEnum | null,
  () => void
] {
  const dispatch = useAppDispatch();
  const { taskId, momentStatus } = useAppSelector((state) => state.task);

  const set = useCallback(
    (
      id: number | null,
      moment: TaskSnapMomentEnum | null = null,
      discriminator: TaskDiscriminatorEnum | null = null
    ) => {
      dispatch(setTaskId({ id, momentStatus: moment, discriminator }));
    },
    [dispatch]
  );

  const resetState = useCallback(() => {
    dispatch(setTaskId({ id: null, momentStatus: null, discriminator: null }));
  }, [dispatch]);

  return [taskId, set, momentStatus, resetState];
}
