import { IWorkingRhythm } from "core/app/api/interfaces";

/** Услуга (перечисление наименований).
 * Услуга соответствует структурному подразделению НН Спутник, которое предоставляет услуги ТОиР: ПСПА, СЛПИ, ООССА... */
export enum UnitServicesEnum {
  SLPI = "СЛПИ",
  PSPA = "ПСПА",
  OOSSA = "ООССА",
  MURT = "МУР-Т",
  SDGR = "СДГР",
  LSO = "ЛСО",
  LKU = "ЛКУ",
  VOLS = "ВОЛС",
  TConnection = "Технологическая связь",
}

export enum UnitServiceCodesEnum {
  SLPI_ZF = "slpi_zf",
  SLPI_KF = "slpi_kf",
  PSPA_KF = "pspa_kf",
  PSPA_ZF = "pspa_zf",
  OOSSA = "oossa",
  LKU = "lku",
  LSO = "lso",
  VOLS = "vols",
}

/** Участок, на котором проводятся работы ТОиР. */
export interface IUnit {
  id: number;
  name: string;
  efficiency: number | null;
  working_rhythms: IWorkingRhythm[] | null;
  monthly_plans_require_approval: boolean;
  service: {
    id: number;
    name: UnitServicesEnum;
    code: UnitServiceCodesEnum;
  };
  isSLPI: () => boolean;
  isSLPIKF: () => boolean;
  isPSPA: () => boolean;
  isOOSSA: () => boolean;
  isPSS: () => boolean;
  isLSO: () => boolean;
  isLKU: () => boolean;
  isVOLS: () => boolean;
  isPSPAZF: () => boolean;
  isPSPAKF: () => boolean;
}
