import { useContext } from "react";
import qs from "qs";

import { useGetDashboardShiftStatisticQuery as useRTQGetDashboardShiftStatisticQuery } from "core/app/api";
import { ShiftContext } from "core/shared/dashboard/context/shiftContext";
import { useUnitId } from "core/shared/unit/useUnitId";
import { apiDateFormat } from "core/shared/tools/dayjs";

const useGetDashboardShiftStatisticQuery = () => {
  const { date, teamId, shiftNum } = useContext(ShiftContext);
  const [unitId] = useUnitId();

  let queryString = "";
  if (unitId && shiftNum && date) {
    const queryParams: Record<string, string | number> = {
      unit_id: unitId,
      shift_num: shiftNum,
      date: date?.format(apiDateFormat),
    };
    if (teamId) {
      queryParams.team = teamId;
    }
    queryString = qs.stringify(queryParams);
  }

  return useRTQGetDashboardShiftStatisticQuery(queryString, {
    skip: !queryString,
  });
};

export { useGetDashboardShiftStatisticQuery };
