import { IEtoTask } from "core/app/api/interfaces";

import { Field } from "../Field";

type TFactoryNumberProps = {
  task: IEtoTask;
};

export function FactoryNumber({ task }: TFactoryNumberProps) {
  return <Field label="Заводской номер" value={task.transport?.serial_number} />;
}
