import { useEffect, useState } from "react";
import { Row, Col, Switch, Form } from "antd";

import Media from "core/widgets/TaskModals/shared/Media";
import { useTask } from "core/shared/task/useTask";
import Text from "core/lib/Text/Text";
import { useDisabled } from "core/shared/task/useDisabled";

import { Comment } from "./Comment";
import { ReasonSelect } from "./ReasonSelect";

function MediaOrComment() {
  const [photo, setPhoto] = useState(true);
  const { data: task } = useTask();
  const disabled = useDisabled();

  useEffect(() => {
    if (task?.no_photo_reason_type_id) {
      setPhoto(false);
    }
  }, [task?.no_photo_reason_type_id]);

  if (!task) {
    return <div />;
  }

  return (
    <>
      <Row gutter={8} style={{ alignItems: "baseline" }}>
        <Col>
          <Form.Item name="no_photo" valuePropName="checked">
            <Switch
              disabled={disabled}
              onChange={() => {
                setPhoto(!photo);
              }}
            />
          </Form.Item>
        </Col>
        <Col>
          <Text boldness="semibold" size="label2">
            Не требует фото и видео фиксации
          </Text>
        </Col>
      </Row>
      {photo ? (
        <Media task={task} />
      ) : (
        <>
          <div className="mb-2">
            <ReasonSelect />
          </div>
          <Comment />
        </>
      )}
    </>
  );
}

export { MediaOrComment };
