import { Space } from "antd";
import { useEffect, useState } from "react";

import { ILocalAttachment } from "core/app/api/interfaces";

import UploadButton from "./UploadButton";
import "./styles.scss";
import AttachmentListItem from "./AttachmentListItem";
import AttachmentViewer from "./AttachmentViewer";

type TAttachmentListProps = {
  value?: ILocalAttachment[];
  onChange?: (attachments: ILocalAttachment[]) => void;
};

function AttachmentsList({ value, onChange }: TAttachmentListProps) {
  const [attachments, setAttachments] = useState<ILocalAttachment[]>(value || []);
  const [selectedAttachmentId, setSelectedAttachmentId] = useState("");

  const isViewerVisible = Boolean(selectedAttachmentId);

  const handleDelete = (attachmentToDelete: ILocalAttachment) => {
    setAttachments((prevAttachments) =>
      prevAttachments.filter((attachment) => attachment.uid !== attachmentToDelete.uid)
    );
  };

  const handleSelect = (attachmentToSelect: ILocalAttachment) => {
    setSelectedAttachmentId(attachmentToSelect.uid);
  };

  const handleViewerClose = () => {
    setSelectedAttachmentId("");
  };

  useEffect(() => {
    onChange?.(attachments);
  }, [attachments, onChange]);

  return (
    <>
      <Space className="attachments-list" size={16}>
        <UploadButton
          onFileUpload={(file) => {
            setAttachments((prevAttachments) => prevAttachments.concat([file]));
          }}
        />
        {attachments.map((attachment) => (
          <AttachmentListItem
            key={attachment.uid}
            attachment={attachment}
            onDelete={handleDelete}
            onSelect={handleSelect}
          />
        ))}
      </Space>
      <AttachmentViewer
        attachments={attachments}
        selectedAttachmentId={selectedAttachmentId}
        onClose={handleViewerClose}
        open={isViewerVisible}
      />
    </>
  );
}

export default AttachmentsList;
