import { Select, Form, FormInstance } from "antd";
import { ReactElement } from "react";

import { StateNamesEnum } from "core/app/api/interfaces";
import { useGetItsmClosingCodesQuery } from "core/app/api";

interface ClosingCodeSelectProps {
  disabled: boolean;
  form: FormInstance;
}

function ClosingCodeSelect({ disabled, form }: ClosingCodeSelectProps): ReactElement {
  const { data: closingCodes } = useGetItsmClosingCodesQuery("type=incident_task");

  let options: {
    label: string;
    value: number;
  }[] = [];

  if (closingCodes) {
    options = closingCodes.map((closingCode) => ({
      label: closingCode.name,
      value: closingCode.id,
    }));
  }

  return (
    <Form.Item
      label="КОД ЗАКРЫТИЯ"
      name="closing_code_id"
      rules={[
        {
          validator: (_, value) => {
            const newState = form.getFieldValue("newState");
            if (newState === StateNamesEnum.Closed && !value) {
              return Promise.reject(new Error("Пожалуйста, введите КОД ЗАКРЫТИЯ"));
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <Select
        disabled={disabled}
        style={{ width: "100%" }}
        dropdownStyle={{ zIndex: 2000 }}
        options={options}
        allowClear
      />
    </Form.Item>
  );
}

export default ClosingCodeSelect;
