import { v4 } from "uuid";

import { getUniqueArray } from "./getUniqueArray";
import { getIsWeekend } from "./isWeekend";
import { randomInt } from "./randomInt";
import { getTaskAvailableStates } from "./getTaskAvailableStates";
import { getShortEmployeeName } from "./getShortEmployeeName";

export const getUuid = () => v4();
export { getUniqueArray, getIsWeekend, randomInt, getTaskAvailableStates, getShortEmployeeName };
