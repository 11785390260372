import { useEffect, useCallback } from "react";

import { useGetUnitsQuery } from "core/app/api";
import { useAppSelector, useAppDispatch } from "core/app/store";
import { setUnitId } from "core/app/store/main-slice";

import { UnitServiceCodesEnum } from "../types";

const useUnitId = (): [
  number | null,
  (id: number, code?: UnitServiceCodesEnum | null) => void,
  UnitServiceCodesEnum | null
] => {
  const { data } = useGetUnitsQuery("");
  const dispatch = useAppDispatch();
  const { unitId, serviceCode } = useAppSelector((state) => state.main);

  const set = useCallback(
    (id: number, code: UnitServiceCodesEnum | null = null) => {
      dispatch(setUnitId({ id, code }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (data && unitId === null) {
      set(data[0].id);
    }
  }, [data, set, unitId]);

  return [unitId, set, serviceCode];
};

export { useUnitId };
