import { ReactElement } from "react";
import { Row, Col } from "antd";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import LegendBullet from "core/widgets/LegendBullet";
import Text from "core/lib/Text/Text";
import { IAggregation } from "core/app/api/interfaces";
import { chartColorGreen, chartColorYellow, chartColorMagenta } from "uikit/themes/default/colors";

ChartJS.register(ArcElement, Tooltip, Legend);

interface IStateProps {
  aggregations: IAggregation[] | undefined;
}

const colors = [chartColorYellow, "#8BB9FF", chartColorGreen, chartColorMagenta];

function State({ aggregations }: IStateProps): ReactElement {
  return (
    <>
      <div className="mb-1">
        <Text className="uppercase" boldness="semibold">
          Статус
        </Text>
      </div>
      <Row>
        <Col style={{ width: "120px", height: "120px" }}>
          <Doughnut
            data={{
              datasets: [
                {
                  data: aggregations?.map((item) => item.amount),
                  backgroundColor: aggregations?.map((_, index) => colors[index]),
                },
              ],
            }}
          />
        </Col>
        <Col>
          <table>
            <tbody>
              {aggregations?.map((item, index) => (
                <tr key={item.key}>
                  <td>
                    <LegendBullet color={colors[index]} />
                  </td>
                  <td>
                    <Text size="label3" boldness="semibold" className="uppercase mr-3">
                      {item.verbose_name}
                    </Text>
                  </td>
                  <td>
                    <Text size="label3" boldness="semibold">
                      {item.amount} шт.
                    </Text>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
    </>
  );
}

export default State;
