import { ReactElement } from "react";
import { Space } from "antd";

import Text, { TSize } from "core/lib/Text/Text";
import { ITask } from "core/app/api/interfaces";
import TaskUrgency from "core/widgets/TaskUrgency";
import { TaskCaption } from "core/widgets/TaskModals/shared/TaskCaption";

interface TaskTitleProps {
  task: ITask;
  icon?: boolean;
  size?: TSize;
}

/** Иконка и заголовок карточки задания (ТО подвесных, ТО стационарных, АВР, доп. работ). */
function TaskTitle(props: TaskTitleProps): ReactElement {
  const { task, icon, size } = props;
  return (
    <div className="mb-1" id={`${task.id}`}>
      <Space size={16}>
        {Boolean(icon && task.request_priority) && <TaskUrgency requestPriority={task.request_priority} />}
        <Text className="name uppercase" size={size ?? "h4"} boldness="black">
          <TaskCaption task={task} />
        </Text>
      </Space>
    </div>
  );
}

export default TaskTitle;
