import AssistanceCarMaintenance from "./AssistanceCarMaintenance";

import "./styles.scss";

/**
 * Модальное окно для отображения задач по обслуживанию "Хозяек"
 * @returns
 */
function AssistanceCarTaskModal() {
  return (
    <>
      <AssistanceCarMaintenance />
      {/* <WarningModal /> */}
    </>
  );
}

export default AssistanceCarTaskModal;
