import { useMemo } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { useGetUnitQuery } from "core/app/api";
import { IUnit } from "core/shared/unit/types";

import { useUnitId } from "../useUnitId";
import { isSLPI, isPSPA, isOOSSA, isPSS, isLKU, isLSO, isVOLS, isPSPAZF, isPSPAKF, isSLPIKF } from "../tools";

const useUnit = (unitId?: number | null): IUnit | undefined => {
  const [stateUnitId] = useUnitId();
  if (!unitId) {
    unitId = stateUnitId;
  }
  const { data } = useGetUnitQuery(unitId ?? skipToken);

  const unit = useMemo(() => {
    if (data) {
      return {
        ...data,
        isSLPI,
        isPSPA,
        isOOSSA,
        isPSS,
        isLKU,
        isLSO,
        isVOLS,
        isPSPAZF,
        isPSPAKF,
        isSLPIKF,
      };
    }
    return undefined;
  }, [data]);

  return unit;
};

export { useUnit };
