import { ReactNode, useLayoutEffect, useRef } from "react";
import { Row, Col, Tabs } from "antd";
import { TabBarExtraContent } from "rc-tabs/lib/interface";

import { useGetUnitsQuery } from "core/app/api";
import { useUnitId } from "core/shared/unit/useUnitId";
import Text from "core/lib/Text/Text";
import useWindowSize from "core/shared/useWindowSize";

interface UnitsTabsProps {
  tab: ReactNode;
  tabBarExtraContent?: TabBarExtraContent;
  responsive?: boolean;
}

/** Список участков в виде закладок, одну из которых можно выбрать. */
function UnitsTabs({ tab: Tab, tabBarExtraContent, responsive = false }: UnitsTabsProps) {
  const [unitId, setUnitId] = useUnitId();
  const { data: units } = useGetUnitsQuery("");
  const handleTabChange = (key: string) => {
    const activeUnit = units?.find((item) => item.id === +key);
    const id = parseInt(key, 10);
    setUnitId(id, activeUnit?.service.code);
  };
  const tabsRef = useRef<HTMLDivElement>(null);
  const [windowWidth] = useWindowSize();

  useLayoutEffect(() => {
    const node = tabsRef.current;
    if (responsive && units && node) {
      const tabList: any = node.getElementsByClassName("ant-tabs-nav-list")[0];

      if (tabList) {
        tabList.style.maxWidth = `${windowWidth - 110}px`;
      }
    }
  }, [units, windowWidth, responsive]);

  return (
    <Row ref={tabsRef}>
      <Col span={24}>
        <Text size="label2" boldness="semibold">
          УЧАСТОК
        </Text>
        {units && (
          <Tabs
            className="unit-tabs"
            activeKey={`${unitId}`}
            onChange={handleTabChange}
            items={units.map((unit) => ({
              key: `${unit.id}`,
              label: unit.name,
              children: Tab,
            }))}
            tabBarExtraContent={tabBarExtraContent}
          />
        )}
      </Col>
    </Row>
  );
}

export { UnitsTabs };
