import type { RcFile } from "antd/es/upload/interface";
import imageCompression from "browser-image-compression";

export async function imageCompressionHandler(file: RcFile | File) {
  const options = {
    maxWidthOrHeight: 1920,
    useWebWorker: false,
    initialQuality: 0.5,
    fileType: "image/jpeg",
    maxIteration: 10,
  };
  const blobCompress = await imageCompression(file, options);
  return new File([blobCompress], `${blobCompress.name}`, {
    type: "image/jpeg",
  });
}
