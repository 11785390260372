/* Значения енама кириллические */
export enum LSOMaintenanceTypeEnum {
  ETO = "ЕТО",
  TO_1 = "ТО1",
  TO_2 = "ТО2",
}

export enum LsoWorkTypeGroupEnum {
  RESEARCH = "research",
  WORK = "work",
  INSTRUCTION = "instruction",
}
