import { Input, InputProps } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import InputMask from "react-input-mask";
import { ChangeEventHandler, useEffect, useRef, useState } from "react";
import { isNil } from "lodash";

import { useDebounce } from "core/shared/useDebounce";

interface TimeInputProps {
  value?: string;
  onChange?: (arg: any) => void;
  disabled?: boolean;
  size?: InputProps["size"];
  debouceDelay?: number;
}

const timeDivider = ":";

function getValueWithoutSeconds(value: TimeInputProps["value"]): string | undefined {
  if (!value) {
    return undefined;
  }
  const [hours, minutes] = value.split(timeDivider);

  return [hours, minutes].join(timeDivider);
}

export function TimeInput(props: TimeInputProps) {
  const { value, onChange, disabled: isDisabled, size, debouceDelay, ...rest } = props;

  const valueWithoutSeconds = getValueWithoutSeconds(value);

  const [localValue, setLocalValue] = useState(valueWithoutSeconds);
  const debouncedValue = useDebounce(localValue, debouceDelay);

  /** Ref для хранения предыдущих значений value и onChange
   * (Позволяет вызывать обработчик onChange только тогда,
   * когда изменилось  debouncedValue) */
  const prevValueRef = useRef<string | undefined>(valueWithoutSeconds);
  const onChangeRef = useRef<TimeInputProps["onChange"] | undefined>(onChange);

  /** Обновляем ref, при изменении onChange */
  useEffect(() => {
    if (onChange !== onChangeRef.current) {
      onChangeRef.current = onChange;
    }
  }, [onChange]);

  useEffect(() => {
    setLocalValue(valueWithoutSeconds);
  }, [valueWithoutSeconds]);

  useEffect(() => {
    if (typeof onChangeRef.current === "function" && !Number.isNaN(debouncedValue) && !isNil(debouncedValue)) {
      if (debouncedValue !== prevValueRef.current) {
        onChangeRef.current(`${debouncedValue}${timeDivider}00`);
        prevValueRef.current = debouncedValue;
      }
    }
  }, [debouncedValue]);

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (typeof onChange === "function") {
      const stringValue = event.target.value;
      setLocalValue(stringValue);
    }
  };

  const isStartsWithTwo = value?.[0] === "2";
  const mask = [/[0-2]/, isStartsWithTwo ? /[0-3]/ : /[0-9]/, ":", /[0-5]/, /[0-9]/];

  return (
    <InputMask mask={mask} value={localValue} onChange={handleInputChange} disabled={isDisabled}>
      <Input suffix={<ClockCircleOutlined />} size={size} {...rest} />
    </InputMask>
  );
}
