import { Space } from "antd";

import { IEtoTask, EtoTaskStepNameEnum } from "core/app/api/interfaces";

import { CheckListGroup } from "../CheckListGroup";
import { Comments } from "../Comments";

type Props = {
  task: IEtoTask;
};

export function PreShiftCheck({ task }: Props) {
  return (
    <Space className="w-100" direction="vertical" size={16}>
      <CheckListGroup task={task} stepName={EtoTaskStepNameEnum.PRE_SHIFT_CHECK} />
      <Comments />
    </Space>
  );
}
