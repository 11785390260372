import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";
import { isLkuTask, isLsoTask, isSlpiTask } from "core/shared/task";
import { formatDuration } from "core/shared/tools/dayjs";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
}

/** Плановые трудозатраты по задаче. */
function Effort({ task }: FieldProps): ReactElement {
  if (isSlpiTask(task) || isLsoTask(task) || isLkuTask(task)) {
    return <Field label="Плановые трудозатраты" value={`${task.effort} чч.`} valueClassName="blue" />;
  }
  return <Field label="Плановые трудозатраты" value={formatDuration(task.effort_duration)} valueClassName="blue" />;
}

export { Effort };
