import { useCallback, useMemo } from "react";
import { Dayjs } from "dayjs";

import { useAppSelector, useAppDispatch } from "core/app/store";
import { setDate } from "core/app/store/date-slice";
import dayjs from "core/shared/tools/dayjs";
import { useTeamId } from "core/shared/tasks-for-shift/store/useTeamId";

const useDate = (): [Dayjs, (newDate: Dayjs | null) => void] => {
  const dispatch = useAppDispatch();
  const { date } = useAppSelector((state) => state.date);
  const [, setTeamId] = useTeamId();

  const set = useCallback(
    (newDate: Dayjs | null) => {
      if (!newDate) {
        return;
      }
      if (newDate.isValid()) {
        dispatch(setDate({ date: newDate.toISOString() }));
        setTeamId(null);
      }
    },
    [dispatch, setTeamId]
  );
  const dayjsDate = useMemo(() => dayjs(date), [date]);

  return [dayjsDate, set];
};

export { useDate };
