import { Modal } from "antd";
import { ReactElement } from "react";

import { useEquipmentId } from "core/shared/useEquipmentId";
import Equipment from "core/widgets/Equipment";

function EquipmentModal(): ReactElement {
  const [equipmentId, setEquipmentId] = useEquipmentId();

  const handleCancel = () => {
    setEquipmentId(null);
  };

  return (
    <Modal destroyOnClose footer={null} open={!!equipmentId} width={950} onCancel={handleCancel}>
      {equipmentId && <Equipment equipmentId={equipmentId} />}
    </Modal>
  );
}

export default EquipmentModal;
