import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";

import { Field } from "../Field";

interface TVolsLineProps {
  task: ITask;
}

function VolsLine({ task }: TVolsLineProps): ReactElement {
  return <Field label="Линия" value={task.line?.name} />;
}

export { VolsLine };
