import { useContext } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { useGetDashboardShiftStatisticTasksByTeamsSlpiQuery } from "core/app/api";
import { ShiftContext } from "core/shared/dashboard/context/shiftContext";
import { getDashboardShiftStatisticTasksByTeam } from "core/shared/dashboard/api/getDashboardShiftStatisticTasksByTeam";

function useDashboardShiftStatisticTasksByTeamsSlpi() {
  const { queryString, teamId } = useContext(ShiftContext);
  const { data } = useGetDashboardShiftStatisticTasksByTeamsSlpiQuery(queryString ?? skipToken);
  return getDashboardShiftStatisticTasksByTeam(data, teamId);
}

export { useDashboardShiftStatisticTasksByTeamsSlpi };
