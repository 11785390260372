import { ReactElement } from "react";

import { ITask } from "core/app/api/interfaces";

import { Field } from "../Field";

interface FieldProps {
  task: ITask;
}

function State({ task }: FieldProps): ReactElement {
  return <Field label="Статус в системе" value={task.state?.name} />;
}

export { State };
