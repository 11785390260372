import { Select, Form, FormInstance } from "antd";
import { ReactElement } from "react";

import { useCustomerDepartments } from "core/shared/main/useCustomerDepartments";

interface DepartmentSelectProps {
  disabled: boolean;
  form: FormInstance;
}

function DepartmentSelect({ disabled, form }: DepartmentSelectProps): ReactElement {
  const { data: departments } = useCustomerDepartments();

  let options = [
    {
      label: "",
      value: 0,
    },
  ];
  if (departments) {
    options = departments.map((department) => ({
      label: department.name,
      value: department.id,
    }));
  }

  return (
    <Form.Item label="ПОДРАЗДЕЛЕНИЕ" name="customer_department_id">
      <Select
        disabled={disabled}
        style={{ width: "100%" }}
        showSearch
        allowClear
        optionFilterProp="children"
        dropdownStyle={{ zIndex: 2000 }}
        filterOption={(input, option) => (option?.label?.toString() ?? "").toLowerCase().includes(input.toLowerCase())}
        options={options}
        onChange={() => {
          form.setFieldValue("object_id", null);
          form.setFieldValue("system_complex_id", null);
        }}
      />
    </Form.Item>
  );
}

export { DepartmentSelect };
