import { useCallback, useEffect } from "react";

import { useAppSelector, useAppDispatch } from "core/app/store";
import { setShift } from "core/app/store/main-slice";
import { useUnitShifts } from "core/shared/unit/useUnitShifts";
import { useTeamId } from "core/shared/tasks-for-shift/store/useTeamId";
import { IWorkRegime } from "core/app/api/interfaces";

const useShift = (): [IWorkRegime | null, (shift: IWorkRegime | null) => void] => {
  const shift = useAppSelector((state) => state.main.shift);
  const [, setTeamId] = useTeamId();
  const dispatch = useAppDispatch();
  const { data: unitShifts } = useUnitShifts();
  const set = useCallback(
    (value: IWorkRegime | null) => {
      dispatch(setShift(value));
      setTeamId(null);
    },
    [dispatch, setTeamId]
  );

  useEffect(() => {
    if (unitShifts && unitShifts[0] && !unitShifts.find((unitShift) => unitShift.number === shift?.number)) {
      set(unitShifts[0]);
    }
  }, [unitShifts, shift, set]);

  return [shift, set];
};

export { useShift };
