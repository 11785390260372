import { Badge as AntBadge } from "antd";
import { ReactNode } from "react";

interface Props {
  count?: number;
  children?: ReactNode;
}

export function Badge({ children, count }: Props) {
  return (
    <AntBadge color="#0091ff" offset={[10, 0]} count={count} showZero>
      {children}
    </AntBadge>
  );
}
