import { ReactElement } from "react";
import { NavLink } from "react-router-dom";
import clsx from "clsx";

import { AppRoutes } from "core/shared/constants.routes";
import Text from "core/lib/Text/Text";
import { useAppDispatch } from "core/app/store";
import { setSelectedTab } from "core/app/store/maintenance-slice";
import { IDashboardMaintenancePlanFactStatistic } from "core/app/api/interfaces";
import { useUnit } from "core/shared/unit/useUnit";

import { Cell } from "../Cell";
import { DifferenceCell } from "../DifferenceCell";
import { FactMinusPlanCell } from "../FactMinusPlanCell";

interface ITablePspaProps {
  data: IDashboardMaintenancePlanFactStatistic | undefined;
  isCurrentDate: boolean;
}

export function TablePspa({ data, isCurrentDate }: ITablePspaProps): ReactElement {
  const unit = useUnit();

  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(setSelectedTab("1"));
  };

  if (!data) {
    return <div />;
  }

  return (
    <div
      className={clsx("maintenance-stats", {
        pspa: unit?.isPSPA(),
      })}
    >
      <div className="mb-2">
        <NavLink to={AppRoutes.maintenance} onClick={handleClick}>
          <Text className="uppercase navlink" boldness="semibold">
            Статистика по ТО
          </Text>
        </NavLink>
      </div>
      <table>
        <tbody>
          {unit?.isPSPA() && (
            <tr className="head">
              <td />
              <td width="33%">ТО СТАЦИОНАРНОЕ</td>
              <td width="33%">ТО НАВЕСНОЕ</td>
            </tr>
          )}
          <tr>
            <td>План на месяц</td>
            <td>
              <Cell value={data.plans_for_the_month} />
            </td>
            {unit?.isPSPA() && (
              <td>
                <Cell value={data.plans_for_the_month} isMounted />
              </td>
            )}
          </tr>
          {isCurrentDate && (
            <tr>
              <td>План на тек. дату</td>
              <td>
                <Cell value={data.plans_for_the_day} baseValue={data.plans_for_the_month} />
              </td>
              {unit?.isPSPA() && (
                <td>
                  <Cell value={data.plans_for_the_day} baseValue={data.plans_for_the_month} isMounted />
                </td>
              )}
            </tr>
          )}
          <tr>
            <td>Факт</td>
            <td>
              <Cell value={data.facts_for_the_day} baseValue={data.plans_for_the_month} />
            </td>
            {unit?.isPSPA() && (
              <td>
                <Cell value={data.facts_for_the_day} baseValue={data.plans_for_the_month} isMounted />
              </td>
            )}
          </tr>
          <tr>
            <td>Остаток</td>
            <td>
              <DifferenceCell baseValue={data.plans_for_the_month} value={data.facts_for_the_day} />
            </td>
            {unit?.isPSPA() && (
              <td>
                <DifferenceCell baseValue={data.plans_for_the_month} value={data.facts_for_the_day} isMounted />
              </td>
            )}
          </tr>
          {isCurrentDate && (
            <tr>
              <td>Факт минус план на тек. дату</td>
              <td>
                <FactMinusPlanCell plans={data.plans_for_the_day} facts={data.facts_for_the_day} />
              </td>
              {unit?.isPSPA() && (
                <td>
                  <FactMinusPlanCell plans={data.plans_for_the_day} facts={data.facts_for_the_day} isMounted />
                </td>
              )}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
