import { useCallback, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "core/app/store";
import {
  clearBuffer,
  clearCounts,
  countStepInc,
  fillTheBuffer,
  handleStatusWork,
  totalCountInc,
  TBufferFileElement,
} from "core/app/store/file-slice";

export const useFileBuffer = () => {
  const dispatch = useAppDispatch();
  const { isWorkStatus, filesBuffer, countStep, countTotal } = useAppSelector((state) => state.file);

  const bufferOverflow = useCallback(
    (data: TBufferFileElement[]) => {
      dispatch(fillTheBuffer(data));
      dispatch(totalCountInc(data.length));
      if (!isWorkStatus) {
        dispatch(handleStatusWork(true));
      }
    },
    [dispatch, isWorkStatus]
  );

  const deleteElemBuffer = useCallback(
    (key: string) => {
      dispatch(countStepInc());
      dispatch(clearBuffer(key));
    },
    [dispatch]
  );

  const percentLoad = Math.round((100 / countTotal) * countStep);

  useEffect(() => {
    if (percentLoad === 100) {
      const timer = setTimeout(() => {
        dispatch(handleStatusWork(false));
        dispatch(clearCounts());
      }, 2000);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [dispatch, percentLoad]);

  return { isWorkStatus, filesBuffer, percentLoad, bufferOverflow, deleteElemBuffer };
};
