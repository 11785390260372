/* eslint-disable camelcase */
import qs from "qs";

import { apiSlice, extractResults } from "core/app/api/api";
import { IDictionaryItem } from "core/shared/interfaces";

import { IDashboardShiftStatisticTasksByTeams, IResponseBody, ITask, TSendOtherLsoTaskBody } from "../interfaces";

import {
  IApproveLsoMonthPlanRequestPayload,
  IDashboardLsoMaintenancePlanFactStatistic,
  IDashboardShiftStatisticTasksByObjectsLso,
  IDashboardShiftStatisticTasksByTeamsLsoTask,
  ILsoMonthPlan,
  ILsoObject,
  ILsoObjectStats,
  ILsoYearPlan,
} from "./interfaces";

const lsoApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLsoYearPlans: builder.query<ILsoYearPlan[], string>({
      query: (queryString) => `lso-objects/?${queryString}`,
      providesTags: ["lso-objects"],
      transformResponse: extractResults,
    }),
    getLsoMonthPlan: builder.query<ILsoMonthPlan, number>({
      query: (id) => `/lso-month-plans/${id}`,
      providesTags: (result, error, args) => [{ type: "lso-month-plans", id: args }],
    }),
    updateLsoMonthPlan: builder.mutation({
      query(body) {
        return {
          url: `/lso-month-plans/${body.id}/`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: (result, error, args) => [
        { type: "lso-month-plans", id: args.id },
        { type: "lso-objects/stats", id: result?.lso },
      ],
    }),
    approveLsoMonthPlan: builder.mutation<void, IApproveLsoMonthPlanRequestPayload>({
      query: (payload) => ({
        url: `lso-month-plans/approve/?${qs.stringify(payload)}`,
        method: "POST",
        body: {},
      }),
      invalidatesTags: ["lso-objects", "lso-month-plans"],
    }),
    undoApproveLsoMonthPlan: builder.mutation<void, IApproveLsoMonthPlanRequestPayload>({
      query: (payload) => ({
        url: `lso-month-plans/undo_approve/?${qs.stringify(payload)}`,
        method: "POST",
        body: {},
      }),
      invalidatesTags: ["lso-objects", "lso-month-plans"],
    }),
    getLsoTask: builder.query<ITask, number | string>({
      query: (id) => `lso-tasks/${id}/`,
      providesTags: (result, error, taskId) => [{ type: "task", id: Number(taskId) }],
    }),
    getLsoObjects: builder.query<ILsoObject[], string>({
      query: (queryString) => `lso-direct-objects/?${queryString}`,
      providesTags: ["lso-objects"],
      transformResponse: extractResults,
    }),
    getLsoSystems: builder.query<IResponseBody<IDictionaryItem>, string>({
      query: () => `lso-systems/`,
      providesTags: ["lso-systems"],
    }),
    getLsoObjectStats: builder.query<ILsoObjectStats, { year: number; lso_id: number }>({
      query: (params) => `lso-objects/lso-stat/?${qs.stringify(params)}`,
      providesTags: (result, error, params) => [{ type: "lso-objects/stats", id: params.lso_id }],
    }),
    createLsoTask: builder.mutation<any, TSendOtherLsoTaskBody>({
      query: (payload) => ({
        url: `lso-tasks/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["tasks", "open-tasks", "tasks-filtered", "kanban-board", "shifttask"],
    }),

    updateLsoTask: builder.mutation({
      query: (task) => ({
        url: `lso-tasks/${task.id}/`,
        method: "PATCH",
        body: task,
      }),
      async onQueryStarted({ queryString, ...taskUpdate }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData("getOpenTasks", queryString, (cachedTasks) => {
            const cachedTaskToUpdate = cachedTasks.results.find((cachedTask: ITask) => cachedTask.id === taskUpdate.id);
            if (cachedTaskToUpdate) {
              Object.assign(cachedTaskToUpdate, taskUpdate);
            }
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: "task", id: arg.id },
        "tasks",
        "shifttask",
        "tasks-filtered",
        "kanban-board",
        "month-plan",
        "tasks/statistics-by-days",
        "dashboard/shift-statistic/tasks",
        "dashboard/shift-statistic/tasks/by-equipment-types",
        "dashboard/shift-statistic/tasks/by-objects/slpi",
      ],
    }),

    getDashboardShiftStatisticTasksByTeamsLso: builder.query<
      IDashboardShiftStatisticTasksByTeams<IDashboardShiftStatisticTasksByTeamsLsoTask>[],
      string
    >({
      query: (queryString: string) => `dashboard/shift-statistic/tasks/by-teams/lso/?${queryString}`,
      providesTags: ["dashboard/shift-statistic/tasks/by-teams/lso"],
    }),

    getDashboardShiftStatisticTasksByObjectsLso: builder.query<IDashboardShiftStatisticTasksByObjectsLso, string>({
      query: (queryString: string) => `dashboard/shift-statistic/tasks/by-objects/lso/?${queryString}`,
      providesTags: ["dashboard/shift-statistic/tasks/by-objects/lso"],
    }),

    getDashboardLsoMaintenancePlanFactStatistic: builder.query<IDashboardLsoMaintenancePlanFactStatistic, string>({
      query: (queryString) => `/dashboard/lso-maintenance-plan-fact-statistic/?${queryString}`,
      providesTags: ["dashboard/lso-maintenance-plan-fact-statistic"],
    }),
  }),
});

export const {
  useGetLsoYearPlansQuery,
  useUpdateLsoMonthPlanMutation,
  useApproveLsoMonthPlanMutation,
  useUndoApproveLsoMonthPlanMutation,
  useGetLsoMonthPlanQuery,
  useGetLsoTaskQuery,
  useGetLsoObjectsQuery,
  useGetLsoSystemsQuery,
  useGetLsoObjectStatsQuery,
  useCreateLsoTaskMutation,
  useUpdateLsoTaskMutation,
  useGetDashboardShiftStatisticTasksByTeamsLsoQuery,
  useGetDashboardShiftStatisticTasksByObjectsLsoQuery,
  useGetDashboardLsoMaintenancePlanFactStatisticQuery,
} = lsoApiSlice;
