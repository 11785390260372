import { ReactElement } from "react";

import { isGroup, IGroup } from "core/shared/dashboard";
import { IDashboardShiftStatisticTasksByTeamsSlpiTask } from "core/app/api/interfaces";

type TableRecord = IDashboardShiftStatisticTasksByTeamsSlpiTask | IGroup<IDashboardShiftStatisticTasksByTeamsSlpiTask>;

interface CellProps {
  record: TableRecord;
}

function Object({ record }: CellProps): ReactElement {
  if (isGroup(record)) {
    return <div />;
  }
  return <div>{record.object || "Не указано"}</div>;
}

export { Object };
