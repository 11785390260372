import { Row, Col } from "antd";
import clsx from "clsx";

import { ITask } from "core/app/api/interfaces";
import { formatHours } from "core/shared/tools/dayjs";
import { MOTO_WORD } from "core/widgets/CreateTaskModal/Fields/EffortField";
import { RequestCreationDatetime, Initiator } from "core/widgets/TaskModals/shared/Fields";
import { Field } from "core/widgets/TaskModals/shared/Fields/Field";

type TDefaultGeneralInfoProps = {
  task: ITask;
};

function DefaultGeneralInfo({ task }: TDefaultGeneralInfoProps) {
  let effort = 0;
  let moto = 0;
  if (task.work_type?.name.includes(MOTO_WORD)) {
    moto = task.effort || 0;
  } else {
    effort = task.effort || 0;
  }

  return (
    <Row gutter={16} className={clsx("mb-1", "mt-1")}>
      <Col span={12}>
        <Field label="Единица оборудования" value={task.equipment?.name} />
        <Field label="Транспорт" value={task.transport?.name} />
        <RequestCreationDatetime label="Дата создания" task={task} format="DD.MM.YYYY в HH:mm:ss" />
      </Col>
      <Col span={12}>
        <Field label="Плановые трудозатраты" value={formatHours(effort)} valueClassName="blue" />
        <Field label="Мото часы" value={`${moto} ч.`} valueClassName="blue" />
        <Initiator task={task} />
      </Col>
    </Row>
  );
}

export default DefaultGeneralInfo;
