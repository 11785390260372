import { ReactElement } from "react";

import Text from "core/lib/Text/Text";

interface ITotalProps {
  fact: number | null;
  plan: number | null;
  showTitle: boolean;
}

function Total({ fact, plan, showTitle }: ITotalProps): ReactElement {
  return (
    <>
      <div>
        {showTitle && (
          <Text boldness="semibold" size="body1">
            ИТОГО
          </Text>
        )}
      </div>
      <div>
        <Text boldness="semibold" size="label3">
          ФАКТ
        </Text>
      </div>
      <div className="lh-1">
        <Text boldness="black" size="h4">
          {fact}%
        </Text>
      </div>
      <div>
        <Text boldness="semibold" size="label3">
          ПЛАН
        </Text>
      </div>
      <div className="lh-1">
        <Text boldness="black" size="h4">
          {plan}%
        </Text>
      </div>
    </>
  );
}

export { Total };
