import { Form, Input } from "antd";

import "./styles.scss";

function DescriptionField() {
  return (
    <Form.Item label="ОПИСАНИЕ" name="description" rules={[{ required: true, message: "" }]}>
      <Input.TextArea className="description-field" size="large" showCount maxLength={255} rows={6} />
    </Form.Item>
  );
}

export default DescriptionField;
