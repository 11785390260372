import { Select, Form, FormInstance } from "antd";
import { ReactElement } from "react";

import { StateNamesEnum } from "core/app/api/interfaces";
import { useItsmDelayReasons } from "core/shared/main/useItsmDelayReasons";

interface DelayReasonSelectProps {
  disabled: boolean;
  form: FormInstance;
}

function DelayReasonSelect({ disabled, form }: DelayReasonSelectProps): ReactElement {
  const { data: delayReasons } = useItsmDelayReasons();

  let options: {
    label: string;
    value: number;
  }[] = [];

  if (delayReasons) {
    options = delayReasons.map((delayReason) => ({
      label: delayReason.name,
      value: delayReason.id,
    }));
  }

  return (
    <Form.Item
      label="ПРИЧИНА ПРИОСТАНОВКИ"
      name="delay_reason_id"
      rules={[
        {
          validator: (_, value) => {
            const newState = form.getFieldValue("newState");
            if (newState === StateNamesEnum.OnPause && !value) {
              return Promise.reject(new Error("Пожалуйста, введите ПРИЧИНА ПРИОСТАНОВКИ"));
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <Select
        disabled={disabled}
        style={{ width: "100%" }}
        dropdownStyle={{ zIndex: 2000 }}
        dropdownMatchSelectWidth={650}
        options={options}
        allowClear
        showSearch
        filterOption={(input, option) => (option?.label?.toString() ?? "").toLowerCase().includes(input.toLowerCase())}
      />
    </Form.Item>
  );
}

export default DelayReasonSelect;
