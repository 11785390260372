import { Collapse, Form, Input } from "antd";

import { IEtoTask, EtoTaskStepNameEnum } from "core/app/api/interfaces";
import Text from "core/lib/Text/Text";

import { CheckList } from "./CheckList";

type TMaintenanceCheckListProps = {
  task: IEtoTask;
  stepName: EtoTaskStepNameEnum;
};

export function CheckListGroup({ task, stepName }: TMaintenanceCheckListProps) {
  const { steps } = task;
  const stepIndex = task.steps.findIndex((item) => item.step_name === stepName);
  const step = task.steps[stepIndex];

  return (
    <>
      <Form.Item hidden name={["steps", stepIndex, "step_name"]}>
        <Input value={stepName} />
      </Form.Item>
      <Form.Item hidden name={["steps", stepIndex, "id"]}>
        <Input value={step.id} />
      </Form.Item>
      <Collapse defaultActiveKey={steps[stepIndex]?.checklist_json[0]?.uuid} expandIconPosition="right" ghost>
        {steps[stepIndex]?.checklist_json?.map((checklist, checklistJsonIndex) => (
          <Collapse.Panel
            forceRender
            key={checklist.uuid}
            header={
              <>
                <Form.Item name={["steps", stepIndex, "checklist_json", checklistJsonIndex, "uuid"]} hidden>
                  <Input value={checklist.uuid} />
                </Form.Item>
                <Form.Item name={["steps", stepIndex, "checklist_json", checklistJsonIndex, "group"]} hidden>
                  <Input value={checklist.group} />
                </Form.Item>

                <Text size="label1" boldness="semibold" transform="uppercase">
                  {checklist.group}
                </Text>
              </>
            }
          >
            <CheckList
              stepName={stepName}
              formPath={["steps", stepIndex, "checklist_json", checklistJsonIndex]}
              checkList={checklist}
            />
          </Collapse.Panel>
        ))}
      </Collapse>
    </>
  );
}
