import { ReactElement } from "react";
import { Select, Form } from "antd";
import qs from "qs";

import { useGetVolsLinesQuery } from "core/app/api/VOLS";
import { IVolsLine } from "core/app/api/VOLS/interfaces";

export function VolsLineField(): ReactElement {
  const objectId = Form.useWatch("objectId");

  const queryParams: Record<string, string | number> = {};

  if (objectId) {
    queryParams.object_id = objectId;
  }

  const queryString = qs.stringify(queryParams);
  const { data, isFetching } = useGetVolsLinesQuery(queryString);

  const options = (data || []).map((item: IVolsLine) => ({
    label: item.name,
    value: item.id,
  }));

  return (
    <Form.Item label="ЛИНИЯ" name="lineId" colon={false}>
      <Select
        loading={isFetching}
        size="large"
        allowClear
        optionFilterProp="children"
        showSearch
        filterOption={(input, option) =>
          (option?.label?.toString() ?? "").toLowerCase().includes(input.trim().toLowerCase())
        }
        options={options}
      />
    </Form.Item>
  );
}
