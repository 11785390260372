import { ReactElement } from "react";
import { Select, Form } from "antd";
import qs from "qs";

import { useUnitId } from "core/shared/unit/useUnitId";
import { useGetVolsObjectsQuery } from "core/app/api/VOLS";
import { IVolsObject } from "core/app/api/VOLS/interfaces";

export function VolsObjectField(): ReactElement {
  const [unitId] = useUnitId();
  const form = Form.useFormInstance();

  const queryParams: Record<string, string | number> = {};
  const customerId = Form.useWatch("customerId");
  const customerDepartmentId = Form.useWatch("customerDepartmentId");

  if (unitId) {
    queryParams.unit_id = unitId;
  }
  if (customerId) {
    queryParams.customer_id = customerId;
  }
  if (customerDepartmentId) {
    queryParams.customer_department_id = customerDepartmentId;
  }

  const queryString = qs.stringify(queryParams);
  const { data, isFetching } = useGetVolsObjectsQuery(queryString);

  const options = (data || []).map((item: IVolsObject) => ({
    label: item.name,
    value: item.id,
  }));

  const handleChange = () => {
    form.setFieldsValue({
      lineId: undefined,
    });
  };

  return (
    <Form.Item label="ОБЪЕКТ" name="objectId" colon={false}>
      <Select
        loading={isFetching}
        size="large"
        allowClear
        optionFilterProp="children"
        showSearch
        filterOption={(input, option) =>
          (option?.label?.toString() ?? "").toLowerCase().includes(input.trim().toLowerCase())
        }
        options={options}
        onChange={handleChange}
      />
    </Form.Item>
  );
}
