import { Tag } from "antd";

import { ICellRendererProps } from "../../interfaces";

function SignatureRequestStatusCellRenderer({ record: { hasBeenSent, errorMessage } }: ICellRendererProps) {
  let color = "green";
  let text = "ОТПРАВЛЕНО";

  if (errorMessage) {
    color = "red";
    text = "ОШИБКА";
  } else if (!hasBeenSent) {
    color = "purple";
    text = "ОЖИДАЕТ ОТПРАВКИ";
  }

  return <Tag color={color}>{text}</Tag>;
}

export default SignatureRequestStatusCellRenderer;
